import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';



interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    fitnesscenters: models.Paging<models.GSFFitnessCenter>
    filtedFitnessCenters: models.GSFFitnessCenter[]
    isLoading: boolean
    error: string | null
    limit: number
    offset: number
    city: string
    q: string
    search: string
    center: {
        lat: number
        lng: number
    }
    zoom: number
    myMarkers: any[],
    name: string
    latitude: number
    longitude: number
    mapView: boolean
    status: number
}

class GSFOrganizations extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            fitnesscenters: { count: 0, items: [] as models.GSFFitnessCenter[] },
            filtedFitnessCenters: [] as models.GSFFitnessCenter[],
            isLoading: true,
            error: null,
            limit: 500,
            offset: 0,
            city: '',
            q: '',
            search: '',
            center: {
                lat: 17.456505,
                lng: 78.414984
            },
            zoom: 11,
            myMarkers: [],
            name: '',
            latitude: 0,
            longitude: 0,
            mapView: false,
            status: 1
        }
        this.handleChange = this.handleChange.bind(this);
    }
    aMarker = () => {
        const { filtedFitnessCenters } = this.state

        const marker = filtedFitnessCenters.map((c, i) => {
            return {
                id: c.id,
                name: c.name,
                latitude: c.latitude,
                longitude: c.longitude
            }
        }
        )
        this.setState({
            myMarkers: marker,
            isLoading: false
        })
    }

    componentDidMount = () => {

        const { limit, offset, city, q, status } = this.state

        Server.get().getGSFFitnessCenters({ limit, offset, city, q, status }).then((fitnesscenters) => {
            this.setState({
                fitnesscenters: fitnesscenters,
                filtedFitnessCenters: fitnesscenters.items,
                isLoading: false
            }, () => {
                this.aMarker();
            })
        }).catch((e) => {
            this.setState({
                error: getApiError(e),
                isLoading: false
            })
        })
    }

    onChange = (event: any) => {
        this.setState({
            search: event.target.value
        })
    }
    onSearch = (str: string) => {
        const { fitnesscenters } = this.state
        const filtedFitnessCenters = fitnesscenters.items.filter(fc => {
            const lowerStr = str.toLowerCase()
            return fc.name.toLowerCase().indexOf(lowerStr) >= 0 ||
                fc.email.toLowerCase().indexOf(lowerStr) >= 0 ||
                fc.branch.toLowerCase().indexOf(lowerStr) >= 0 ||
                fc.area.toLowerCase().indexOf(lowerStr) >= 0 ||
                fc.city.toLowerCase().indexOf(lowerStr) >= 0 ||
                fc.phone.toLowerCase().indexOf(lowerStr) >= 0 ||
                fc.qr_code.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filtedFitnessCenters,
            isLoading: false
        })
    }
    handleChange = () => {
        const { mapView } = this.state;
        this.setState({
            mapView: !mapView
        })
    }
    _handleChange = (event: any) => {
        event.preventDefault()
        this.setState({ status: parseInt(event.target.value) }, () => this.componentDidMount());
    }
    render() {
        const { error, mapView, center, zoom, isLoading, fitnesscenters, status, filtedFitnessCenters } = this.state;
        console.log('fitnesscenters', filtedFitnessCenters.length)
        const Marker = ({ name, $hover }: any, ) => {
            return (
                <div className="fc-marker">
                    {$hover ? <span>{name}</span> : null}
                </div>
            );
        }
        return (
            <div className='list'>
                <h2>Fitness centers</h2>
                <Loader loading={isLoading}>
                    <div className="row" style={{ alignItems: 'center', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        <div className='form-group'>
                            <SearchField
                                placeholder="Search..."
                                onChange={this.onSearch}
                                classNames="test-class"
                            />
                        </div>
                        {
                            error
                                ? <span style={{ color: 'red' }}>{error}</span>
                                : null
                        }
                        {/* <div className="col"> */}
                        <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/fitnesscenter/create')}><i className="fas fa-create-alt"></i>New Fitness Center</button>
                        <button type="button" className="btn btn-link" onClick={this.handleChange}>{mapView ? "List View" : "Map View"}</button>
                        {/* </div> */}
                        <div style={{ paddingLeft: 10 }}>
                            <select onChange={this._handleChange} value={status} className="form-control">
                                <option value={0}>In-progress</option>
                                <option value={1}>Active</option>
                                <option value={2}>In-active</option>
                            </select>
                        </div>
                        <div style={{ paddingLeft: 10, textAlign: 'center' }}>{fitnesscenters ? `Total items:${fitnesscenters.count}` : null}</div>
                    </div>
                    {
                        mapView ?
                            <div style={{ height: '150vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyB2NIZtNNZqSWMu3E_ALQyN1_OrP9ZKRC8' }}
                                    defaultCenter={center}
                                    defaultZoom={zoom}
                                >
                                    {
                                        this.state.myMarkers.map((each) =>
                                            <Marker
                                                key={each.id}
                                                lat={each.latitude}
                                                lng={each.longitude}
                                                name={each.name}
                                            />
                                        )
                                    }
                                </GoogleMapReact>
                            </div>
                            :
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Branch</th>
                                        <th scope="col">Area</th>
                                        <th scope="col">Qr code</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Website</th>
                                        <th scope="col">Zip code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filtedFitnessCenters.map((c, i) =>
                                        <tr key={c.id}>
                                            <td scope="row"><Link to={'/fc/' + c.id + '/'}> {c.name}</Link></td>
                                            <td>{c.phone}</td>
                                            <td>{c.email}</td>
                                            <td>{c.branch}</td>
                                            <td>{c.area}</td>
                                            <td>{c.qr_code}</td>
                                            <td>{c.status == 0 ? 'In-progress' : (c.status == 1 ? 'Active' : 'In-active')}</td>
                                            <td>{c.website}</td>
                                            <td>{c.zip_code}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                    }
                </Loader>
            </div >
        );
    }
}

export default GSFOrganizations;
