import * as React from 'react';
import DatePicker from "react-datepicker";
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import Pager from '../common/Pager';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    payments: models.Paging<models.FCPayment>
    isLoading: boolean
    error: string | null
    from: Date
    limit: number
    offset: number
    to: Date
}
class FCPayments extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            payments: { count: 0, items: [] as models.FCPayment[] },
            isLoading: true,
            error: null,
            from: new Date(),
            limit: 25,
            offset: 0,
            to: new Date(),
        };

        this.onFromChange = this.onFromChange.bind(this);
        this.onToChange = this.onToChange.bind(this);
    }

    fcPayments = () => {
        this.setState({
            limit: 25,
            offset: 0,
            from: new Date(),
            to: new Date(),
            isLoading: true
        })
    }

    onFromChange = (date: Date) => {
        this.setState({
            from: date
        }, () => {
            this.componentDidMount();
        })
    }

    onToChange = (date: Date) => {
        this.setState({
            to: date
        }, () => {
            this.componentDidMount();
        })
    }
    componentDidMount = () => {
        const { fc_id } = this.props
        const { from, limit, offset, to } = this.state

        Server.get().getFCPayments({ fc_id, from: from.getTime(), to: to.getTime(), limit, offset })
            .then((payments) => {
                this.setState({
                    payments: payments,
                    isLoading: false
                })
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }
    onDelete = (id: number) => {

        const { fc_id } = this.props
        const payment_id = id
        if (confirm('Are you sure you want to cancel this payment?')) {

            Server.get().deleteFCPayment({ fc_id, payment_id })
                .then(() => {
                    let statePayment = this.state.payments
                    statePayment.items = statePayment.items.filter((payment) => payment.id !== id)
                    this.setState({ payments: statePayment })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                    })
                })
        }
    }
    onPage = (limit: number, offset: number) => {
        this.setState({
            limit,
            offset
        })
    }
    public render() {
        const { fc_id } = this.props;
        const { from, to, error, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Payment</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={isLoading}>
                    <Pager count={this.state.payments.count} limit={25} page={0} onChange={(limit, offset) => this.onPage(limit, offset)} />
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <div className="form-group">
                        From :
                        <DatePicker className="form-control"
                            selected={from}
                            onChange={this.onFromChange}
                        />
                    </div>
                    <div className="form-group">
                        To :
                        <DatePicker className="form-control"
                            selected={to}
                            onChange={this.onToChange}
                        />
                    </div>
                    <div>Total items : {this.state.payments.count}</div>
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col">Create at</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Bill date</th>
                                <th scope="col">Reference no.</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.payments.items && this.state.payments.items.map((c, i) =>
                                <tr key={c.id}>
                                    <th>
                                        <Moment format="DD/MM/YYYY HH:mm">
                                            {c.create_at}
                                        </Moment></th>
                                    <td>{c.amount}</td>
                                    <td>
                                        <Moment format="DD/MM/YYYY">
                                            {c.bill_date}
                                        </Moment>
                                    </td>
                                    <td>{c.ref_no}</td>
                                    <td><button type='button' className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/payment/update', { bill: c })}><i className="fas fa-edit">Edit</i></button>
                                        <button type='button' className="btn btn-link" onClick={() => this.onDelete(c.id)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCPayments);
