import * as React from 'react';
import DatePicker from "react-datepicker";
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import Pager from '../common/Pager';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    packageSales: models.Paging<models.FCPackageSale>
    filteredSales: models.FCPackageSale[]
    isLoading: boolean
    error: string | null
    from: Date
    limit: number
    offset: number
    to: Date
}
class FCPackageSales extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            packageSales: { count: 0, items: [] as models.FCPackageSale[] },
            filteredSales: [] as models.FCPackageSale[],
            isLoading: true,
            error: null,
            from: new Date(),
            limit: 25,
            offset: 0,
            to: new Date(),
        };
        this.onFromChange = this.onFromChange.bind(this);
        this.onToChange = this.onToChange.bind(this);

    }

    fcPackageSales = () => {
        this.setState({
            isLoading: true,
            limit: 25,
            offset: 0,
            from: new Date(),
            to: new Date(),
        })
    }

    onFromChange = (date: Date) => {
        date.setHours(0)
        date.setMinutes(0)
        console.log("from time", date)
        this.setState({
            from: date

        }, () => {
            this.componentDidMount();
        })
    }
    onPage(limit: number, offset: number) {
        this.setState({
            limit,
            offset
        })
    }
    onToChange = (date: Date) => {
        date.setHours(23)
        date.setMinutes(59)
        console.log("to time", date)
        this.setState({
            to: date
        }, () => {
            this.componentDidMount();
        })
    }
    componentDidMount = () => {
        const { fc_id } = this.props
        const { from, to, limit, offset } = this.state;
        console.log("from", from)
        console.log('to', to)
        Server.get().getFCPackageSales({ fc_id, from: from.getTime(), to: to.getTime(), offset, limit })
            .then((packageSale) => {
                this.setState({
                    packageSales: packageSale,
                    filteredSales: packageSale.items,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }
    onSearch = (input: string) => {
        const { filteredSales } = this.state
        const filteredpkg = filteredSales.filter(sales => {
            const lowerStr = input.toLowerCase();
            return sales.pkg_name.toLowerCase().indexOf(lowerStr) >= 0 ||
                sales.user_name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filteredSales: filteredpkg
        })
    }
    public render() {
        const { from, to, error, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Sales</h2>
                        </div>
                    </ol>
                </nav>
                <Pager count={this.state.packageSales.count} limit={25} page={0} onChange={(limit, offset) => this.onPage(limit, offset)} />
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <div className="form-inline">
                        <div className="form-group">
                            <SearchField
                                placeholder="Search..."
                                onChange={this.onSearch}
                                classNames="test-class"
                            />
                        </div>
                        <div className="form-group">
                            From :
                        <DatePicker className="form-control"
                                selected={from}
                                onChange={this.onFromChange}
                            />
                        </div>
                        <div className="form-group">
                            To :
                        <DatePicker className="form-control"
                                selected={to}
                                onChange={this.onToChange}
                            />
                        </div>
                        <div>Total items: {this.state.packageSales.count}</div>
                    </div>
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col">Create at</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Effective from</th>
                                <th scope="col">Effective to</th>
                                <th scope="col">Package Name</th>
                                <th scope="col">Package Period</th>
                                <th scope="col">User Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredSales.map((c, i) =>
                                <tr key={c.id}>
                                    <td scope="row">
                                        <Moment format="DD/MM/YYYY HH:mm">
                                            {c.create_at}
                                        </Moment>
                                    </td>
                                    <td>{c.amount}</td>
                                    <td>
                                        <Moment format="DD/MM/YYYY">
                                            {c.effective_from}
                                        </Moment>
                                    </td>
                                    <td>
                                        <Moment format="DD/MM/YYYY">
                                            {c.effective_to}
                                        </Moment>

                                    </td>
                                    <td>{c.pkg_name}</td>
                                    <td>{c.pkg_period}</td>
                                    <td>{c.user_name}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCPackageSales);
