import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { models } from '../../models';
import { Server } from '../../common/Server';
import { getApiError, VERSION } from '../../common/HelperMethods';
import Loader from '../common/Loader';



interface IProps extends RouteComponentProps<any> {
    org_id: number
}

interface IState {
    orgDetails: models.OrgDetails
    error: string | null
    limit: number
    offset: number
    city: string
    q: string
    id: number
    loading: boolean
    isGSFUser: boolean
}

class OrgSidebar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            orgDetails: {} as models.OrgDetails,
            error: null,
            loading: false,
            limit: 500,
            offset: 0,
            city: '',
            q: '',
            id: 0,
            isGSFUser: false
        };
    }
    componentDidMount = () => {

        const { org_id } = this.props;
        Server.get().userType().then(user_type => {
            this.setState({ isGSFUser: user_type === 'gsf_user' })
        })
        Server.get().getOrgDetails({ org_id })
            .then((details) => {
                this.setState({
                    orgDetails: details,
                    loading:false
                })
            })

            .catch((e) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })
    }

    onPage = (limit: number, offset: number) => {
        this.setState({
            limit,
            offset
        })
    }
    handleLogout = () => {
        Server.get().logout().then(() => {
            this.props.history.replace('/org/login')
        })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    render() {
        const { org_id } = this.props;
        const { orgDetails, isGSFUser } = this.state;
        return (
            <Loader loading={this.state.loading}>
                <nav id="sidebar" className="sidebar-wrapper">
                    {
                        isGSFUser ?
                            <div className='sidebar-home'>
                                <NavLink to={"/gsf/organization"}>
                                    <span>Home</span>
                                </NavLink>
                            </div> : null
                    }
                    <div className='sidebar-header'>
                        <h4>
                            {orgDetails.name}
                        </h4>
                    </div>
                    <div className="sidebar-menu">
                        <ul>
                            <li>
                                <NavLink to={'/org/' + org_id + '/dashboard'}>
                                    <i className="fa fa-info"></i>
                                    <span>Dashboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/org/' + org_id + '/domains'}>
                                    <i className="fa fa-calendar"></i>
                                    <span>Domains</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/org/' + org_id + '/employees'}>
                                    <i className="fa fa-users"></i>
                                    <span>Employees</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to={'/org/' + org_id + '/packages'}>
                                    <i className="fa fa-tachometer-alt"></i>
                                    <span>Packages</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/org/' + org_id + '/details'}>
                                    <i className="fa fa-info"></i>
                                    <span>Details</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/org/' + org_id + '/usage'}>
                                    <i className="fa fa-tachometer-alt"></i>
                                    <span>Usages</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/org/' + org_id + '/users'}>
                                    <i className="fa fa-tachometer-alt"></i>
                                    <span>Users</span>
                                </NavLink>
                            </li>
                            <button type="button" className="btn btn-primary" onClick={this.handleLogout}>Logout</button>
                        </ul>
                        <div className="row justify-content-center">
                            <div className="align-content:center" style={{ padding: 5 }}>
                                Version {VERSION}
                            </div>
                        </div>
                    </div>
                </nav>
            </Loader>
        );
    }
}

export default withRouter(OrgSidebar);