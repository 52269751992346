import * as React from 'react';
interface IProps {
    count: number
    limit: number
    page: number
    onChange: (limit: number, offset: number) => any
}
interface IState {
    page: number
}

class Pager extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            page: 0
        };
    }
    onPrevious() {
        const isFirst = this.state.page == 0
        isFirst || this.setState({
            page: this.state.page - 1
        })
    }
    onNext() {
        const isLast = this.state.page * this.props.limit > this.props.count
        isLast || this.setState({
            page: this.state.page + 1
        })
    }
    renderPages() {
        const pages = []
        for (let x = 0, pageCount = 1; x < this.props.count; x += this.props.limit) {
            const isCurrent = this.state.page == x
            pages.push(<li key={'' + x + isCurrent} className={`page-item ${isCurrent ? 'active' : ''}`}>
                <a className="page-link" href="#" onClick={() => this.props.onChange(this.props.limit, x)}>
                    {pageCount}
                    <span className="sr-only">(current)</span>
                </a>
            </li>)
            pageCount++;
        }
        return pages
    }
    public render() {
        const isFirst = this.state.page == 0
        const isLast = this.state.page * this.props.limit > this.props.count
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end" >
                    <li className={`page-item ${isFirst ? 'disabled' : ''}`} ><a className="page-link" href="#" onClick={() => this.onPrevious()}>Previous</a></li>
                    {this.renderPages()}
                    <li className={`page-item ${isLast ? 'disabled' : ''}`} ><a className="page-link" href="#" onClick={() => this.onNext()}>Next</a></li>
                </ul>
            </nav>
        )
    }
}

export default Pager;
