import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    isLoading: boolean
    error: string | null
    fullFacilities: models.GSFFacility[]
    filteredFacilities: models.GSFFacility[]
    facilities: string[]
}

class FCFacilities extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            fullFacilities: [],
            filteredFacilities: [],
            facilities: []
        };
    }

    handleOnChange = (facility: string, checked: boolean) => {
        const facilities = this.state.facilities
        if (checked) {
            facilities.push(facility)
        } else {
            const index = facilities.indexOf(facility)
            facilities.splice(index, 1)
        }
        this.setState({
            facilities: facilities
        })
    }

    getFullFacilities = () => {

        Server.get().getAllFacilities({})
            .then((facilities) => {
                this.setState({
                    fullFacilities: facilities,
                    filteredFacilities: facilities
                })
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }



    componentDidMount = () => {
        const { fc_id } = this.props

        this.getFullFacilities();
        Server.get().getFCFacilities({ fc_id })

            .then((facilities) => {
                this.setState({
                    facilities: facilities,
                    isLoading: false,

                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false,

                })
            })
    }

    onSubmit = (event: any) => {

        event.preventDefault();

        const { fc_id } = this.props;
        const { facilities } = this.state;

        Server.get().updateFCFacilitites({ facilities, fc_id })
            .then((facilities) => {
                this.setState({
                    facilities: facilities
                })
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    onSearch = (str: string) => {
        const { fullFacilities } = this.state;
        const filteredFacilities = fullFacilities.filter((facility) => {
            const lowerStr = str.toLowerCase()
            return facility.name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filteredFacilities: filteredFacilities,
            isLoading: false
        })
    }

    public render() {
        const { facilities, filteredFacilities, error, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Facilities</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={isLoading}>
                    <div className='form-group'>
                        <SearchField
                            placeholder="Search..."
                            onChange={this.onSearch}
                            classNames="test-class"
                        />
                    </div>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Facilities</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredFacilities.map((facility, i) =>
                                    <tr key={facility.name}>
                                        <td scope="row">
                                            <input type="checkbox" name="active" onChange={(e) => this.handleOnChange(facility.name, e.target.checked)} checked={facilities.indexOf(facility.name) >= 0} />
                                            {facility.name}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </Loader>
                <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                </div>
            </div>
        );
    }
}

export default withRouter(FCFacilities);
