import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import Select from 'react-select';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import Pager from '../common/Pager';
import Moment from 'react-moment';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isLoading: boolean,
    error: string | null,
    limit: number
    offset: number
    city: string
    q: string
    consumers: models.Paging<models.GSFConsumers>
    filteredConsumer: models.GSFConsumers[]
    organizations: models.GSFOrganization[]
    items: models.GSFSelectedConsumers[],
    selectedConsumersCount: number
    fitnessCenters: models.GSFFitnessCenter[]
    _organization: any[] | null
    selectAll: boolean
    fc: any[]
    _fc: any[] | null,
    gender: string
    status: string
    area: models.Area[]
    _area: any[] | null
    fc_status: number
    fcs: models.NearByFcs[]
    plans: models.GSFPlan[]
    plan: string
    show: boolean
    consumer_id: number
}

class GSFConsumers extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            consumers: { count: 0, items: [] as models.GSFConsumers[] },
            filteredConsumer: [] as models.GSFConsumers[],
            isLoading: true,
            error: null,
            limit: 500,
            offset: 0,
            city: 'Hyderabad',
            q: '',
            organizations: [] as models.GSFOrganization[],
            items: [] as models.GSFSelectedConsumers[],
            selectedConsumersCount: 0,
            fitnessCenters: [] as models.GSFFitnessCenter[],
            _organization: [],
            selectAll: false,
            fc: [],
            _fc: [],
            gender: '',
            status: '',
            area: [] as models.Area[],
            _area: [],
            fc_status: 1,
            fcs: [] as models.NearByFcs[],
            plans: [] as models.GSFPlan[],
            plan: '',
            show: false,
            consumer_id: 0
        };
        this.handleChange = this.handleChange.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this.onChange = this.onChange.bind(this)
        this._onChange = this._onChange.bind(this)
        this._handleChangeArea = this._handleChangeArea.bind(this)
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount = () => {
        this.getAllConsumers();
        this.getAllAreas();
        this.getAllOrgs();
        this.getAllFcs();
        Server.get().getGSfPlans({}).then((plans) => {
            this.setState({ plans })
        })
    }

    handleClose() {
        this.setState({ show: false });
    }
    getAllConsumers = () => {
        const { limit, offset, city, q, _fc, _organization, gender, status, _area } = this.state;
        const organization = _organization!.map((c) => { return c.value })
        const fcs = _fc!.map((c) => { return c.value })
        const areas = _area!.map((c) => { return c.value })
        Server.get().getGSFConsumers({ limit, offset, city, q, fcs, gender, organization, status, areas }).then((consumers) => {
            this.setState({
                consumers: consumers,
                filteredConsumer: consumers.items,
                isLoading: false
            })
        }).catch((e) => {
            this.setState({
                error: getApiError(e),
                isLoading: false
            })
        })
    }

    getAllOrgs = () => {
        const { limit, offset, city, q } = this.state;

        Server.get().getGSFOrganizations({ city, limit, offset, q }).then((org) => {

            this.setState({
                organizations: org.items
            })
        })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                })
            })
    }

    getAllFcs = () => {
        const { limit, offset, city, q, fc_status } = this.state;

        Server.get().getGSFFitnessCenters({ city, limit, offset, q, status: fc_status }).then((fcs) => {
            this.setState({
                fitnessCenters: fcs.items
            })
        })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                })
            })
    }

    getAllAreas = () => {
        Server.get().getAllArea({ city: 1 })
            // 1 - hyderabad
            .then((area) => {
                this.setState({
                    area: area,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onPage = (limit: number, offset: number) => {
        this.setState({
            limit,
            offset
        }, () => this.componentDidMount())
    }

    onSearch = (input: string) => {
        const { consumers } = this.state;

        const filteredConsumers = consumers.items.filter(consumer => {
            const lowerStr = input.toLowerCase();
            return consumer.name && consumer.name.toLowerCase().indexOf(lowerStr) >= 0 ||
                consumer.limit_type && consumer.limit_type.toLowerCase().indexOf(lowerStr) >= 0 ||
                consumer.city && consumer.city.toLowerCase().indexOf(lowerStr) >= 0 ||
                consumer.email && consumer.email.toLowerCase().indexOf(lowerStr) >= 0 ||
                consumer.phone && consumer.phone.toLowerCase().indexOf(lowerStr) >= 0 ||
                consumer.org_name && consumer.org_name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filteredConsumer: filteredConsumers
        })
    }
    onCheckinSelect = (id: number, checked: boolean) => {
        const { filteredConsumer } = this.state;
        const consumer = filteredConsumer.filter((c, i) => c.id == id)
        consumer.forEach((m) =>
            m.selected = checked
        )
        this.setState({
            filteredConsumer
        }, () => this.updateCount())
    }

    // getNearByFcs = () => {

    //     Server.get().getNearByFcsTouser({})
    //         .then((fcs) => {
    //             this.setState({
    //                 fcs: fcs
    //             })
    //         })
    //         .catch((e) => {
    //             this.setState({
    //                 error: getApiError(e)
    //             })
    //         })
    // }

    updateCount = () => {
        const { consumers } = this.state;

        let selectedItems = consumers.items.filter((c) => c.selected)
        this.setState({
            selectedConsumersCount: selectedItems.length,
        })
    }
    handleChange = (value: any) => {
        this.setState({ _organization: value }, () => this.getAllConsumers());
    }
    _handleChange = (value: any) => {
        this.setState({ _fc: value }, () => this.getAllConsumers());
    }
    _handleChangeArea = (value: any) => {
        this.setState({ _area: value }, () => this.getAllConsumers());
    }
    onChange = (e: any) => {
        this.setState({ gender: e.target.value }, () => this.getAllConsumers());
    }
    _onChange = (e: any) => {
        this.setState({ status: e.target.value }, () => this.getAllConsumers());
    }
    setOrganizations = () => {
        const orgObjs = this.state.organizations.map((c) => {
            return {
                value: c.id,
                label: c.name
            }
        })
        return orgObjs;
    }

    setFitnessCenters = () => {
        const { fitnessCenters } = this.state;

        const fcObjs = fitnessCenters && fitnessCenters.map((c) => {
            return {
                value: c.id,
                label: c.name
            }
        })
        return fcObjs;
    }
    setArea = () => {
        const { area } = this.state;

        const areaObj = area && area.map((c) => {
            return {
                value: `${c.latitude}`,
                label: c.name
            }
        })
        return areaObj;
    }
    onSelectAll = (checked: boolean) => {

        const { filteredConsumer } = this.state;
        filteredConsumer.forEach((m) =>
            m.selected = checked
        )
        this.setState({
            filteredConsumer
        }, () => this.updateCount())
    }

    onSendMessage = () => {
        let user_ids = this.state.consumers.items.filter((i) => i.selected).map((x) => x.id);
        this.props.history.push('/gsf/create/message', { user_id: user_ids });
    }

    render() {
        const { error, filteredConsumer, plans, plan, consumer_id, show } = this.state;
        return (
            <div className='list'>
                <h2>Consumers</h2>
                <Pager count={this.state.consumers.count} limit={500} page={0} onChange={(limit, offset) => this.onPage(limit, offset)} />
                <div className='form-group'>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <div>Total consumers : {this.state.consumers.count}</div>
                <div>Total selected consumers : {this.state.selectedConsumersCount}</div>
                <Loader loading={this.state.isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <div className='form-group'>
                        <Select
                            value={this.state._organization}
                            onChange={this.handleChange}
                            isMulti={true}
                            placeholder='Select organizations'
                            options={this.setOrganizations()}
                        />
                    </div>
                    <div className='form-group'>
                        <Select
                            value={this.state._fc}
                            onChange={this._handleChange}
                            isMulti={true}
                            placeholder='Select fitness Centers'
                            options={this.setFitnessCenters()}
                        />
                    </div>
                    <div className='form-group'>
                        <Select
                            value={this.state._area}
                            onChange={this._handleChangeArea}
                            isMulti={true}
                            placeholder='Select area'
                            options={this.setArea()}
                        />
                    </div>
                    <div className='form-group'>
                        <select onChange={this.onChange} value={this.state.gender} className="form-control" placeholder='Select gender'>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</ option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <select onChange={this._onChange} value={this.state.status} className="form-control" placeholder='Select status'>
                            <option value='Active'>Active</option>
                            <option value='In-active'>In-active</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <input type="checkbox" name="checkbox" onChange={(e) => this.onSelectAll(e.target.checked)} /><span>Select All</span>
                    </div>
                    <div className="form-group">
                        <button type="button" className="btn btn-primary" onClick={this.onSendMessage}> <i className="fas fa-comments-alt"></i>Send message</button>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Select</th>
                                <th scope="col">Name / Last Used</th>
                                <th scope="col">Email</th>
                                <th scope="col">Corporate email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Organizaton name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Sessions</th>
                                <th scope="col">Units</th>
                                <th scope="col">City</th>
                                <th scope="col">City</th>
                                <th scope="col">Plan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredConsumer && filteredConsumer.map((c, i) =>
                                <tr key={c.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            name="active"
                                            onChange={(e) => this.onCheckinSelect(c.id, e.target.checked)}
                                            checked={c.selected}
                                        />

                                    </td>
                                    <td>
                                        <div>{c.name}</div>
                                        {c.last_used_at ?
                                            <div>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                    {c.last_used_at}
                                                </Moment>
                                            </div>
                                            :
                                            null
                                        }
                                    </td>
                                    <td>{c.email}</td>
                                    <td>{c.corporate_email}</td>
                                    <td>{c.phone}</td>
                                    <td>{c.org_name}</td>
                                    <td>{c.gender}</td>
                                    <td>{c.session_remaining || 0} of {c.sessions_limit || 0}/{c.limit_type || "week"}</td>
                                    <td>{c.min_unit ? c.min_unit : 0} => {c.max_unit ? c.max_unit : 10} {c.custom_limits ? <i className="fas fa-create-alt" /> : <i className="fas fa-create-alt" />}</td>
                                    <td>{c.address ? `${c.address} / ${c.city}` : ''}</td>
                                    <td>{c.plan_name ? c.plan_name : <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/activate/plan', { consumer: c })} data-target="#exampleModal" data-toggle="modal" >
                                        <text>Select Plan</text>
                                    </button>
                                    }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div >
        );
    };
}

export default withRouter(GSFConsumers);