import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    packages: models.FCPackage[]
    isLoading: boolean
    error: string | null
    isGSFuser: boolean
}
class FCPackages extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            packages: [] as models.FCPackage[],
            isLoading: true,
            isGSFuser: false,
            error: null,
        };
    }
    componentDidMount = () => {
        const { fc_id } = this.props
        Server.get().userType()
            .then((user_type) => {
                this.setState({
                    isGSFuser: user_type === "gsf_user",
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
        Server.get().getFCPackages({ fc_id })
            .then((packages) => {
                this.setState({
                    packages,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onDelete = (id: number) => {

        const { fc_id } = this.props
        const package_id = id

        if (confirm('Are you sure you want to delete this package?')) {

            Server.get().deleteFCPackage({ fc_id, package_id })
                .then(() => {
                    let statePackage = this.state.packages
                    statePackage = statePackage.filter((pkg) => pkg.id !== id)
                    this.setState({ packages: statePackage })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                    })
                })
        }
    }
    onSearch = (str: string) => {
        const { packages } = this.state;
        const filteredPackages = packages.filter((pkg) => {
            const lowerStr = str.toLowerCase()
            return pkg.name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            packages: filteredPackages
        })
    }

    render() {
        const { fc_id } = this.props;
        const { error, isLoading, isGSFuser } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>GetSetFit</h2>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/packages/create')}><i className="fas fa-create-alt"  ></i>New package</button>
                <div className='form-group'>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Period</th>
                                <th scope="col">Price</th>
                                <th scope="col">Description</th>
                                <th scope="col">Active</th>
                                <th scope="col">Commission</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.packages.map((c, i) =>
                                <tr key={c.id}>
                                    <td scope="row">{c.name}</td>
                                    <td>{c.period + " " + "months"}</td>
                                    <td>{c.price == 0 ? "" : `${c.price}`}</td>
                                    <td>{c.description}</td>
                                    <td>{c.active ? "Active" : "Inactive"}</td>
                                    {
                                        isGSFuser
                                            ?
                                            <td>{c.commission_in_percentage ? `${c.commission}%` : `₹${c.commission}`}</td>
                                            : null
                                    }
                                    <td><button type='button' className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/packages/update', { package: c })}><i className="fas fa-edit">Edit</i></button>
                                        <button type='button' className="btn btn-link" onClick={() => this.onDelete(c.id)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div >
        );
    }
}

export default withRouter(FCPackages);
