import * as React from 'react';
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError, getFormattedDate } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    error: string | null
    data: models.Paging<models.FCCheckin>
    start_date: Date
    end_date: Date
    checkins_count: number
    amount_total: number
    _items: models.FCBillItemWithSelect[]
    limit: number
    offset: number
    from: Date
    to: Date
    user_id: number,
    user_name: string,
    checkin_id: number,
    checkin_time: number,
    isSubmitBtnDisable: boolean
    select_all: boolean
    open: boolean
    isLoading: boolean
}

class FCCreateBills extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            data: { count: 0, items: [] as models.FCCheckin[] },
            start_date: new Date(),
            end_date: new Date(),
            checkins_count: 0,
            amount_total: 0,
            _items: [] as models.FCBillItemWithSelect[],
            limit: 500,
            user_id: 0,
            user_name: '',
            checkin_id: 0,
            checkin_time: 0,
            offset: 0,
            from: new Date(),
            to: new Date(),
            isSubmitBtnDisable: false,
            select_all: false,
            open: false

        };
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onCheckinSelect = this.onCheckinSelect.bind(this)
    }

    onSelectAll = (checked: boolean) => {

        const { _items: items } = this.state;
        const consumers = items.filter((c, i) => c.checkin_id)
        consumers.forEach((m) =>
            m.selected = checked
        )
        this.setState({
            _items: items
        }, () => this.updateCount())
    }

    onCheckinSelect = (checkin_id: number, checked: boolean) => {
        const { _items: items } = this.state;
        const bills = items.filter((c, i) => c.checkin_id == checkin_id)
        bills.forEach((m) =>
            m.selected = checked
        )
        this.setState({
            _items: items
        }, () => this.updateCount())
    }

    updateCount = () => {
        const { _items: items } = this.state;

        let selectedItems = items.filter((c) => c.selected)
        this.setState({
            checkins_count: selectedItems.length,
            amount_total: selectedItems.reduce((sum, i) => sum + i.amount, 0)
        })
    }

    componentDidMount = () => {
        const { fc_id } = this.props;
        const { start_date, end_date, limit, offset, _items: items } = this.state;
        this.updateCount();
        Server.get().getFCCheckinsForBills({ fc_id, from: start_date.getTime(), limit, offset, to: end_date.getTime() })
            .then((data) => {
                const billItems = data.items.map((m) => {
                    return {
                        selected: true,
                        user_id: m.user_id,
                        user_name: m.user_name,
                        checkin_id: m.id,
                        checkin_time: new Date(m.start_at).getTime(),
                        amount: m.amount
                    }
                })
                this.setState({
                    _items: billItems,
                })

            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }



    onStartDateChange = (date: Date) => {
        this.setState({
            start_date: date
        }, () => {
            this.componentDidMount();
        })
    }

    onEndDateChange = (date: Date) => {
        this.setState({
            end_date: date
        }, () => {
            this.componentDidMount();
        })
    }


    onSubmit = (event: any) => {
        event.preventDefault();
        const { fc_id } = this.props;
        const { amount_total, checkins_count, end_date, start_date, _items } = this.state;
        const items = _items.filter((m) => m.selected).map((c) => {
            return {
                amount: c.amount,
                checkin_id: c.checkin_id
            }
        })
        Server.get().createFCBills({ fc_id, amount_total, checkins_count, end_date: getFormattedDate(end_date).toString(), start_date: getFormattedDate(start_date).toString(), items })
            .then((r) => {
                this.props.history.goBack()
                this.setState({
                    isLoading: false
                })

            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false

                })
            })
    }


    render() {
        const { start_date, end_date, checkins_count, error, amount_total, isSubmitBtnDisable, _items: items, select_all, isLoading } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Create Bills</h1>
                        </div>
                    </ol>
                </nav>
                <div className="container">
                    <div className='row justify-content-center'>
                        <div className='col-sm-5'>
                            <div className='base-form'>
                                <form>
                                    {
                                        error
                                            ? <span style={{ color: 'red' }}>{error}</span>
                                            : null
                                    }
                                    <div className="form-group">
                                        <span>Start Date :</span>
                                        <DatePicker className="form-control"
                                            selected={start_date}
                                            onChange={this.onStartDateChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <span>End Date :</span>
                                        <DatePicker className="form-control"
                                            selected={end_date}
                                            onChange={this.onEndDateChange}
                                        />
                                    </div>
                                    <div>
                                        <div className="form-group">Checkins count:{checkins_count}</div>
                                        <div className="form-group">Amount total:{amount_total}</div>
                                        <div>
                                            <div>
                                                <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isSubmitBtnDisable}>Submit</button>
                                            </div>
                                            <div className='form-group'>
                                                <input type="checkbox" name="checkbox" onChange={(e) => this.onSelectAll(e.target.checked)} />Select all
                                            </div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Checkin</th>
                                                        <th scope="col">From date</th>
                                                        <th scope="col">Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        items.map((c) =>
                                                            <tr key={c.checkin_id}>
                                                                <td scope="row">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="active"
                                                                        onChange={(e) => this.onCheckinSelect(c.checkin_id, e.target.checked)}
                                                                        checked={c.selected}
                                                                    />
                                                                </td>
                                                                <th><Moment format="DD/MM/YYYY HH:mm">{c.checkin_time}</Moment></th>
                                                                <td>{c.user_name}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FCCreateBills);