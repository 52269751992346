import { Field, Form, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Error from '../common/Error';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    loading: boolean
    data: models.GSFCreateOrg
}
class GSFCreateOrganization extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            data: {
                address: '',
                hr_email: '',
                hr_name: "",
                hr_phone: '',
                limit_type: '',
                manager_email: '',
                manager_name: '',
                manager_phone: '',
                max_unit: 0,
                min_unit: 0,
                name: '',
                sessions_limit: 0,
            }
        };
    }
    schema = () => {
        return Yup.object().shape({
            address: Yup.string().label('Address').required(),
            hr_email: Yup.string().label('Hr email').required(),
            hr_name: Yup.string().label('Hr name').email().required(),
            hr_phone: Yup.string().label('Hr phone').matches(/^\d{10}$/, 'Not a valid phone number').required(),
            limit_type: Yup.string().label('Limit type').required(),
            manager_email: Yup.string().label('Manager email').email().required(),
            manager_name: Yup.string().label('Manager name').required(),
            manager_phone: Yup.string().label('Manager phone').matches(/^\d{10}$/, 'Not a valid phone number').required(),
            max_unit: Yup.number().label('Maximum unit').required(),
            min_unit: Yup.number().label('Minimum unit').required(),
            name: Yup.string().label('Organization name').required(),
            sessions_limit: Yup.number().label('Session limit').required(),
        })
    }
    onSubmit = (values: models.GSFCreateOrg, fa: FormikActions<any>) => {
        Server.get().createOrganization(values)
            .then((r: any) => {
                fa.setSubmitting(false)
                this.props.history.goBack()
            })
            .catch((e: any) => {
                fa.setErrors(e)
                fa.setSubmitting(false)
            })
    }


    render() {
        const { data } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Create organization</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <Formik initialValues={data} onSubmit={this.onSubmit} validationSchema={this.schema} validateOnBlur={true}
                                        render={({ isSubmitting, submitForm }) =>
                                            <Form>
                                                <div className='form-group'>
                                                    <label>Name</label>
                                                    <Field type='text' name="name" placeholder='Enter Name' className="form-control" />
                                                    <Error name="name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <Field type='text' className="form-control" name="address" placeholder='Enter Address' />
                                                    <Error name="address" />
                                                </div>
                                                <div className="form-group">
                                                    <label>HR name</label>
                                                    <Field type='text' className="form-control" name="hr_name" placeholder='Enter Hr name' />
                                                    <Error name="hr_name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>HR email</label>
                                                    <Field type='email' className="form-control" name="hr_email" placeholder='Enter Hr email' />
                                                    <Error name="hr_email" />
                                                </div>
                                                <div className="form-group">
                                                    <label>HR phone</label>
                                                    <Field type='text' className="form-control" name="hr_phone" placeholder='Enter Hr phone' />
                                                    <Error name="hr_phone" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Manager name</label>
                                                    <Field type='text' className="form-control" name="manager_name" placeholder='Enter manager name' />
                                                    <Error name="manager_name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Manager email</label>
                                                    <Field type='email' className="form-control" name="manager_email" placeholder='Enter manager email' />
                                                    <Error name="manager_email" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Manager phone</label>
                                                    <Field type='text' className="form-control" name="manager_phone" placeholder='Enter manager phone' />
                                                    <Error name="manager_phone" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Minimum unit</label>
                                                    <Field type='number' className="form-control" name="min_unit" placeholder='Minimum unit' />
                                                    <Error name="min_unit" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Maximum unit</label>
                                                    <Field type='number' className="form-control" name="max_unit" placeholder='Maximum unit' />
                                                    <Error name="max_unit" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Session limit</label>
                                                    <Field type='number' className="form-control" name="sessions_limit" placeholder='Session limit' />
                                                    <Error name="sessions_limit" />
                                                </div>
                                                <div>
                                                    <label>Limit type:</label>
                                                    <Field component="select" className="form-control" name="limit_type">
                                                        <option value='month'>Month</option>
                                                        <option value='week'>Week</option>
                                                    </Field>
                                                    <Error name="limit_type" />
                                                </div>
                                                <div className="form-group" >
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                                </div>
                                            </Form>
                                        }
                                    >
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default GSFCreateOrganization;
