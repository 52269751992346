import md5 from 'md5';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError, isValidOTP } from '../common/HelperMethods';
import { FC_NEW_PASSWORD, GSF_NEW_PASSWORD, ORG_NEW_PASSWORD } from '../common/Routes';
import { models } from '../models';
import { Server } from '../common/Server';


export interface IProps extends RouteComponentProps<any> {
    user_type: 'end_user' | 'fc_user' | 'gsf_user' | 'org_user'

}

interface IState {
    error: string | null
    color: "success" | "error" | null
    otp: string
    otpMd5?: string
    isNextBtnDisable: boolean
}

class EnterOtp extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            error: null,
            color: null,
            otp: '',
            otpMd5: this.props.history.location.state.otpMd5,
            isNextBtnDisable: false,
        };
    }


    resendCode = (event: any) => {
        event.preventDefault()

        const { emailorphone } = this.props.history.location.state;
        const req = {} as models.ForgotPasswordReq

        if (emailorphone.indexOf('@') > 0) {
            req.email = emailorphone
        } else {
            req.phone = emailorphone
        }
        req.user_type = this.props.user_type;

        Server.get().forgotPassword(req)
            .then(({ otpMd5 }) => {
                this.setState({
                    otpMd5: otpMd5,
                    isNextBtnDisable: true,
                    error: `OTP code sent to your ${emailorphone} again`,
                    color: "success"
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error"
                })
            })
    }

    checkOtp = (code: string) => {

        const { emailorphone } = this.props.history.location.state
        const { otpMd5 } = this.props.history.location.state
        const { user_type } = this.props;

        if (!isValidOTP(code)) {
            this.setState({ error: 'Otp must be of 6 digits', color: "error" })
            return
        } else if (md5(code) !== otpMd5) {
            this.setState({ error: 'invalid otp entered', color: 'error' })
        } else {

            if (user_type === 'fc_user') {
                this.props.history.push(FC_NEW_PASSWORD, { otp: code, emailorphone: emailorphone, user_type: user_type })
            }
            if (user_type === 'gsf_user') {
                this.props.history.push(GSF_NEW_PASSWORD, { otp: code, emailorphone: emailorphone, user_type: user_type })

            } if (user_type === 'org_user') {
                this.props.history.push(ORG_NEW_PASSWORD, { otp: code, emailorphone: emailorphone, user_type: user_type })
            }
        }
    }

    handleBack = (event: any) => {
        event.preventDefault()
        this.props.history.goBack()
    }

    render() {
        const { otp, error, isNextBtnDisable, color } = this.state
        const { emailorphone } = this.props.history.location.state
        return (
            <div className="container" >
                <div className='row justify-content-center'>
                    <div className="title">
                        <img src="/logo/apple-icon-60x60.png" width='60' height='60' alt='' />
                        <h1 text-align="center">GetSet.Fit</h1>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className="col-sm-4">
                        <div className="card">
                            <article className="card-body">
                                <h4 className="card-title text-center mb-4 mt-1">Verification Code</h4>
                                <hr />
                                {
                                    error
                                        ? <p className="text-danger text-center" style={{ color: color === "error" ? 'red' : 'green' }}>{error}</p>
                                        : <p className="text-center" style={{ color: 'green' }}>{`Please enter the OTP sent to your ${emailorphone}`}</p>
                                }
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"> <i className="fa fa-user-secret"></i> </span>
                                        </div>
                                        <input name="otp" className="form-control" placeholder="Enter OTP" value={otp} onChange={(e) => this.setState({ otp: e.target.value })} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary btn-block" onClick={() => this.checkOtp(otp)} disabled={isNextBtnDisable}>Next</button>
                                </div>
                                <p className="text-center">
                                    <button type="button" className="btn btn-link" onClick={this.resendCode}>Resend OTP</button> |
                                    <button type="button" className="btn btn-link" onClick={this.handleBack}>Back</button>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(EnterOtp);
