import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    billing: models.FCBilling
    methods: models.FCBillingMethod[]
    error: string | null
    isLoading: boolean
}
class FCBilling extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            billing: {} as models.FCBilling,
            error: null,
            methods: [] as models.FCBillingMethod[]
        };
    }

    componentDidMount() {
        Server.get().getFCBilling({ fc_id: this.props.fc_id })
            .then((billing) => {
                this.setState({
                    billing: billing,
                    methods: billing.methods,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onDelete = (billing_method_id: number) => {
        const { fc_id } = this.props
        if (confirm('Are you sure you want to delete this billing?')) {
            Server.get().deleteFCBillingMethod({ billing_method_id, fc_id })
                .then(() => {
                    let stateMethod = this.state.methods
                    stateMethod = stateMethod.filter((mthd) => mthd.id !== billing_method_id)
                    this.setState({ methods: stateMethod })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        isLoading: false
                    })
                })
        }
    }


    render() {
        const { billing, methods, error, isLoading } = this.state;
        const { fc_id } = this.props;
        return (
            <div className='details' >
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Billing Details</h1>
                        </div>
                    </ol>
                </nav>
                <div>
                    <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/billing/update', { billing: billing })}><i className="fas fa-edit"></i>Edit</button>
                </div>
                <div className="details">
                    <div>GST : {billing.gst}</div>
                    <div>PAN : {billing.pan}</div>
                </div>
                <div>
                    <Loader loading={this.state.isLoading}>
                        {
                            error
                                ? <span style={{ color: 'red' }}>{error}</span>
                                : null
                        }
                        <h3>Billing Methods</h3>
                        <button type="button" className="btn btn-primary" onClick={() => this.props.history.push('/fc/' + fc_id + '/billing/method/create', { methods: methods })}><i className="fas fa-plus-square"></i>Add Billing Method</button>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Account holder name</th>
                                    <th scope="col">Account number</th>
                                    <th scope="col">Active</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.methods && this.state.methods.map((m, i) =>
                                    <tr>
                                        <th scope="row">{m.type}</th>
                                        <td>{m.account_name}</td>
                                        <td>{m.account_no}</td>
                                        <td>{m.active ? 'Active' : 'In-active'}</td>
                                        <td>
                                            <button type='button' className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/billing/method/update', { method: m })}><i className="fas fa-edit">Edit</i></button>
                                            <button type='button' className="btn btn-link" onClick={(e) => this.onDelete(m.id)}><i className="fas fa-trash-alt">Delete</i></button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Loader>
                </div>
            </div>
        );
    }
}

export default withRouter(FCBilling);
