import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import SearchField from "react-search-field";
import { models } from '../../models';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    org_id: number
}
interface Istate {
    users: models.OrgUser[]
    loading: boolean
    error: string | null
}
class OrgUsers extends React.Component<IProps, Istate> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            users: [] as models.OrgUser[],
            loading: true,
            error: null
        }

    }

    orgUsers = () => {
        this.setState({
            loading: true
        })
    }
    componentDidMount = () => {
        Server.get().getOrgUsers({ org_id: this.props.org_id }).then((users) => {
            this.setState({
                users,
                loading: false

            })
        }).catch((e: any) => {
            this.setState({
                error: getApiError(e),
                loading: false
            })
        })
    }

    onDelete = (id: number) => {
        const { org_id } = this.props;
        let { users } = this.state;
        const user_id = id;

        if (confirm('Are you sure you want to delete this user ?')) {
            Server.get().deleteOrgUser({ org_id, user_id })
                .then(() => {
                    let stateUser = this.state.users
                    stateUser = stateUser.filter((user) => user.id !== id)
                    this.setState({ users: stateUser })

                })
                .catch((e) => {
                    this.setState({
                        error: getApiError(e),
                        loading: false
                    })
                })
        }

    }
    onSearch = (input: string) => {
        const { users } = this.state;

        const _filteredUsers = users.filter((user) => {
            const lowerStr = input.toLowerCase()
            return user.name.toLowerCase().indexOf(lowerStr) >= 0 ||
                user.email.toLowerCase().indexOf(lowerStr) >= 0 ||
                user.phone.toLowerCase().indexOf(lowerStr) >= 0 ||
                user.role.toLowerCase().indexOf(lowerStr) >= 0
        })

        this.setState({
            users: _filteredUsers,
            loading: false
        })
    }

    render() {
        const { org_id } = this.props;
        const { error } = this.state;
        return (
            <div className='list'>
                <h2>User</h2>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/user/create')}><i className="fas fa-create-alt"></i>New User</button>
                <Loader loading={this.state.loading}>
                    <div>
                        <SearchField
                            placeholder="Search..."
                            onChange={this.onSearch}
                            classNames="test-class"
                        />
                    </div>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Role</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map((c, i) =>
                                <tr key={c.id}>
                                    <th scope="row">{c.name}</th>
                                    <td>{c.email}</td>
                                    <td>{c.phone}</td>
                                    <td>{c.role}</td>
                                    <td><button type='button' className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/user/update', { user: c })}><i className="fas fa-edit">Edit</i></button>
                                        <button type='button' className="btn btn-link" onClick={() => this.onDelete(c.id)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>

                            )}
                        </tbody>

                    </table>
                </Loader>

            </div>
        );
    }
}
export default withRouter(OrgUsers);
