import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Server } from '../../common/Server';
import { getApiError } from '../../common/HelperMethods';
import { models } from '../../models';
import Loader from '../common/Loader';



interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    loading:boolean
    amount: string
    bill_date: Date
    bill_id: number
    ref_no: string
    create_at: string
    error: string | null
    isDisableSubmit: boolean
    id: number
    method_id: number
}

class FCUpdatePayment extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        const fcPayment = this.props.history.location.state.bill as models.FCPayment

        this.state = {
            loading:false,
            amount: fcPayment.amount.toString(),
            bill_date: new Date(),
            bill_id: fcPayment.bill_id,
            ref_no: fcPayment.ref_no,
            create_at: fcPayment.create_at,
            error: null,
            isDisableSubmit: false,
            id: fcPayment.id,
            method_id: fcPayment.method_id
        };
    }

    getValidationErr = (amount: string, ref_no: string) => {

        let err = ""

        if (!amount) {
            err = "Enter amount"
            return
        } if (!ref_no) {
            err = 'Enter reference number'
        }

        return err

    }

    onSubmit = () => {
        let { amount, ref_no, bill_date, bill_id, create_at, method_id, id } = this.state;
        amount = amount.replace(',', '').trim()
        const err = this.getValidationErr(amount, ref_no);
        const { fc_id } = this.props;

        if (err) {
            this.setState({ error: err })
            return
        }

        this.setState({ isDisableSubmit: true })

        Server.get().updateFCPayment({ ref_no, fc_id, amount: parseInt(amount), bill_date: bill_date.getTime(), bill_id, create_at, id, method_id })
            .then((r) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isDisableSubmit: false
                })
            })
    }

    render() {
        const { error, amount, isDisableSubmit, ref_no } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update payments</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        <h2>Update payments</h2>
                                        {
                                            error
                                                ? <span style={{ color: "red" }}>{error}</span>
                                                : null
                                        }
                                        <div className="form-group">
                                            <label>Amount</label>
                                            <input type='amount' name="amount" placeholder='Enter amount' autoCapitalize="off" value={amount} onChange={(e) => this.setState({ amount: e.target.value })} />
                                        </div>

                                        <div className="form-group">
                                            <label>Reference no.</label>
                                            <input type='amount' name="amount" placeholder='Reference no.' autoCapitalize="off" value={ref_no} onChange={(e) => this.setState({ ref_no: e.target.value })} />
                                        </div>
                                        <button color='primary' className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCUpdatePayment);