import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { models } from '../../models';
import { Server } from '../../common/Server';
import { getApiError, VERSION } from '../../common/HelperMethods';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    fcDetails: models.FCDetails
    error: string | null
    isGSFUser: boolean
    loading: boolean
    user_id: string
}

class FCSidebar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            fcDetails: {} as models.FCDetails,
            error: null,
            isGSFUser: false,
            loading: false,
            user_id: ''
        };
    }
    fcDetails = () => {
        this.setState({
            loading: true
        })
    }

    handleLogout = () => {
        const { isGSFUser } = this.state
        Server.get().logout().then(() => {
            if (isGSFUser == true) {
                this.props.history.replace('/gsf/login')
            }
            else {
                this.props.history.replace('/fc/login')
            }
        })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    componentDidMount = () => {
        const { fc_id } = this.props;
        Server.get().userType().then(user_type => {
            this.setState({ isGSFUser: user_type === 'gsf_user' })
        })

        Server.get().userId().then(id => {
            this.setState({ user_id: id! })
        })

        Server.get().getFCDetails({ fc_id })
            .then((fcDetails) => {
                this.setState({
                    fcDetails: fcDetails,
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                })

            })
    }
    render() {
        const { fc_id } = this.props;
        const { fcDetails, isGSFUser, user_id } = this.state;

        return (
            <nav id="sidebar" className="sidebar-wrapper">
                {isGSFUser ?
                    <div className='sidebar-home'>
                        <NavLink to={"/gsf/fitnesscenter"}>
                            <span>Home</span>
                        </NavLink>
                    </div> : null
                }
                <div className='sidebar-header'>
                    <h4>
                        {fcDetails.name}
                    </h4>
                </div>
                <div className="sidebar-menu">
                    <ul>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/details"}>
                                <i className="fa fa-info"></i>
                                <span>Details</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/billing"}>
                                <i className="fa fa-calendar"></i>
                                <span>Billings</span>
                            </NavLink>
                        </li>


                        <li>
                            <NavLink to={"/fc/" + fc_id + "/contacts"}>
                                <i className="fa fa-users"></i>
                                <span>Contacts</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={"/fc/" + fc_id + "/dashboard"}>
                                <i className="fa fa-tachometer-alt"></i>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/equipment"}>
                                <i className="fa fa-dumbbell"></i>
                                <span>Equipments</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/facilities"}>
                                <i className="fa fa-smile-beam"></i>
                                <span>Facilities</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/images"}>
                                <i className="fa fa-images"></i>
                                <span>Images</span>
                            </NavLink>
                        </li>
                        <li >
                            <NavLink to={"/fc/" + fc_id + "/slots"}>
                                <i className="fa fa-dumbbell"></i>
                                <span>Slots</span></NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/packages"}>
                                <i className="fa fa-box"></i>
                                <span>Packages</span>
                            </NavLink>
                        </li>
                        {user_id === '3' ?
                            <li>
                                <NavLink to={"/fc/" + fc_id + "/pricesettings"}>
                                    <i className="fa fa-money-bill-alt"></i>
                                    <span>PriceSettings</span>
                                </NavLink>
                            </li> : null}
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/packagesales"}>
                                <i className="fa fa-th-list"></i>
                                <span>Sales</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={"/fc/" + fc_id + "/bills"}>
                                <i className="fa fa-file-invoice"></i>
                                <span>Bills</span></NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/payments"}>
                                <i className="fa fa-file-invoice-dollar"></i>
                                <span>Payments</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/checkins"}>
                                <i className="fa fa-bolt"></i>
                                <span>Checkins</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/user"}>
                                <i className="fa fa-users"></i>
                                <span>Users</span></NavLink>
                        </li>
                        <li>
                            <NavLink to={"/fc/" + fc_id + "/holidays"}>
                                <i className="fa fa-file-invoice"></i>
                                <span>Holidays</span></NavLink>
                        </li>
                        <li>
                            <NavLink to={'/fc/' + fc_id + '/logo/upload'}>
                                <i className="fa fa-images"></i>
                                <span>Logo</span>
                            </NavLink>
                        </li>
                    </ul>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={this.handleLogout}>Logout</button>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="align-content:center" style={{ padding: 5 }}>
                        Version {VERSION}
                    </div>
                </div>
            </nav>
        );

    }
}

export default withRouter(FCSidebar);