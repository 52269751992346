import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    paymentDetails: models.FCPaymentDetail[]
    isLoading: boolean
    error: string | null
}
class FCPaymentDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            paymentDetails: [] as models.FCPaymentDetail[],
            isLoading: true,
            error: null
        };

    }

    fcPaymentDetails = () => {
        this.setState({
            isLoading: true
        })
    }

    componentDidMount = () => {
        const { fc_id } = this.props
        const payment_id = this.props.history.location.state.id

        Server.get().getFCPaymentDetails({ fc_id, payment_id })
            .then((paymentDetails) => {
                this.setState({
                    paymentDetails: paymentDetails.items,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    render() {
        const { error, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Payment Details</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col">Starts at</th>
                                <th scope="col">Units</th>
                                <th scope="col">User Name</th>
                                <th scope="col">User image</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.paymentDetails.map((c, i) =>
                                <tr key={c.id}>
                                    <th scope="row">{c.start_at}</th>
                                    <td>{c.units}</td>
                                    <td>{c.user_name}</td>
                                    <td>{c.user_img}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCPaymentDetails);
