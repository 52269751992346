
import { Field, Form, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Error from '../common/Error';
import Loader from '../common/Loader';
import { valueFocusAriaMessage } from 'react-select/lib/accessibility';
import { getApiError } from '../../common/HelperMethods';


interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    loading: boolean
    isDisableSubmit: boolean
    error: string | null
    color: "error" | "success" | null
    data: models.GSFUpdatePlan
}
class GSFUpdatePlan extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const plan = this.props.history.location.state.plan
        this.state = {
            loading: false,
            isDisableSubmit: false,
            error: null,
            color: null,
            data: {
                id: plan.id,
                name: plan.name || '',
                units: plan.units || 0,
                duration: plan.duration || 0,
                total_value: plan.total_value || '',
                max_unit: plan.max_unit || '',
                min_unit: plan.min_unit || '',
                sessions_limit: 0,
                limit_type: "month"
            }
        };
    }
    schema = () => {
        return Yup.object().shape({
            name: Yup.string().label('Name').required(),
            units: Yup.number().label('Units').required(),
            duration: Yup.number().label('Duration').required(),
            total_value: Yup.string().label('Total value').required(),
            max_unit: Yup.string().label('max_unit').required(),
            min_unit: Yup.string().label('Min Unit').required(),
            sessions_limit: Yup.number().label('Session limit').required()

        })
    }
    onSubmit = (values: models.GSFUpdatePlan, fa: FormikActions<any>) => {
        Server.get().updateGSFPlan(values)
            .then((r) => {
                fa.setSubmitting(false)
                this.props.history.goBack();
            })
            .catch((e: any) => {
                fa.setErrors(e)
                fa.setSubmitting(false)
            })
    }
    render() {
        const { data } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update Plan</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <Formik initialValues={data} onSubmit={this.onSubmit} validationSchema={this.schema} validateOnBlur={true}
                                        render={({ values, isSubmitting, error, setFieldValue }) =>
                                            <Form>
                                                {
                                                    error
                                                        ? <span style={{ color: 'red' }}>{error}</span>
                                                        : null
                                                }
                                                <div className='form-group'>
                                                    <Field type='text' name="name" placeholder='Name' className="form-control" />
                                                    <Error name="name" />
                                                </div>

                                                <div className='form-group'>
                                                    <Field type='text' name="units" placeholder='Units' className="form-control" />
                                                    <Error name="units" />
                                                </div>
                                                <div className='form-group'>
                                                    <Field type='text' name="max_unit" placeholder='Max Units' className="form-control" />
                                                    <Error name="max_unit" />
                                                </div>
                                                <div className='form-group'>
                                                    <Field type='text' name="min_unit" placeholder='Min Units' className="form-control" />
                                                    <Error name="min_unit" />
                                                </div>
                                                <div className='form-group'>
                                                    <Field type='text' name="duration" placeholder='Duration in terms of days' className="form-control" />
                                                    <Error name="duration" />
                                                </div>
                                                <div className='form-group'>
                                                    <Field type='text' name="total_value" placeholder='Total value' className="form-control" />
                                                    <Error name="total_value" />
                                                </div>
                                                <div className='form-group'>
                                                    <Field type='text' name="total_value" placeholder='Total value' className="form-control" />
                                                    <Error name="total_value" />
                                                </div>
                                                <div className='form-group'>
                                                    <Field type='text' name="sessions_limit" placeholder='Session limit' className="form-control" />
                                                    <Error name="sessions_limit" />
                                                </div>
                                                <div>
                                                    <Field component="select" className="form-control" name="limit_type">
                                                        <option value={'month'}>Month</option>
                                                        <option value={'week'}>Week</option>
                                                    </Field>
                                                </div>
                                                <div className="form-group" >
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                                </div>
                                            </Form>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div >
        );
    }
}
export default GSFUpdatePlan;