import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    loading: boolean
    isdisableSubmit: boolean
    name: string
    description: string
    price: string
    error: string | null
    color: "error" | "success" | null
    period: number
    active: boolean
    id: number,
    commission: string
    isGsfUser: boolean
    commissionInPercentage: boolean
}
class FCUpdatePackage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const fcpackage = this.props.history.location.state.package as models.FCPackage
        this.state = {
            loading: false,
            isdisableSubmit: false,
            name: fcpackage.name,
            description: fcpackage.description,
            price: fcpackage.price.toString(),
            error: null,
            color: null,
            period: fcpackage.period,
            active: fcpackage.active,
            id: fcpackage.id,
            commission: fcpackage.commission || '',
            isGsfUser: false,
            commissionInPercentage: fcpackage.commission_in_percentage
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleChange = () => {
        const { active } = this.state
        this.setState({ active: !active });
    }

    handleOnChange = (event: any) => {
        this.setState({ period: parseInt(event.target.value) })
    }


    getValidationErr = (name: string, description: string, price: string) => {
        let err = ""
        if (!name) {
            err = 'Enter name'
        } if (!price) {
            err = "Enter the amount of your package"
        } if (!description) {
            err = "Enter package description"
        }
        return err
    }

    componentDidMount = () => {
        Server.get().userType()
            .then((user_type) => {
                this.setState({
                    isGsfUser: user_type === 'gsf_user'
                })
            })
    }
    onChange = () => {
        const { commissionInPercentage } = this.state
        this.setState({ commissionInPercentage: !commissionInPercentage })
    }
    onSubmit = (event: any) => {

        event.preventDefault()
        let { name, description, price, period, active, id, commission, commissionInPercentage } = this.state
        price = price.replace(',', '').trim()

        const err = this.getValidationErr(name, price, description)
        const { fc_id } = this.props

        if (err) {
            this.setState({ error: err, color: "error" })
            return
        }
        this.setState({ isdisableSubmit: true })

        Server.get().updateFCPackage({ name, id, fc_id, active, description, period, price: parseInt(price), commission, commission_in_percentage: commissionInPercentage })
            .then((r: any) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isdisableSubmit: false,
                    color: "error"
                })
            })
    }
    render() {
        const { isdisableSubmit, name, description, price, period, active, error, isGsfUser, commission, commissionInPercentage } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update package</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className="container">
                        <div className='row justify-content-center'>
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }
                                        <div className="form-group">
                                            <input type='name' className="form-control" name="name" placeholder='Name' autoCapitalize="off" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <textarea className="form-control" placeholder='Description' rows={3} autoCapitalize="off" value={description} onChange={(e) => this.setState({ description: e.target.value })} ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <input type='price' className="form-control" name="price" placeholder='Price.' autoCapitalize="off" value={price} onChange={(e) => this.setState({ price: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <span>Period:</span>
                                                <select onChange={this.handleOnChange} value={period} className="form-control">
                                                    <option value='1'>1 month</option>
                                                    <option value='3'>3 month</option>
                                                    <option value='6'>6 month</option>
                                                    <option value='12'>12 month</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <input type="checkbox" name="active" value="checked" onChange={this.handleChange} checked={active} />
                                            <span>Active</span>
                                        </div>
                                        {
                                            isGsfUser
                                                ?
                                                <div className="form-group">
                                                    <span>Commission:</span>
                                                    <input type='name' className="form-control" name="commission" placeholder='Commission' autoCapitalize="off" value={commission} onChange={(e) => this.setState({ commission: e.target.value })} />
                                                    <input type="checkbox" name="active" value="checked" onChange={this.onChange} checked={commissionInPercentage} />
                                                    <span>Commission in percentage</span>
                                                </div>
                                                : null
                                        }
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isdisableSubmit}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCUpdatePackage);
