import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import SearchField from "react-search-field";
import { Server } from '../../common/Server';
import Loader from '../common/Loader';
import { models } from '../../models';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isLoading: boolean
    error: string | null
    facilities: models.GSFFacility[],
    filteredFacilities: models.GSFFacility[],

}
class GSFFacilities extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            facilities: [] as models.GSFFacility[],
            filteredFacilities: [] as models.GSFFacility[],

        };
    }
    onDelete = (name: string) => {

        if (confirm('Are you sure you want to delete this facility?')) {
            Server.get().deleteGSFFacility({ name })
                .then(() => {
                    let facilities = this.state.facilities
                    facilities = facilities.filter((facilitie) => facilitie.name !== name)
                    this.setState({
                        filteredFacilities: facilities,
                        isLoading: false
                    })

                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        isLoading: false
                    })
                })
        }
    }
    componentDidMount = () => {
        Server.get().getAllFacilities({})
            .then((facilities) => {
                this.setState({
                    facilities,
                    filteredFacilities: facilities,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }
    onSearch = (str: string) => {
        const { facilities } = this.state;
        const filteredFacilities = facilities.filter((facility) => {
            const lowerStr = str.toLowerCase()
            return facility.name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filteredFacilities: filteredFacilities,
            isLoading: false
        })
    }

    public render() {
        const { filteredFacilities } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Facilities</h2>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/facilitie/create')}><i className="fas fa-create-alt"></i>Add Facility</button>
                <div className='form-group'>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <Loader loading={this.state.isLoading}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                        <tbody>

                            {filteredFacilities.map((c) =>
                                <tr key={c.id}>
                                    <td scope="row">
                                        {c.name}
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-link"><i className="fas fa-trash-alt" onClick={() => this.onDelete(c.name)}>Delete</i></button>
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(GSFFacilities);