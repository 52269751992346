import * as React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    amount: string
    ref_no: string
    error: string | null
    isDisableSubmit: boolean
    from_date: Date
    to_date: Date
    bank: string
    isUpiId: boolean
    billing: models.FCBilling,
    method_id: number
    isLoading: boolean
}

class FCCreatePayments extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);


        this.state = {
            isLoading: true,
            amount: '',
            ref_no: '',
            error: null,
            isDisableSubmit: false,
            from_date: new Date(),
            to_date: new Date(),
            bank: '',
            isUpiId: false,
            billing: {} as models.FCBilling,
            method_id: 0
        };

    }

    getValidationErr = (amount: string, ref_no: string) => {

        let err = ""

        if (!amount) {
            err = "Enter amount"
            return
        }
        if (!ref_no) {
            err = "Enter reference no."
        }

        this.setState({ isDisableSubmit: true })
        return err

    }

    componentDidMount = () => {
        const { fc_id } = this.props;


        Server.get().getFCBilling({ fc_id })
            .then((billing) => {
                const id = billing.methods.filter(c => c.active).map(m => m.id)[0]
                this.setState({
                    method_id: id,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }



    onSubmit = (event: any) => {

        event.preventDefault()
        let { amount, ref_no, method_id } = this.state;
        amount = amount.replace(',', '').trim()
        const err = this.getValidationErr(amount, ref_no);
        const { fc_id } = this.props;
        const bill_id = this.props.history.location.state.bill_id

        if (err) {
            this.setState({ error: err })
            return
        }

        this.setState({ isDisableSubmit: true })

        Server.get().createFCPayment({ fc_id, amount: parseInt(amount), bill_id, method_id, ref_no })
            .then((r) => {
                this.props.history.goBack()
                this.setState({
                    isLoading: false
                })


            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isDisableSubmit: false,
                    isLoading: false
                })
            })
    }

    render() {
        const { error, amount, isDisableSubmit, ref_no, isLoading } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Create payments</h1>
                        </div>
                    </ol>
                </nav>
                <div className='container' >
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="base-form">
                                <form>
                                    {
                                        error
                                            ? <span style={{ color: "red" }}>{error}</span>
                                            : null
                                    }
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type='amount' name="amount" placeholder='Enter amount' autoCapitalize="off" value={amount} onChange={(e) => this.setState({ amount: e.target.value })} />
                                    </div>

                                    <div className="form-group">
                                        <label>Reference no.</label>
                                        <input type='amount' name="amount" placeholder='Reference no.' autoCapitalize="off" value={ref_no} onChange={(e) => this.setState({ ref_no: e.target.value })} />
                                    </div>
                                    <button color='primary' className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FCCreatePayments);