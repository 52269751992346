import * as React from 'react';
interface IProps {
    loading: boolean
}

class Loader extends React.Component<IProps, any> {
    public render() {
        const loading = this.props.loading
        return (
            loading ?
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                : this.props.children
        )
    }
}

export default Loader