import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Server } from '../../common/Server';
import { models } from '../../models';
import { getApiError } from '../../common/HelperMethods';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    org_id: number
}

interface IState {
    domain: models.OrgDomain[]
    loading: boolean
    error: string | null
}
class OrgDomains extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            domain: [] as models.OrgDomain[],
            loading: true,
            error: null
        };
    }

    componentDidMount = () => {
        const { org_id } = this.props;

        Server.get().getOrgDomains({ org_id })
            .then((domains) => {
                this.setState({
                    domain: domains,
                    loading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })
    }

    onDelete = (domain: string) => {
        const { org_id } = this.props;

        if (confirm('Are you sure you want to delete this domain ?')) {

            Server.get().deleteOrgDomain({ domain, org_id })
                .then(() => {
                    let stateDomain = this.state.domain
                    const domains = stateDomain.filter((domains) => domains.domain !== domain)
                    this.setState({
                        domain: domains,
                        loading: false
                    })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        loading: false
                    })
                })
        }

    }
    public render() {
        const { org_id } = this.props;
        const { error } = this.state;
        return (
            <div className='list'>
                <h2>Organization domains</h2>
                <button type="Button" className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/domain/create')}><i className="fas fa-create-alt"></i>New domains</button>
                <Loader loading={this.state.loading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Domain</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.domain.map((c) =>
                                <tr>
                                    <td scope="row">{c.domain}</td>
                                    <td>
                                        <button type="button" className='btn btn-link' onClick={() => this.onDelete(c.domain)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default OrgDomains;
