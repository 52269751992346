import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';


interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isDisableSubmit: boolean
    name: string
    error: string | null
    color: "success" | "error" | null
    plans: models.GSFPlan[]
    consumer_id: number
    consumer_name: string
    consumer_email: string
    consumer_phone: string
    plan_id: number
}
class GSFActivatePlan extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const consumer = this.props.history.location.state.consumer
        this.state = {
            consumer_name: consumer.name,
            consumer_email: consumer.email,
            consumer_phone: consumer.phone,
            consumer_id: consumer.id,
            isDisableSubmit: false,
            name: '',
            error: null,
            color: null,
            plans: [] as models.GSFPlan[],
            plan_id: 0
        };
    }
    componentDidMount = () => {
        Server.get().getGSfPlans({}).then((plans) => {
            this.setState({ plans })
        })
    }

    onSubmit = (event: any) => {
        event.preventDefault()

        const { consumer_id, plan_id } = this.state;
        console.log('plan id', plan_id)
        Server.get().activateConsumerPlan({ consumer_id, plan_id })
            .then((r) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error",
                    isDisableSubmit: false
                })
            })
    }
    onCancel = () => {
        this.props.history.goBack()
    }
    render() {
        const { plans, plan_id, isDisableSubmit, error, consumer_name, consumer_email, consumer_phone } = this.state;
        return (
            <div className='form'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Actavate Plan</h2>
                        </div>
                    </ol>
                </nav>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="base-form">
                                <form>
                                    {
                                        error
                                            ? <span style={{ color: 'red' }}>{error}</span>
                                            : null
                                    }
                                    <div className="form-group">
                                        <div>
                                            Consumer Name:{consumer_name}
                                        </div>
                                        <div>
                                            Consumer Email:{consumer_email}
                                        </div>
                                        <div>
                                            Consumer Phone:{consumer_phone}
                                        </div>
                                        <label>Select Plan</label>
                                        <select
                                            onChange={(e) => this.setState({ plan_id: parseInt(e.target.value) })}
                                            value={plan_id}
                                            className="form-control"
                                        >
                                            {plans.map(c =>
                                                <option key={c.id} value={c.id}>{c.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className='row'>
                                        <div className='m-2'> <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button></div>
                                        <div className='m-2'><button type="button" className="btn btn-primary" onClick={this.onCancel} disabled={isDisableSubmit}>Cancel</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GSFActivatePlan);
