import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { getApiError, VERSION } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';



interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    fcDetails: models.FCDetails
    error: string | null
    user_id: string
    loading: boolean

}

class GSFSidebar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            fcDetails: {} as models.FCDetails,
            error: null,
            loading: false,
            user_id: ''
        };
    }
    handleLogout = () => {
        Server.get().logout().then(() => {
            this.props.history.replace('/gsf/login')
        })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }

    componentDidMount = () => {
        Server.get().userId().then(id => {
            this.setState({ user_id: id! })
        })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    render() {
        const { user_id } = this.state;
        return (
            <nav id="sidebar" className="sidebar-wrapper">
                <div className='sidebar-header'>
                    <h3>
                        GetSet.Fit
                    </h3>
                </div>
                <div className="sidebar-menu">
                    <ul>
                        <li>
                            <NavLink to={'/gsf/dashboard'}>
                                <i className="fa fa-info"></i>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/gsf/fitnesscenter'}>
                                <i className="fa fa-calendar"></i>
                                <span>Fitnesss Centers</span>
                            </NavLink>
                        </li>
                        {user_id === '1' || '3' ? <li>
                            <NavLink to={'/gsf/organization'}>
                                <i className="fa fa-users"></i>
                                <span>Organizations</span>
                            </NavLink>
                        </li> : null}
                        <li>
                            <NavLink to={'/gsf/users'}>
                                <i className="fa fa-tachometer-alt"></i>
                                <span>Users</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/gsf/plans'}>
                                <i className="fa fa-dumbbell"></i>
                                <span>Plans</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/gsf/equipment'}>
                                <i className="fa fa-dumbbell"></i>
                                <span>Equipments</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/gsf/facilitie'}>
                                <i className="fa fa-smile-beam"></i>
                                <span>Facilities</span>
                            </NavLink>
                        </li>
                        {user_id === '3' || '1' ? <li>
                            <NavLink to={'/gsf/consumer'}>
                                <i className="fa fa-smile-beam"></i>
                                <span>Consumers</span>
                            </NavLink>
                        </li> : null}
                        <li>
                            <NavLink to={'/gsf/area'}>
                                <i className="fa fa-smile-beam"></i>
                                <span>Area</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/gsf/report/payments'}>
                                <i className="fa fa-smile-beam"></i>
                                <span>Payments Report</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/gsf/add/terms&conditions'}>
                                <i className="fa fa-smile-beam"></i>
                                <span>Terms &amp; Conditions</span>
                            </NavLink>
                        </li>
                        <button type="button" className="btn btn-primary" onClick={this.handleLogout}>Logout</button>
                    </ul>
                    <div className="row justify-content-center">
                        <div className="align-content:center" style={{ padding: 5 }}>
                            Version {VERSION}
                        </div>
                    </div>
                </div>
            </nav>

        );
    }
}

export default withRouter(GSFSidebar);