import * as React from 'react';
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import Pager from '../common/Pager';
interface IProps extends RouteComponentProps<any> {
    org_id: number
}

interface IState {
    usages: models.Paging<models.OrgUsage>
    filteredUsages: models.OrgUsage[]
    loading: boolean
    error: string | null
    from: number
    to: number
    limit: number
    offset: number
}

class OrgUsage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            usages: { count: 0, items: [] as models.OrgUsage[] },
            filteredUsages: [] as models.OrgUsage[],
            loading: true,
            error: null,
            from: 0,
            to: 0,
            limit: 25,
            offset: 0,
        };
    }

    orgUsage = () => {
        this.setState({
            limit: 25,
            offset: 0,
            loading: true,
            from: new Date().getTime(),
        })
    }

    componentDidMount = () => {

        const { org_id } = this.props;
        const { from, limit, offset, to } = this.state;

        Server.get().getOrgUsage({ org_id, from, limit, offset, to })
            .then((usages) => {
                this.setState({
                    usages: usages,
                    filteredUsages: usages.items,
                    loading: false
                })
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })
    }

    onPage = (limit: number, offset: number) => {
        this.setState({
            limit,
            offset
        }, () => this.componentDidMount())
    }

    onSearch = (input: string) => {
        const { usages } = this.state
        const filteredData = usages.items.filter(data => data.user_name.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        this.setState({
            filteredUsages: filteredData,
            loading: false
        })
    }

    public render() {
        const { error, filteredUsages } = this.state;
        return (
            <div className='list'>
                <h2>Usage</h2>
                <div>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <div>Total items: {this.state.usages.count}</div>
                <Loader loading={this.state.loading}>
                    <Pager count={this.state.usages.count} limit={25} page={0} onChange={(limit, offset) => this.onPage(limit, offset)} />
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Fitness Center name</th>
                                <th scope="col">Start at</th>
                                <th scope="col">Units</th>
                                <th scope="col">User name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsages && filteredUsages.map((c, i) =>
                                <tr key={c.id}>
                                    <th scope="row">{c.fc_name}</th>
                                    <td>
                                        <Moment format="DD/MM/YYYY HH:mm">
                                            {c.start_at}
                                        </Moment>
                                    </td>
                                    <td>{c.units}</td>
                                    <td>{c.user_name}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(OrgUsage);
