export const INDEX = '/'
export const ENTER_OTP = '/enter-otp'
export const START_PAGE = '/start-page'
// Fc
export const FC_HOME = '/fc/:fc_id/'
export const FC_BILING = '/fc/billing'
export const FC_CHECKINS = '/fc/checkins'
export const FC_CONTACTS = '/fc/contacts'
export const FC_CREATE_BILLING_METHOD = '/fc/create_billing_method'
export const FC_CREATE_PACKAGE = '/fc/create-package'
export const FC_CREATE_USER = '/fc/create-user'
export const FC_DASHBOARD = '/fc/dashboard'
export const FC_DETAILS = '/fc/details'
export const FC_EQUIPMENT = '/fc/equipment'
export const FC_FACILITIES = '/fc/facilities'
export const FC_IMAGES = '/fc/images'
export const FC_PACKAGES = '/fc/packages'
export const FC_PACKAGE_SALES = '/fc/package-sales'
export const FC_PAYMENT_DETAILS = '/fc/payment-details'
export const FC_PAYMENTS = '/fc/payments'
export const FC_UPDATE_PAYMENTS = '/fc/update-payments'
export const FC_SLOTS = '/fc/slots'
export const FC_USER = '/fc_user'
export const FC_USER_PROFILE = '/fc/user-profile'
export const FC_UPLOAD_IMAGES = '/fc/upload-images'
export const FC_UPLOAD_LOGO = '/fc/upload-logo'
export const FC_UPDATE_CONTACTS = '/fc/update-contacts'
export const FC_UPDATE_PACKAGES = '/fc/update-packages'
export const FC_UPDATE_SLOTS = '/fc/update-slots'
export const FC_UPDATE_USER = '/fc/update-user'
export const FC_FORGOT_PASSWORD = '/fc/forgot-password'
export const FC_UPDATE_BILLING = '/fc/update-billing'
export const FC_CREATE_PRICESETTING = '/fc/create-pricesetting'
export const FC_PRICESETTINGS = '/fc/pricesettings'
export const FC_UPDATE_PRICESETTINGS = '/fc/update-pricesettings'
export const FC_Holidays = '/fc/holidays'


// Org
export const ORG_HOME = '/org/:org_id/'
export const ORG_DASHBOARD = "/org/dashboard"
export const ORG_DOMAINS = "/org/domains"
export const ORG_EMPLOYEES = "/org/employees"
export const ORG_PACKAGES = "/org/packages"
export const ORG_USAGE = "/org/usage"
export const ORG_USERS = "/org/users"
export const ORG_CREATE_EMPLOYEE = '/org/create-employee'
export const ORG_CREATE_USER = '/org/create-user'
export const ORG_UPDATE_USER = '/org/update-user'

// Gsf
export const GSF_HOME = '/gsf/:user_id/'
export const GSF_DASHBOARD = "/gsf/dashboard"
export const GSF_FITNESSCENTER = "/gsf/fitnesscenter"
export const GSF_ORGANIZATION = "/gsf/organization"
export const GSF_USERS = "/gsf/users"
export const GSF_UPDATE_USER = "/gsf/update-user"
export const GSF_CREATE_USER = "/gsf/create-user"
export const GSF_CREATE_ORGANIZATION = "/gsf/create/organization"

// login page according to the user type
export const FC_LOGIN = '/fc/login'
export const ORG_LOGIN = '/org/login'
export const GSF_LOGIN = '/gsf/login'
// reset password page according to the user type
export const ORG_FORGOT_PASSWORD = '/org/forgot-password'
export const GSF_FORGOT_PASSWORD = '/gsf/forgot-password'
// enter otp page according to the user type
export const FC_ENTER_OTP = '/fc/enter-otp'
export const ORG_ENTER_OTP = '/org/enter-otp'
export const GSF_ENTER_OTP = '/gsf/enter-otp'
// enter otp page according to the user type
export const FC_NEW_PASSWORD = '/fc/new-password'
export const ORG_NEW_PASSWORD = '/org/new-password'
export const GSF_NEW_PASSWORD = '/gsf/new-password'







