import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError, isValidEmail } from '../../common/HelperMethods';
import * as Yup from 'yup';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import { FormikActions, Formik, Form, Field } from 'formik';
import Error from '../common/Error';




interface IProps extends RouteComponentProps<any> {
    org_id: number
}

interface IState {
    loading: boolean
    data: models.OrgUpdateUser
}
class OrgUpdateUser extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const user = this.props.history.location.state.user as models.OrgUser
        this.state = {
            loading: false,
            data: {
                email: user.email || '',
                id: user.id,
                name: user.name || '',
                org_id: this.props.org_id,
                password: '',
                phone: user.phone || '',
                role: user.role || 'Admin'
            }
        };
    }
    schema = () => {
        return Yup.object().shape({
            name: Yup.string().label('Name').required(),
            email: Yup.string().email().label('Email').when('phone', {
                is: phone => phone,
                then: Yup.string().email(),
                otherwise: Yup.string().email().required()
            }),
            phone: Yup.string().label('Phone').when('email', {
                is: email => email,
                then: Yup.string().matches(/^\d{10}$/, 'Not a valid phone number'),
                otherwise: Yup.string().matches(/^\d{10}$/, 'Not a valid phone number').required()
            }),
            password: Yup.string().label('Password').required(),
            role: Yup.string().label('Role').required()
        }, [['email', 'phone']])
    }
    onSubmit = (values: models.OrgUpdateUser, fa: FormikActions<any>) => {
        Server.get().UpdateOrgUser(values)
            .then((r) => {
                fa.setSubmitting(false)
                this.props.history.goBack();
            })
            .catch((e: any) => {
                fa.setErrors(e)
                fa.setSubmitting(false)
            })
    }
    render() {
        const { data } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update User</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className="container" >
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <Formik initialValues={data} onSubmit={this.onSubmit} validationSchema={this.schema} validateOnBlur={true}
                                        render={({ isSubmitting, error }) =>
                                            <Form>
                                                {
                                                    error
                                                        ? <span style={{ color: 'red' }}>{error}</span>
                                                        : null
                                                }
                                                <div className='form-group'>
                                                    <label>Name</label>
                                                    <Field type='text' name="name" placeholder='Name' className="form-control" />
                                                    <Error name="name" />
                                                </div>

                                                <div className='form-group'>
                                                    <label>Email</label>
                                                    <Field type='text' name="email" placeholder='Enter email' className="form-control" />
                                                    <Error name="email" />
                                                </div>

                                                <div className='form-group'>
                                                    <label>Phone</label>
                                                    <Field type='text' name="phone" placeholder='Enter phone number' className="form-control" />
                                                    <Error name="phone" />
                                                </div>

                                                <div className='form-group'>
                                                    <label>Password</label>
                                                    <Field type='text' name="password" placeholder='Enter password' className="form-control" />
                                                    <Error name="password" />
                                                </div>

                                                <div>
                                                    <label>Role</label>
                                                    <Field component="select" className="form-control" name="role">
                                                        <option value='Admin'>Admin</option>
                                                        <option value='Support'>Support</option>
                                                    </Field>
                                                    <Error name="role" />
                                                </div>
                                                <div className="form-group" >
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                                </div>
                                            </Form>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(OrgUpdateUser);
