import * as React from 'react';
import DatePicker from "react-datepicker";
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError, getFormattedDate } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import moment from 'moment';
import Loader from '../common/Loader';



interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    loading: boolean
    error: string | null
    start_date: Date
    end_date: Date
    checkins_count: number
    data: models.Paging<models.FCCheckin>
    amount_total: number
    id: number
    limit: number
    offset: number
    user_id: number
    user_name: string
    checkin_id: number
    checkin_time: number
    _items: models.FCBillItemWithSelect[]
    selectedItems: models.FCBillItemWithSelect[]
}

class FCUpdateBills extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const fcbills = this.props.history.location.state.bills as models.FCBill
        const checkin_time = moment(fcbills.start_date, 'MM-DD-YYYY').toDate()
        console.log('date is', checkin_time)

        this.state = {
            loading: false,
            error: null,
            end_date: moment(fcbills.end_date, 'DD-MM-YYYY').toDate(),
            start_date: moment(fcbills.start_date, 'DD-MM-YYYY').toDate(),
            checkins_count: fcbills.checkins_count,
            amount_total: fcbills.amount_total,
            id: fcbills.id,
            data: { count: 0, items: [] as models.FCCheckin[] },
            limit: 0,
            offset: 0,
            user_id: 0,
            user_name: '',
            checkin_id: 0,
            checkin_time: 0,
            _items: [] as models.FCBillItemWithSelect[],
            selectedItems: [] as models.FCBillItemWithSelect[]
        };
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onCheckinSelect = this.onCheckinSelect.bind(this);
    }


    componentDidMount = () => {
        const { fc_id } = this.props;
        const { start_date, end_date, limit, offset } = this.state;
        Server.get().getFCCheckinsForBills({ fc_id, from: start_date.getTime(), limit, offset, to: end_date.getTime() })
            .then((data) => {
                const billItems = data.items.map((c) => {
                    return {
                        selected: true,
                        user_id: c.user_id,
                        user_name: c.user_name,
                        checkin_id: c.id,
                        checkin_time: new Date(c.start_at).getTime(),
                        amount: c.amount
                    }
                })
                this.setState({
                    loading: false,
                    _items: billItems
                })
            })
            .catch((e: any) => {
                this, this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })
    }

    onCheckinSelect = (checkin_id: number, checked: boolean) => {
        const { _items: items } = this.state;

        const bills = items.filter((c, i) => c.checkin_id == checkin_id)
        bills.forEach((m) =>
            m.selected = checked
        )
        this.setState({
            _items: items
        }, () => this.updateCount())
    }

    updateCount = () => {
        const { _items: items } = this.state;

        let selectedItems = items.filter((c) => c.selected)
        this.setState({
            checkins_count: selectedItems.length,
            amount_total: selectedItems.reduce((sum, i) => sum + i.amount, 0)
        })
    }
    onStartDateChange = (date: Date) => {
        this.setState({
            start_date: date
        }, () => {
            this.componentDidMount();
        })
    }

    onEndDateChange = (date: Date) => {
        this.setState({
            end_date: date
        }, () => {
            this.componentDidMount();
        })
    }

    onSubmit = (e: any) => {

        e.preventDefault()
        const { fc_id } = this.props;
        let { amount_total, checkins_count, end_date, id, start_date, _items } = this.state;
        const items = _items.filter((m) => m.selected).map((c) => {
            return {
                amount: c.amount,
                checkin_id: c.checkin_id
            }
        })

        Server.get().updateFCBills({ fc_id, checkins_count, amount_total, id, end_date: getFormattedDate(end_date).toString(), start_date: getFormattedDate(start_date).toString(), items })
            .then((r) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    render() {
        const { start_date, end_date, checkins_count, error, amount_total, _items: items } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Update Bills</h1>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        <h2>Update bills</h2>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }

                                        <div className="form-group">
                                            <span>From :</span>
                                            <DatePicker className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                selected={start_date}
                                                onChange={this.onStartDateChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <span>To :</span>
                                            <DatePicker className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                selected={end_date}
                                                onChange={this.onEndDateChange}
                                            />
                                        </div>
                                        <div>Checkins count:{checkins_count}</div>
                                        <div>Amount total:{amount_total}</div>

                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                                        </div>

                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Checkin</th>
                                                    <th scope="col">From date</th>
                                                    <th scope="col">Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    items.map((c) =>
                                                        <tr key={c.checkin_id}>
                                                            <td scope="row">
                                                                <input
                                                                    type="checkbox"
                                                                    name="active"
                                                                    onChange={(e) => this.onCheckinSelect(c.checkin_id, e.target.checked)}
                                                                    checked={c.selected}
                                                                />
                                                            </td>
                                                            <th><Moment format="DD/MM/YYYY HH:mm">{c.checkin_time}</Moment></th>
                                                            <td>{c.user_name}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}
export default withRouter(FCUpdateBills);