import * as React from 'react';
import Avatar from 'react-avatar';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError, getIconUrl } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    isLoading: boolean
    error: string | null
    fullEquipments: models.GSFEquipment[]
    filteredEquipments: models.GSFEquipment[]
    equipments: string[]
}
class FCEquipment extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            fullEquipments: [],
            filteredEquipments: [],
            equipments: []
        };
    }

    handleOnChange = (equipments: string, checked: boolean) => {
        const equipment = this.state.equipments
        if (checked) {
            equipment.push(equipments)
        } else {
            const index = equipment.indexOf(equipments)
            equipment.splice(index, 1)
        }
        this.setState({
            equipments: equipment
        })
    }

    getAllEquipments = () => {

        Server.get().getAllEquipments({})
            .then((equipment) => {
                this.setState({
                    fullEquipments: equipment,
                    filteredEquipments: equipment
                })
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }

    componentDidMount = () => {
        const { fc_id } = this.props;
        this.getAllEquipments();
        Server.get().getFCEquipment({ fc_id })
            .then((equipments) => {
                this.setState({
                    equipments: equipments,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onSubmit = (event: any) => {

        event.preventDefault();

        const { fc_id } = this.props;
        const { equipments: equipment } = this.state;

        Server.get().updateFCEquipment({ equipment, fc_id })
            .then((equipment: string[]) => {
                alert('Equipments added successfully')
                this.setState({
                    equipments: equipment,
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    onSearch = (str: string) => {
        const { fullEquipments } = this.state;
        const filteredEquipments = fullEquipments.filter(equipment => {
            const lowerStr = str.toLowerCase()
            return equipment.name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filteredEquipments: filteredEquipments,
            isLoading: false
        })
    }
    public render() {
        const { equipments, filteredEquipments, error, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Equipments</h2>
                        </div>
                    </ol>
                </nav>
                <div className='form-group'>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Icon</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredEquipments.map((c, i) =>
                                    <tr key={c.id}>
                                        <td scope="row">
                                            <input type="checkbox" name="active" onChange={(e) => this.handleOnChange(c.name, e.target.checked)} checked={equipments.indexOf(c.name) >= 0} />

                                            {c.icon ? <Avatar
                                                src={getIconUrl(c.icon, '64x64')}
                                                size="64px"
                                            /> : null
                                            }
                                        </td>
                                        <td scope="row">
                                            {c.name}
                                        </td>
                                        <td scope="row">
                                            {c.description}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="form-group">
                        <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCEquipment);
