import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';


export interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    loading: boolean
    isDisableSubmit: boolean
    name: string
    qr_code: string
    branch: string
    phone: string
    email: string
    address_line1: string
    address_line2: string
    land_mark: string
    zip_code: string
    latitude: string
    longitude: string
    website: string
    city: string
    area: string
    error: string | null
    color: "error" | "success" | null
    status: number
    areas: models.Area[]
    workouts: string[]
    allWorkouts: models.Workout[]
    price: string
    commitment: string
    business_name: string
}
class GSFCreateFitnessCenter extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            isDisableSubmit: false,
            name: '',
            qr_code: '',
            branch: '',
            phone: '',
            email: '',
            address_line1: '',
            address_line2: '',
            land_mark: '',
            zip_code: '',
            latitude: '',
            longitude: '',
            website: '',
            city: 'Hyderabad',
            error: null,
            color: null,
            status: 0,
            area: '',
            areas: [],
            allWorkouts: [],
            workouts: [],
            price: '',
            commitment: '',
            business_name: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount = () => {
        Server.get().getAllAreas(1).then(areas => {
            this.setState({ areas })
        })
        Server.get().getAllWorkouts().then(allWorkouts => this.setState({ allWorkouts }))
    }

    getValidationErr = (name: string, area: string, city: string) => {
        let err = ''
        if (!name) {
            return 'Enter Fitness Center name'
        }
        if (!city) {
            return "Enter City"
        }
        if (!area) {
            return "Enter area"
        }

        return err
    }
    onSubmit = (event: any) => {
        event.preventDefault()
        const { name, area, branch, phone, email,
            address_line1, address_line2, zip_code,
            isDisableSubmit, longitude, latitude,
            city, land_mark, qr_code, website, status,
            workouts, price, commitment, business_name

        } = this.state
        const err = this.getValidationErr(name, area, city)
        if (err) {
            this.setState({ error: err, isDisableSubmit: false })
            return
        }

        Server.get().createFitnessCenter({
            address_line1, address_line2, phone,
            email, name, city, status,
            longitude: parseFloat(longitude),
            latitude: parseFloat(latitude),
            branch, land_mark, qr_code, website, zip_code, area,
            workouts, price_label: parseInt(price), commitment,
            business_name
        })
            .then((r) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: 'error',
                    isDisableSubmit: false
                })
            })
    }
    handleChange = (e: any) => {
        this.setState({
            status: e.target.value
        });
    }
    handleWorkoutChange = (name: string, checked: boolean) => {
        console.log(name, checked)
        const { workouts } = this.state
        if (checked) {
            workouts.push(name)
        } else {
            workouts.splice(workouts.indexOf(name), 1)
        }
        this.setState({ workouts });
    }

    public render() {
        const { name, branch, phone, email, error, area, qr_code, address_line1,
            address_line2, zip_code, isDisableSubmit,
            city, website, land_mark, latitude, longitude,
            workouts, allWorkouts, areas, price, commitment, business_name } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Create Fitness Center</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }

                                        <div className='form-group'>
                                            <input type='text' className="form-control" name="name" placeholder='Name' value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="email" placeholder='Email' value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="phone" placeholder='Phone' value={phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="code" placeholder='Qr code' value={qr_code} onChange={(e) => this.setState({ qr_code: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="branch" placeholder='Branch' value={branch} onChange={(e) => this.setState({ branch: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="landmark" placeholder='LandMark' value={land_mark} onChange={(e) => this.setState({ land_mark: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="branch" placeholder='Latitude' value={latitude} onChange={(e) => this.setState({ latitude: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="branch" placeholder='Longitude' value={longitude} onChange={(e) => this.setState({ longitude: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="address" placeholder='Address line 1' value={address_line1} onChange={(e) => this.setState({ address_line1: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="address" placeholder='Address line 2' value={address_line2} onChange={(e) => this.setState({ address_line2: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <textarea className="form-control" placeholder='Commitments' rows={3} autoCapitalize="off" value={commitment} onChange={(e) => this.setState({ commitment: e.target.value })} ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="code" placeholder='Pincode' value={zip_code} onChange={(e) => this.setState({ zip_code: e.target.value })} />
                                        </div>

                                        <div className="form-group">
                                            <label>Area</label>
                                            <select onChange={(e) => this.setState({ area: e.target.value })}
                                                value={area}
                                                className="form-control"
                                            >
                                                {areas.map((m, i) =>
                                                    <option value={m.name} key={i}>{m.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input type='text' className="form-control" name="city" placeholder='City' value={city} onChange={(e) => this.setState({ city: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' name="website" className="form-control" placeholder='Business name' value={business_name} onChange={(e) => this.setState({ business_name: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <input type='text' name="website" className="form-control" placeholder='Website' value={website} onChange={(e) => this.setState({ website: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Workouts</label>
                                            {allWorkouts.map((w, i) =>
                                                <div key={i}>
                                                    <input type="checkbox" name="active" onChange={(e) => this.handleWorkoutChange(w.name.toLowerCase(), e.target.checked)} checked={workouts.indexOf(w.name.toLowerCase()) >= 0} />
                                                    <span>{w.name}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default GSFCreateFitnessCenter;
