import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';


interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    error: string | null
    title: string
    message: string
    user_id: number[]
    type: string
}

class GSFMessage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const user_id = this.props.history.location.state.user_id as any[]
        this.state = {
            error: null,
            title: '',
            message: '',
            user_id: user_id,
            type: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    onSubmit = () => {
        const { message, title, type } = this.state;
        const user_ids = this.props.history.location.state.user_id;
        const { user_id } = this.props;
        console.log('user id', user_ids);
        Server.get().sendMessage({ message, user_ids, title, type })
            .then(() => {
                alert('Message sent successfull');
                this.props.history.goBack();
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }

    handleChange = (e: any) => {
        this.setState({
            type: e.target.value
        })
    }

    render() {
        const { error, message, title, type } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Send message</h2>
                        </div>
                    </ol>
                </nav>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="base-form">
                                <form>
                                    {
                                        error
                                            ? <span style={{ color: 'red' }}>{error}</span>
                                            : null
                                    }
                                    <div className="form-group">
                                        <select onChange={this.handleChange} value={type} className="form-control">
                                            <label>Type:</label>
                                            <option value='New product'>New product</option>
                                            <option value='Offer'>Offer</option>
                                            <option value='Notification'>Notification</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Title</label>
                                        <input type='name' className="form-control" name="name" placeholder='Enter message title ' autoCapitalize="off" value={title} onChange={(e) => this.setState({ title: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea className="form-control" placeholder='Write message here...' rows={3} autoCapitalize="off" value={message} onChange={(e) => this.setState({ message: e.target.value })} ></textarea>
                                    </div>
                                    <div style={{ alignItems: 'center' }}>
                                        <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default withRouter(GSFMessage);