import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import OrgCreateDomain from '../components/org/OrgCreateDomain';
import OrgCreateEmployee from '../components/org/OrgCreateEmployee';
import OrgCreatePackage from '../components/org/OrgCreatePackage';
import OrgCreateUser from '../components/org/OrgCreateUser';
import OrgDashBoard from '../components/org/OrgDashboard';
import OrgDetails from '../components/org/OrgDetails';
import OrgDomains from '../components/org/OrgDomains';
import OrgEmployees from '../components/org/OrgEmployees';
import OrgPackages from '../components/org/OrgPackages';
import OrgSidebar from '../components/org/OrgSidebar';
import OrgUpdateDetails from '../components/org/OrgUpdateDetails';
import OrgUpdateEmployee from '../components/org/OrgUpdateEmployee';
import OrgUpdatePackage from '../components/org/OrgUpdatePackage';
import OrgUpdateUser from '../components/org/OrgUpdateUser';
import OrgUsage from '../components/org/OrgUsage';
import OrgUsers from '../components/org/OrgUsers';
import { Server } from '../common/Server';


interface IRouteProps {
    org_id: string
}

interface IProps extends RouteComponentProps<IRouteProps> {
    org_id: number
}

interface IState {

}

class OrgHome extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {

        };
    }

    componentDidMount = () => {
        Server.get().userToken().then(token => {
            console.log('fc home token', token)
            if (!token) {
                this.props.history.replace('')
            }
        })
    }

    render() {
        const org_id: number = parseInt(this.props.match.params.org_id);
        return (
            <div className='wrapper'>
                <OrgSidebar org_id={org_id}></OrgSidebar>
                <div className='content'>
                    <Switch>
                        <Route exact={true} path={'/org/' + org_id + '/dashboard'} render={(props) => <OrgDashBoard {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/domains'} render={(props) => <OrgDomains {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/employees'} render={(props) => <OrgEmployees {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/packages'} render={(props) => <OrgPackages {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/usage'} render={(props) => <OrgUsage {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/users'} render={(props) => <OrgUsers {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/details'} render={(props) => <OrgDetails {...props} org_id={org_id} />} />

                        <Route exact={true} path={'/org/' + org_id + '/employee/create'} render={(props) => <OrgCreateEmployee {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/user/create'} render={(props) => <OrgCreateUser {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/user/update'} render={(props) => <OrgUpdateUser {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/package/update'} render={(props) => <OrgUpdatePackage {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/package/create'} render={(props) => <OrgCreatePackage {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/domain/create'} render={(props) => <OrgCreateDomain {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/details/update'} render={(props) => <OrgUpdateDetails {...props} org_id={org_id} />} />
                        <Route exact={true} path={'/org/' + org_id + '/employee/update'} render={(props) => <OrgUpdateEmployee {...props} org_id={org_id} />} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(OrgHome);