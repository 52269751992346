import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isLoading: boolean
    error: string | null
    plans: models.GSFPlan[]

}
class GSFPlans extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            plans: [] as models.GSFPlan[],
        };
    }
    onDelete = (id: number) => {

        if (confirm('Are you sure you want to delete this equipment?')) {

            Server.get().deleteGSFPlan({ id })
                .then(() => {
                    let plans = this.state.plans
                    plans = plans.filter((p) => p.id !== id)
                    this.setState({
                        plans,
                        isLoading: false
                    })

                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        isLoading: false
                    })
                })
        }
    }
    componentDidMount = () => {
        Server.get().getGSfPlans({})
            .then((plans) => {
                this.setState({
                    plans,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }


    public render() {
        const { plans, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Plans</h2>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/create/plan')}><i className="fas fa-create-alt"></i>Create New Plan</button>

                <Loader loading={isLoading}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">No Of Workouts</th>
                                <th scope="col">Max Units</th>
                                <th scope="col">Min Units</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Total value</th>
                                <th scope="col">Workout price</th>
                                <th scope="col">Sessions Limit</th>
                                <th scope="col">limit_type</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {this.state.plans.map((c, i) =>
                                <tr key={c.id}>
                                    <td scope="row">
                                        {c.name}
                                    </td>
                                    <td scope="row">
                                        {c.units}
                                    </td>
                                    <td scope="row">
                                        {c.max_unit}
                                    </td>
                                    <td scope="row">
                                        {c.min_unit}
                                    </td>
                                    <td scope="row">
                                        {`${c.duration} days`}
                                    </td>
                                    <td scope="row">
                                        {c.total_value}
                                    </td>
                                    <td scope="row">
                                        {c.units_price}
                                    </td>
                                    <td scope="row">
                                        {c.sessions_limit}
                                    </td><td scope="row">
                                        {c.limit_type}
                                    </td>
                                    <td><button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/plan/update', { plan: c })}><i className="fas fa-edit" >Edit</i></button>
                                        <button type="button" className="btn btn-link" onClick={(e) => this.onDelete(c.id)}><i className="fas fa-trash-alt" >Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </Loader>


            </div>
        );
    }
}

export default withRouter(GSFPlans);
