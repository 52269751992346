import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

const str = (v: any) => v ? v.toString() : ''
const toInt = (v: string, d: number = 0) => v ? parseInt(v) : d

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    error: string | null
    loading: boolean
    isDisableSubmit: boolean
    id: number
    name: string
    qr_code: string
    branch: string
    area: string
    phone: string
    email: string
    address_line1: string
    address_line2: string
    land_mark: string
    zip_code: string
    city: string
    latitude: string
    longitude: string
    website: string
    status: number
    gender: string
    capacity: string
    floor_trainers: string
    personal_trainers: string
    area_size: string,
    workouts: string[]
    areas: models.Area[]
    allWorkouts: models.Workout[]
    price_label: string
    isGsfUser: boolean
    commitment: string
    business_name: string
    payment_cycle: string
    user_id: string
}
class FCUpdateDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const fcDetails = this.props.history.location.state.fcDetails as models.FCDetails

        this.state = {
            isDisableSubmit: false,
            error: null,
            loading: false,
            id: fcDetails.id,
            name: fcDetails.name,
            qr_code: fcDetails.qr_code,
            branch: fcDetails.branch,
            area: fcDetails.area,
            phone: fcDetails.phone,
            email: fcDetails.email,
            address_line1: fcDetails.address_line1,
            address_line2: fcDetails.address_line2,
            land_mark: fcDetails.land_mark,
            zip_code: fcDetails.zip_code,
            city: fcDetails.city,
            latitude: str(fcDetails.latitude),
            longitude: str(fcDetails.longitude),
            website: fcDetails.website,
            status: fcDetails.status,
            gender: fcDetails.gender,
            capacity: str(fcDetails.capacity),
            floor_trainers: str(fcDetails.floor_trainers),
            personal_trainers: str(fcDetails.personal_trainers),
            area_size: str(fcDetails.area_size),
            workouts: fcDetails.workouts || ['gym'],
            allWorkouts: [],
            areas: [],
            price_label: str(fcDetails.price_level),
            isGsfUser: false,
            commitment: fcDetails.commitment,
            business_name: fcDetails.business_name,
            payment_cycle: fcDetails.payment_cycle || '',
            user_id: ''
        };
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount = () => {
        Server.get().getAllAreas(1).then(areas => {
            this.setState({ areas })
        })
        Server.get().getAllWorkouts().then(allWorkouts => this.setState({ allWorkouts }))

        Server.get().userId().then(id => {
            this.setState({ user_id: id! })
        })

        Server.get().userType()
            .then((user_type) => {
                this.setState({
                    isGsfUser: user_type === "gsf_user"
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    handleChange = (event: any) => {
        event.preventDefault()
        this.setState({ status: parseInt(event.target.value) });
    }
    handleGenderChange = (event: any) => {
        event.preventDefault()
        this.setState({ gender: event.target.value });
    }
    handleWorkoutChange = (name: string, checked: boolean) => {
        console.log(name, checked)
        const { workouts } = this.state
        if (checked) {
            workouts.push(name)
        } else {
            workouts.splice(workouts.indexOf(name), 1)
        }
        this.setState({ workouts });
    }
    getValidationErr = (name: string, city: string) => {
        let err = ''
        if (!name) {
            return 'Enter Fitness Center name'
        }
        if (!city) {
            return "Enter City"
        }

        return err
    }
    onSubmit = (event: any) => {
        event.preventDefault()
        const { fc_id } = this.props;
        const { address_line1, website, longitude, latitude, city, zip_code,
            land_mark, address_line2, email, phone, branch,
            id, name, qr_code, status, gender,
            capacity, floor_trainers, personal_trainers, area_size, workouts, area, commitment,
            price_label, business_name, payment_cycle
        } = this.state;
        const err = this.getValidationErr(name, city)
        if (err) {
            this.setState({
                error: err
            })
        }

        if (status == 1) {

        }
        Server.get().updateFCDetails({
            fc_id, gender, address_line1, address_line2,
            branch, city, email, land_mark,
            latitude: parseFloat(latitude), longitude: parseFloat(longitude),
            name, phone, qr_code, website, zip_code, status,
            capacity: toInt(capacity),
            floor_trainers: toInt(floor_trainers),
            personal_trainers: toInt(personal_trainers),
            area_size: toInt(area_size),
            workouts, area, commitment,
            price_level: parseFloat(price_label),
            business_name,
            payment_cycle
        })
            .then((r) => {
                this.props.history.goBack();
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })

    }
    render() {
        const { error, name, email, phone, qr_code, branch, land_mark, latitude, longitude, address_line1,
            address_line2, zip_code, city, website, isDisableSubmit, status, gender,
            capacity, personal_trainers, floor_trainers, area_size, areas, area, price_label,
            workouts, allWorkouts, isGsfUser, commitment, business_name, payment_cycle, user_id } = this.state
        return (
            <div>
                <div className='form' >
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <div className="align-content:center">
                                <h2>Update details</h2>
                            </div>
                        </ol>
                    </nav>
                    <Loader loading={this.state.loading}>
                        <form>
                            {
                                error
                                    ? <span style={{ color: 'red' }}>{error}</span>
                                    : null
                            }
                            <div className='container'>
                                <div className='row '>
                                    <div className='col'>
                                        <div className='form-group'>
                                            <label>Name</label>
                                            <input type='text' className="form-control " name="name" placeholder='Name' value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </div>

                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type='text' className="form-control" name="email" placeholder='Email' value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type='text' className="form-control" name="phone" placeholder='Phone' value={phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>QR code</label>
                                            <input type='text' className="form-control" name="Qr code" placeholder='Qr code' value={qr_code} onChange={(e) => this.setState({ qr_code: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Branch</label>
                                            <input type='text' className="form-control" name="" placeholder='Branch' value={branch} onChange={(e) => this.setState({ branch: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Land Mark</label>
                                            <input type='text' className="form-control" name="Land Mark" placeholder='LandMark'
                                                value={land_mark} onChange={(e) => this.setState({ land_mark: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>For Gender</label>
                                            <select onChange={this.handleGenderChange} value={gender} className="form-control">
                                                <option value='Both'>Both Men/Women</option>
                                                <option value='Men'>Men Only</option>
                                                <option value='Women'>Women Only</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Capacity</label>
                                            <input type='text' className="form-control" name="capacity" placeholder='Capacity' value={capacity} onChange={(e) => this.setState({ capacity: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Floor Trainers</label>
                                            <input type='text' className="form-control" name="trainers" placeholder='Floor Trainers' value={floor_trainers} onChange={(e) => this.setState({ floor_trainers: e.target.value })} />
                                        </div>
                                        {
                                            isGsfUser ?
                                                <div className="form-group">
                                                    <label>Commitments</label>
                                                    <textarea className="form-control" placeholder='Commitments' rows={3} autoCapitalize="off" value={commitment} onChange={(e) => this.setState({ commitment: e.target.value })} ></textarea>
                                                </div>
                                                : null
                                        }
                                        <div className="form-group">
                                            <label>Payment Cycle</label>
                                            <input type='text' className="form-control" name="payment_cycle" placeholder='Payment Cycle' value={payment_cycle} onChange={(e) => this.setState({ payment_cycle: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Workouts</label>
                                            {allWorkouts.map((w, i) =>
                                                <div key={i}>
                                                    <input type="checkbox" name="active" onChange={(e) => this.handleWorkoutChange(w.name.toLowerCase(), e.target.checked)} checked={workouts.indexOf(w.name.toLowerCase()) >= 0} />
                                                    <span>{w.name}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="form-group">
                                            <label>Latitude</label>
                                            <input type='text' className="form-control" name="Latitude" placeholder='Latitude' value={latitude} onChange={(e) => this.setState({ latitude: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Longitude</label>
                                            <input type='text' className="form-control" name="Longitude" placeholder='Longitude' value={longitude} onChange={(e) => this.setState({ longitude: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Address Line1</label>
                                            <input type='text' className="form-control" name="Address Line1" placeholder='Address line 1' value={address_line1} onChange={(e) => this.setState({ address_line1: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Address Line2</label>
                                            <input type='text' className="form-control" name="Address Line2" placeholder='Address line 2' value={address_line2} onChange={(e) => this.setState({ address_line2: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Area</label>
                                            <select onChange={(e) => this.setState({ area: e.target.value })}
                                                value={area}
                                                className="form-control"
                                            >
                                                {areas.sort().map((m, i) =>
                                                    <option value={m.name} key={i}>{m.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Zipcode</label>
                                            <input type='text' className="form-control" name="Zipcode" placeholder='Pincode' value={zip_code} onChange={(e) => this.setState({ zip_code: e.target.value })} />
                                        </div>

                                        <div className="form-group">
                                            <label>City</label>
                                            <input type='text' className="form-control" name="city" placeholder='City' value={city} onChange={(e) => this.setState({ city: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label text-form-group="left">Website</label>
                                            <input type='text' className="form-control" name="Website" placeholder='Website' value={website} onChange={(e) => this.setState({ website: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Personal Trainers</label>
                                            <input type='text' className="form-control" name="Land Mark" placeholder='Personal trainers' value={personal_trainers} onChange={(e) => this.setState({ personal_trainers: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Area Size(sqft)</label>
                                            <input type='text' className="form-control" name="Land Mark" placeholder='Area Size(sqft)' value={area_size} onChange={(e) => this.setState({ area_size: e.target.value })} />
                                        </div>

                                        <div className="form-group">
                                            <label>Price</label>
                                            <input type='text' className="form-control" name="Land Mark" placeholder='Price' value={price_label} onChange={(e) => this.setState({ price_label: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Business name</label>
                                            <input type='text' className="form-control" name="Land Mark" placeholder='Business name' value={business_name} onChange={(e) => this.setState({ business_name: e.target.value })} />
                                        </div>
                                        {user_id === '3' ? < div className="form-group">
                                            <span>Status:</span>
                                            <select onChange={this.handleChange} value={status} className="form-control">
                                                <option value={0}>In-progress</option>
                                                <option value={1}>active</option>
                                                <option value={2}>In-active</option>
                                            </select>
                                        </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="text-center">
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                        </div>
                    </Loader>
                </div>
            </div >

        );
    }
}

export default withRouter(FCUpdateDetails);
