import { models } from "../models";
import { ErrorWithData } from "./ErrorWithData";
import { async } from 'q';


let user: models.User | undefined = undefined
export class Server {


    // BASE_URL = 'http://192.168.0.100:5000/getset-fit-dev/us-central1/api/cms/'
    BASE_URL = 'https://us-central1-getset-fit-dev.cloudfunctions.net/api/cms/'
    static _instance: Server;
    static get() {
        if (!Server._instance) {
            Server._instance = new Server();
        }
        return Server._instance;
    }
    post = async (path: string, data: any, isMultiPart?: boolean): Promise<any> => {

        let token = await sessionStorage.getItem('token') || ''
        // const response = await fetch(`${this.BASE_URL}${path}`,
        const options: any = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "x-access-token": token,
                // "Content-Type": "application/json; charset=utf-8",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer",

            // body: JSON.stringify(data), // body data type must match "Content-Type" header
            // no-referrer, *client
        }
        if (isMultiPart) {
            options["body"] = data
            options["mimeType"] = "multipart/form-data"
            options["processData"] = false
            options["contentType"] = false
        }
        else {
            options['body'] = JSON.stringify(data)
            options.headers["Content-Type"] = "application/json; charset=utf-8"
        }

        const response = await fetch(`${this.BASE_URL}${path}`, options)
        const resp = await response.json()
        if (response.status === 200) {
            return resp;
        } else {
            if (response.status === 401) {
                // await this.logout()
            }
            throw new ErrorWithData(resp, response.status)
        }
    }

    clearUser = async () => {
        user = undefined
        await sessionStorage.removeItem('token')
        await sessionStorage.removeItem('user_type')
    }

    logout = async () => {
        return this.clearUser()
    }

    userType = async () => {
        return await sessionStorage.getItem('user_type')
    }
    userId = async () => {
        return await sessionStorage.getItem('user_id')
    }

    userToken = async () => {
        return await sessionStorage.getItem('token')
    }

    login = async (req: models.LoginReq): Promise<models.UserWithToken> => {

        const res = await this.post('login', req)
        await sessionStorage.setItem('token', res.token)
        await sessionStorage.setItem('user_type', res.user_type)
        await sessionStorage.setItem('user_id', res.id)
        return await res as models.UserWithToken
    }
    forgotPassword = async (req: models.ForgotPasswordReq): Promise<models.ForgotPasswordResp> => {
        return await this.post('forgot_password', req) as models.ForgotPasswordResp
    }
    changePassword = async (req: models.ChangePasswordReq): Promise<models.UserWithToken> => {
        return await this.post('change_password', req) as models.UserWithToken
    }
    getFCBilling = async (req: models.FCReq): Promise<models.FCBilling> => {
        return await this.post('fc/billing', req) as models.FCBilling
    }
    // fc bills
    getFCBills = async (req: models.FCGetBills): Promise<models.Paging<models.FCBill>> => {
        return await this.post('fc/bills', req) as models.Paging<models.FCBill>
    }

    createFCBills = async (req: models.FCCreateBill): Promise<models.FCCreateBill> => {
        return await this.post('fc/bills/create', req) as models.FCCreateBill
    }
    // fc checkins
    getFCCheckins = async (req: models.FCCheckinsReq): Promise<models.Paging<models.FCCheckin>> => {
        return await this.post('fc/checkins', req) as models.Paging<models.FCCheckin>
    }
    createFCCheckins = async (req: models.FCCheckinsReq): Promise<models.Paging<models.FCCheckin>> => {
        return await this.post('fc/checkins/create', req) as models.Paging<models.FCCheckin>
    }
    deleteFCCheckins = async (req: models.FCDeleteCheckinReq): Promise<models.Paging<models.FCCheckin>> => {
        return await this.post('fc/checkins/delete', req) as models.Paging<models.FCCheckin>
    }
    updateFCCheckins = async (req: models.FCCheckinsReq): Promise<models.Paging<models.FCCheckin>> => {
        return await this.post('fc/checkins/update', req) as models.Paging<models.FCCheckin>
    }
    // checkins
    getFCCheckinsForBills = async (req: models.FCCheckinsReq): Promise<models.Paging<models.FCCheckinForBill>> => {
        return await this.post('fc/checkins/bills', req) as models.Paging<models.FCCheckinForBill>
    }
    getFCContacts = async (req: models.FCReq): Promise<models.FCContact[]> => {
        return await this.post('fc/contacts', req) as models.FCContact[]
    }
    createFCContacts = async (req: models.FCCreateContact): Promise<models.FCContact> => {
        return await this.post('fc/contacts/create', req) as models.FCContact
    }
    createFCBillingMethod = async (req: models.FCCreateBillingMethodReq): Promise<models.FCBillingMethod> => {
        return await this.post('fc/billing/methods/create', req) as models.FCBillingMethod
    }
    createFCPackage = async (req: models.FCCreatePackage): Promise<models.FCPackage> => {
        return await this.post('fc/packages/create', req) as models.FCPackage
    }
    createFCPayment = async (req: models.FCCreatePayment): Promise<models.FCPayment> => {
        return await this.post('fc/payments/create', req) as models.FCPayment
    }
    createFCUser = async (req: models.FCCreateUser): Promise<models.FCUser> => {
        return await this.post('fc/users/create', req) as models.FCUser
    }
    deleteFCBillingMethod = async (req: models.FCDeleteBillingMethod): Promise<boolean> => {
        return await this.post('fc/billing/methods/delete', req)
    }
    deleteFCPackageSales = async (req: models.FCDeletePackageSales): Promise<boolean> => {
        return await this.post('fc/packagesales/methods/delete', req)
    }
    deleteFCBills = async (req: models.FCDeleteBills): Promise<boolean> => {
        return await this.post('fc/bills/delete', req)
    }
    deleteFCImage = async (req: models.FCDeleteImage): Promise<boolean> => {
        return await this.post('fc/images/delete', req)
    }
    deleteFCPackage = async (req: models.FCDeletePackage): Promise<boolean> => {
        return await this.post('fc/packages/delete', req)
    }
    deleteFCPayment = async (req: models.FCDeletePayment): Promise<boolean> => {
        return await this.post('fc/payment/delete', req)
    }
    deleteFCUpdateContact = async (req: models.FCDeleteUpdateContact): Promise<boolean> => {
        return await this.post('fc/contacts/delete', req)
    }
    deleteFCUser = async (req: models.FCDeleteUser): Promise<boolean> => {
        return await this.post('fc/users/delete', req)
    }
    getFCDetails = async (req: models.FCReq): Promise<models.FCDetails> => {
        return await this.post('fc/details', req) as models.FCDetails
    }
    getFCEquipment = async (req: models.FCReq): Promise<string[]> => {
        return await this.post('fc/equipment', req)
    }
    getFCFacilities = async (req: models.FCReq): Promise<string[]> => {
        return await this.post('fc/facilities', req)
    }
    getFCImages = async (req: models.FCReq): Promise<string[]> => {
        return await this.post('fc/images', req)
    }
    getAllFacilities = async (req: any): Promise<models.GSFEquipment[]> => {
        return await this.post('gsf/facilities/all', req)
    }

    getAllArea = async (req: any): Promise<models.Area[]> => {
        return await this.post('gsf/areas/all', req)
    }

    getAllEquipments = async (req: any): Promise<models.GSFEquipment[]> => {
        return await this.post('gsf/equipment/all', req)
    }
    //fc pricesettings
    createFCPriceSetting = async (req: models.FCCreatePriceSetting): Promise<models.FCPriceSetting> => {
        return await this.post('fc/pricesetting/create', req) as models.FCPriceSetting
    }
    deleteFCPriceSetting = async (req: models.FCDeletePricesetting): Promise<boolean> => {
        return await this.post('fc/pricesetting/delete', req)
    }
    getFCPriceSettings = async (req: models.FCgetPriceSettings): Promise<models.FCPriceSetting[]> => {
        return await this.post('fc/pricesettings', req) as models.FCPriceSetting[]
    }
    updateFCPriceSetting = async (req: models.FCUpdatePriceSetting): Promise<models.FCPriceSetting> => {
        return await this.post('fc/pricesetting/update', req) as models.FCPriceSetting
    }
    getFCPackages = async (req: models.FCReq): Promise<models.FCPackage[]> => {
        return await this.post('fc/packages', req) as models.FCPackage[]
    }
    getFCPackageSales = async (req: models.FCPackagesales): Promise<models.Paging<models.FCPackageSale>> => {
        return await this.post('fc/packages/sales', req) as models.Paging<models.FCPackageSale>
    }

    getFCPaymentDetails = async (req: models.FCPaymentDetailsReq): Promise<models.Paging<models.FCPaymentDetail>> => {
        return await this.post('fc/payments/details', req) as models.Paging<models.FCPaymentDetail>
    }
    getFCPayments = async (req: models.FCPaymentsReq): Promise<models.Paging<models.FCPayment>> => {
        return await this.post('fc/payments', req) as models.Paging<models.FCPayment>
    }
    getFCSlots = async (req: models.FCReq): Promise<models.SlotSettings> => {
        return await this.post('fc/slots', req) as models.SlotSettings
    }
    updateFCBilling = async (req: models.FCUpdateBilling): Promise<models.FCBilling> => {
        return await this.post('fc/billing/update', req) as models.FCBilling
    }
    updateFCBills = async (req: models.FCUpdateBill): Promise<models.FCBill> => {
        return await this.post('fc/bills/update', req) as models.FCBill
    }
    updateFCBillingMethod = async (req: models.FCUpdateBillingMethod): Promise<models.FCBillingMethod> => {
        return await this.post('fc/billing/methods/update', req) as models.FCBillingMethod
    }
    updateFCContact = async (req: models.FCUpdateContact): Promise<models.FCContact> => {
        return await this.post('fc/contacts/update', req) as models.FCContact
    }
    updateFCDetails = async (req: models.FCUpdateDetails): Promise<models.FCDetails> => {
        return await this.post('fc/details/update', req) as models.FCDetails
    }
    updateFCEquipment = async (req: models.FCUpdateEquipment): Promise<string[]> => {
        return await this.post('fc/equipment/update', req)
    }
    updateFCFacilitites = async (req: models.FCUpdateFacilities): Promise<string[]> => {
        return await this.post('fc/facilities/update', req)
    }
    updateFCPackage = async (req: models.FCUpdatePackage): Promise<models.FCPackage> => {
        return await this.post('fc/packages/update', req) as models.FCPackage
    }
    updateFCSlots = async (req: models.FCUpdateSlots): Promise<boolean> => {
        return await this.post('fc/slots/update', req)
    }
    updateFCUser = async (req: models.FCUpdateUser): Promise<models.FCUser> => {
        return await this.post('fc/users/update', req) as models.FCUser
    }
    updateFCPayment = async (req: models.FCUpdatePayment): Promise<models.FCPayment> => {
        return await this.post('fc/payments/update', req) as models.FCPayment
    }
    updateFCUsp = async (req: models.FCUpdateUSP): Promise<boolean> => {
        return await this.post('fc/usp/update', req)
    }
    updateFCUploadImage = async (req: models.FCUploadImage): Promise<models.FCImages> => {
        const form = new FormData();
        req.img.forEach((v, i) => {
            form.append('img' + i, v)
        })
        form.append('fc_id', req.fc_id.toString())
        return await this.post('fc/images/upload', form, true) as models.FCImages
    }
    updateFCUploadLogo = async (req: models.FCUploadLogo): Promise<models.FCLogo> => {
        const form = new FormData();
        form.append('logo', req.logo)
        form.append('fc_id', req.fc_id.toString())
        return await this.post('fc/logo/upload', form, true) as models.FCLogo
    }
    getFCUsers = async (req: models.FCReq): Promise<models.FCUser[]> => {
        return await this.post('fc/users', req) as models.FCUser[]
    }
    getFCUsp = async (req: models.FCReq): Promise<models.FCUsp> => {
        return await this.post('fc/usp', req) as models.FCUsp
    }
    //FCHolidays
    updateStatus = async (req: models.FCStatus): Promise<models.FCStatus> => {
        return await this.post('fc/update/openstatus', req) as models.FCStatus
    }
    addHoliday = async (req: models.FCHolidaysReq): Promise<models.FCHolidays[]> => {
        return await this.post('fc/holiday/create', req) as models.FCHolidays[]
    }
    getFCHolidays = async (req: models.FCReq): Promise<models.FCHolidays[]> => {
        return await this.post('fc/holidays', req) as models.FCHolidays[]
    }
    deleteFCHoliday = async (req: models.FCDeleteHolidays): Promise<models.FCHolidays[]> => {
        return await this.post('fc/holiday/delete', req) as models.FCHolidays[]
    }
    getStatus = async (req: models.FCReq): Promise<models.FCStatus> => {
        return await this.post('fc/status', req) as models.FCStatus
    }


    //
    changeOpenStatus = async (req: models.FCHolidaysReq): Promise<models.FCHolidays[]> => {
        return await this.post('fc/holidays', req) as models.FCHolidays[]
    }

    createFitnessCenter = async (req: models.GSFCreateFitnessCenter): Promise<models.GSFFitnessCenter> => {
        return await this.post('gsf/fcs/create', req) as models.GSFFitnessCenter
    }
    createOrganization = async (req: models.GSFCreateOrg): Promise<models.GSFOrganization> => {
        return await this.post('gsf/orgs/create', req) as models.GSFOrganization
    }
    createGSFUser = async (req: models.GSFCreateUser): Promise<models.GSFUser> => {
        return await this.post('gsf/users/create', req) as models.GSFUser
    }
    deleteGSFUser = async (req: models.GSFDeleteUser): Promise<boolean> => {
        return await this.post('gsf/users/delete', req)
    }
    getGSFFitnessCenters = async (req: models.GSFGetFitnessCenters): Promise<models.Paging<models.GSFFitnessCenter>> => {
        return await this.post('gsf/fcs', req) as models.Paging<models.GSFFitnessCenter>
    }
    getGSFOrganizations = async (req: models.GSFGetOrganizations): Promise<models.Paging<models.GSFOrganization>> => {
        return await this.post('gsf/orgs', req) as models.Paging<models.GSFOrganization>
    }
    getGSFConsumers = async (req: models.GetGSFConsumers): Promise<models.Paging<models.GSFConsumers>> => {
        return await this.post('gsf/consumers', req) as models.Paging<models.GSFConsumers>
    }
    updateFCStatus = async (req: models.GSFUpdateFCStatus): Promise<boolean> => {
        return await this.post('gsf/fcs/status/update', req)
    }
    updateOrgStatus = async (req: models.GSFUpdateOrgStatus): Promise<boolean> => {
        return await this.post('gsf/orgs/status/update', req)
    }
    updateGSFUser = async (req: models.GSFUpdateUser): Promise<models.GSFUser> => {
        return await this.post('gsf/users/update', req) as models.GSFUser
    }
    getGSFUsers = async (req: any): Promise<models.GSFUser[]> => {
        return await this.post('gsf/users', req) as models.GSFUser[]
    }

    getNearByFcsToUser = async (req: any): Promise<models.NearByFcs[]> => {
        return await this.post('gsf/nearby/fcs', req) as models.NearByFcs[]
    }
    //
    createGSFFacility = async (req: models.GSFCreateFacilite): Promise<models.GSFFacility[]> => {
        return await this.post('gsf/facilitie/create', req) as models.GSFFacility[]
    }
    createGSFArea = async (req: models.Area): Promise<models.Area[]> => {
        return await this.post('gsf/area/create', req) as models.Area[]
    }
    deleteGSFFacility = async (req: models.GSFDeleteFacility): Promise<boolean> => {
        return await this.post('gsf/facility/delete', req)
    }
    deleteGSFArea = async (req: models.GSFDeleteArea): Promise<boolean> => {
        return await this.post('gsf/area/delete', req)
    }
    createGSFEquipment = async (req: models.GSFCreateEquipment): Promise<models.GSFEquipment[]> => {
        return await this.post('gsf/equipment/create', req) as models.GSFEquipment[]
    }

    deleteGSFEquipment = async (req: models.GSFDeleteEquipment): Promise<boolean> => {
        return await this.post('gsf/equipment/delete', req)
    }
    updateAgreement = async (req: models.UpdateAgreement): Promise<boolean> => {
        return await this.post('gsf/update/agreement', req)
    }
    updateGSUsers = async (req: any): Promise<models.GSFUser[]> => {
        return await this.post('gsf/update-user', req) as models.GSFUser[]
    }
    createOrgDomain = async (req: models.OrgCreateDomain): Promise<models.OrgDomain> => {
        return await this.post('org/domains/create', req) as models.OrgDomain
    }
    getOrgDetails = async (req: models.OrgReq): Promise<models.OrgDetails> => {
        return await this.post('org/details', req) as models.OrgDetails
    }
    createOrgEmployee = async (req: models.OrgCreateEmployee): Promise<models.OrgEmployee> => {
        return await this.post('org/employees/create', req) as models.OrgEmployee
    }
    createOrgPackage = async (req: models.OrgCreatePackage): Promise<models.OrgPackage> => {
        return await this.post('org/packages/create', req) as models.OrgPackage
    }
    createOrgUser = async (req: models.OrgCreateUser): Promise<models.OrgUser> => {
        return await this.post('org/users/create', req) as models.OrgUser
    }
    deleteOrgDomain = async (req: models.OrgDeleteDomain): Promise<boolean> => {
        return await this.post('org/domains/delete', req)
    }
    deleteOrgEmployee = async (req: models.OrgDeleteEmployee): Promise<boolean> => {
        return await this.post('org/employees/delete', req)
    }
    deleteOrgPackage = async (req: models.OrgDeletePackage): Promise<boolean> => {
        return await this.post('org/packages/delete', req)
    }
    deleteOrgUser = async (req: models.OrgDeleteUser): Promise<boolean> => {
        return await this.post('org/users/delete', req)
    }
    getOrgDomains = async (req: models.OrgReq): Promise<models.OrgDomain[]> => {
        return await this.post('org/domains', req)
    }
    getOrgEmployees = async (req: models.OrgReq): Promise<models.OrgEmployee[]> => {
        return await this.post('org/employees', req) as models.OrgEmployee[]
    }
    getOrgPackages = async (req: models.OrgReq): Promise<models.OrgPackage[]> => {
        return await this.post('org/packages', req) as models.OrgPackage[]
    }
    updateOrgEmployee = async (req: models.OrgUpdateEmployee): Promise<models.OrgEmployee> => {
        return await this.post('org/employees/update', req) as models.OrgEmployee
    }
    updateOrgPackage = async (req: models.OrgUpdatePackage): Promise<models.OrgPackage> => {
        return await this.post('org/packages/update', req) as models.OrgPackage
    }
    updateOrgDetails = async (req: models.OrgUpdate): Promise<models.OrgDetails> => {
        return await this.post('org/details/update', req) as models.OrgDetails
    }
    UpdateOrgUser = async (req: models.OrgUpdateUser): Promise<models.OrgUser> => {
        return await this.post('org/users/update', req) as models.OrgUser
    }
    getOrgUsage = async (req: models.OrgGetUsage): Promise<models.Paging<models.OrgUsage>> => {
        return await this.post('org/usage', req) as models.Paging<models.OrgUsage>
    }
    getOrgUsers = async (req: models.OrgReq): Promise<models.OrgUser[]> => {
        return await this.post('org/users', req) as models.OrgUser[]
    }
    getAllAreas = async (req: number): Promise<models.Area[]> => {
        return await this.post('gsf/areas/all', { city: 1 }) as models.Area[]
    }
    getAllWorkouts = async (): Promise<models.Workout[]> => {
        return await this.post('gsf/workouts/all', {}) as models.Workout[]
    }
    getGSFDashboardData = async (req: models.GetGSFDashboardDataReq): Promise<models.GetGSFDashboardData> => {
        return await this.post('gsf/dashboard', req) as models.GetGSFDashboardData

    }
    getOrgDashboardData = async (req: models.GetOrgDashboardDataReq): Promise<models.GetOrgDashboardData> => {
        return await this.post('org/dashboard', req) as models.GetOrgDashboardData
    }
    getFCDashboardData = async (req: models.GetFCDashboardDataReq): Promise<models.GetFCDashboardData> => {
        return await this.post('fc/dashboard', req) as models.GetFCDashboardData
    }
    getGSfPlans = async (req: any): Promise<models.GSFPlan[]> => {
        return await this.post('gsf/plans', req) as models.GSFPlan[]
    }
    createGSFPlan = async (req: models.GSFCreatePlan): Promise<models.GSFCreatePlan> => {
        return await this.post('gsf/plan/create', req) as models.GSFCreatePlan
    }
    createGSFCheckin = async (req: models.GSFCreateCheckin): Promise<models.GSFCreatePlan> => {
        return await this.post('gsf/checkin/create', req) as models.GSFCreatePlan
    }
    deleteGSFPlan = async (req: models.GSFDeletePlan): Promise<boolean> => {
        return await this.post('gsf/plan/delete', req)
    }
    updateGSFPlan = async (req: models.GSFUpdatePlan): Promise<models.GSFUpdatePlan> => {
        return await this.post('gsf/plan/update', req) as models.GSFUpdatePlan
    }
    activateConsumerPlan = async (req: models.GSFActivatePlan): Promise<models.GSFActivatePlan> => {
        return await this.post('gsf/select/plans/cunsumers', req) as models.GSFActivatePlan
    }
    getGSfConsPlan = async (req: { plan_id: number }): Promise<models.GSFPlan> => {
        return await this.post('gsf/consumer/plan', req) as models.GSFPlan
    }
    uploadFC360Images = async (req: models.FCUpload360Img): Promise<models.FC360Img> => {
        const form = new FormData();
        form.append('img', req.img)
        form.append('fc_id', req.fc_id.toString())
        return await this.post('fc/360image/upload', form, true) as models.FC360Img
    }
    sendMessage = async (req: models.SendMessage): Promise<boolean> => {
        return await this.post('gsf/message/send', req)
    }
    getAgreement = async (req: any): Promise<models.agreement> => {
        return await this.post('get_agreement', req) as models.agreement
    }
    getPaymentReport = async (req: { year: number, month: number }): Promise<models.GSFPaymentDetail[]> => {
        return await this.post('gsf/report/payments', req)
    }
}
