import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    organizations: models.Paging<models.GSFOrganization>
    filteredOrganizations: models.GSFOrganization[]
    loading: boolean
    error: string | null
    limit: number
    offset: number
    city: string
    q: string
}

class GSFOrganizations extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);


        this.state = {
            organizations: { count: 0, items: [] as models.GSFOrganization[] },
            filteredOrganizations: [] as models.GSFOrganization[],
            loading: true,
            error: null,
            limit: 500,
            offset: 0,
            city: '',
            q: '',
        }
    }

    gsfOrganizations = () => {
        this.setState({
            limit: 500,
            offset: 0,
            city: 'Hyderabad',
            q: '',
            loading: true
        })
    }

    componentDidMount = () => {

        const { limit, offset, city, q } = this.state;

        Server.get().getGSFOrganizations({ limit, offset, city, q }).then((organization) => {
            this.setState({
                organizations: organization,
                filteredOrganizations: organization.items,
                loading: false
            })
        }).catch((e) => {
            this.setState({
                error: getApiError(e),
                loading: false
            })
        })
    }



    onSearch = (input: string) => {
        const { organizations } = this.state;

        const filteredOrganizations = organizations.items.filter(org => {
            const lowerStr = input.toLowerCase();
            return org.name.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.manager_name.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.manager_email.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.manager_phone.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.hr_name.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.hr_email.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.hr_phone.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.manager_name.toLowerCase().indexOf(lowerStr) >= 0 ||
                org.address.toLowerCase().indexOf(lowerStr) >= 0
        })

        this.setState({
            filteredOrganizations
        })

    }

    render() {
        const { error } = this.state;
        return (
            <div className='list'>
                <h2>Organizations</h2>
                <div className='form-group'>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <div>Total organizations : {this.state.organizations.count}</div>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/organization/create')}><i className="fas fa-create-alt"></i>New Organization</button>
                <Loader loading={this.state.loading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Manager name</th>
                                <th scope="col">Manager email</th>
                                <th scope="col">Manager phone</th>
                                <th scope="col">Hr name</th>
                                <th scope="col">Hr email</th>
                                <th scope="col">Hr phone</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredOrganizations && this.state.filteredOrganizations.map((c, i) =>
                                <tr key={c.id}>
                                    <td scope="row"><Link to={'/org/' + c.id + '/'}>{c.name}</Link></td>
                                    <td>{c.address}</td>
                                    <td>{c.manager_name}</td>
                                    <td>{c.manager_email}</td>
                                    <td>{c.manager_phone}</td>
                                    <td>{c.hr_name}</td>
                                    <td>{c.hr_email}</td>
                                    <td>{c.hr_phone}</td>
                                    <td>{c.status == 0 ? 'In-progress' : (c.status == 1 ? 'Active' : 'In-active')}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div >
        );
    }
}

export default withRouter(GSFOrganizations);
