import moment from 'moment';
import * as React from 'react';
import DatePicker from "react-datepicker";
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    holidays: models.FCHolidays[]
    error: string | null
    limit: number
    offset: number
    isClosed: boolean
    isDisableAdd: boolean
    isLoading: boolean
    selectedDate: Date
}
class FCHolidays extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            holidays: [] as models.FCHolidays[],
            error: null,
            limit: 500,
            offset: 0,
            isClosed: false,
            isDisableAdd: false,
            isLoading: true,
            selectedDate: new Date(),
        }
    }
    onSelectDate = (selectedDate: Date) => {
        console.log("selected date", selectedDate)
        this.setState({
            selectedDate: selectedDate,
            isLoading: false
        })
    }
    updateCheckBox = () => {
        const { isClosed } = this.state
        this.setState({
            isClosed: !isClosed
        }, () => this.updateStatus()
        )
    }
    getStatus = () => {
        const { fc_id } = this.props
        Server.get().getStatus({ fc_id })
            .then((status) => {
                this.setState({
                    isClosed: status.manual_closed
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                })
            })
    }
    updateStatus = () => {
        const { isClosed } = this.state
        const { fc_id } = this.props

        Server.get().updateStatus({ fc_id, manual_closed: isClosed })
            .then((isClosed) => {
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onAdd = (event: any) => {
        event.preventDefault();
        const { selectedDate } = this.state
        const { fc_id } = this.props
        const date = moment(selectedDate).format("DD/MM/YYYY")

        Server.get().addHoliday({ fc_id, holiday: date })
            .then((date) => {
                this.setState({
                    holidays: date
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }
    onDelete = (holiday: string) => {

        const { fc_id } = this.props
        if (confirm('Are you sure you want to delete this package?')) {

            Server.get().deleteFCHoliday({ fc_id, holiday })
                .then(() => {
                    let _holiday = this.state.holidays
                    const holiday1 = _holiday.filter((holidays) => holidays.holiday !== holiday)
                    this.setState({ holidays: holiday1, isLoading: false })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        isLoading: false
                    })
                })
        }
    }
    componentDidMount = () => {
        const { fc_id } = this.props;
        this.getStatus();
        Server.get().getFCHolidays({ fc_id })
            .then((holidays) => {
                this.setState({
                    holidays: holidays,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })

            })
    }
    public render() {
        const { error, isLoading, isClosed } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Holidays</h1>
                        </div>
                    </ol>
                </nav>
                <Loader loading={isLoading}>
                    <span>Current Status</span>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <div className='form-group'>
                        <label className="switch">
                            <input type="checkbox" onChange={this.updateCheckBox} checked={!isClosed} />
                            <span className="slider round"></span>
                            <span className="absolute-no">Closed</span>

                        </label>
                    </div>
                    <div className="form-group">
                        <span>Select date </span>
                        <DatePicker
                            selected={this.state.selectedDate}
                            onChange={this.onSelectDate}
                            className="form-control"

                        />
                        <button type="button" className="btn btn-primary" onClick={this.onAdd}>Add</button>
                    </div>
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.holidays.map((c, i) =>
                                <tr key={c.holiday}>
                                    <th scope="row">{c.holiday}</th>
                                    <td>
                                        <button type='button' className="btn btn-link" onClick={() => this.onDelete(c.holiday)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}
export default withRouter(FCHolidays);