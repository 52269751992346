import * as React from 'react';
import { ErrorMessage } from "formik";

class Error extends React.Component<{ name: string }> {
    render() {
        return (
            <ErrorMessage component='div' className='text-danger' name={this.props.name} />
        );
    }
}
export default Error