import * as React from "react";
import DatePicker from "react-datepicker";
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import Pager from '../common/Pager';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    fcBills: models.Paging<models.FCBill>
    isLoading: boolean
    error: string | null
    limit: number,
    from: Date,
    to: Date,
    offset: number,
    payment: models.FCPayment[]
}

class FCBills extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            fcBills: { count: 0, items: [] as models.FCBill[] },
            isLoading: true,
            payment: [] as models.FCPayment[],
            error: null,
            limit: 500,
            offset: 0,
            from: new Date(),
            to: new Date(),
        };
        this.onFromChange = this.onFromChange.bind(this)
        this.onToChange = this.onToChange.bind(this)
    }

    fcBills = () => {
        this.setState({
            isLoading: false,
            limit: 500,
            offset: 0,
            from: new Date(),
            to: new Date()
        })
    }

    onFromChange = (date: Date) => {
        date.setHours(0)
        date.setMinutes(0)
        console.log("from time", date)
        this.setState({
            from: date
        }, () => {

            this.componentDidMount()
        })
    }

    onToChange = (date: Date) => {
        date.setHours(23)
        date.setMinutes(59)
        console.log("to time", date)
        this.setState({
            to: date
        }, () => {

            this.componentDidMount()
        })
    }
    componentDidMount = () => {
        const { fc_id } = this.props;
        const { limit, from, offset, to } = this.state;
        Server.get().getFCBills({ limit, fc_id, from: from.getTime(), offset, to: to.getTime() })
            .then((bills) => {
                this.setState({
                    fcBills: bills,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onPage(limit: number, offset: number) {
        this.setState({
            limit,
            offset
        })
    }

    onDelete = (id: number) => {
        const { fc_id } = this.props;
        if (confirm('Are you sure you want to delete this bills details ?')) {
            Server.get().deleteFCBills({ fc_id, bill_id: id })
                .then(() => {
                    let bills = this.state.fcBills
                    bills.items = bills.items.filter((bill) => bill.id !== id)
                    this.setState({ fcBills: bills })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        isLoading: false
                    })
                })
        }
    }

    render() {
        const { from, to, fcBills, error, isLoading } = this.state;
        const { fc_id } = this.props;
        return (
            <div className="list">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Bills</h1>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/bills/create')}><i className="fas fa-create-alt"  ></i>New Bills</button>
                <Pager count={this.state.fcBills.count} limit={25} page={0} onChange={(limit, offset) => this.onPage(limit, offset)} />
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <div className="form-group">
                        From :
                        <DatePicker className="form-control"
                            selected={from}
                            onChange={this.onFromChange}
                        />
                    </div>
                    <div className="form-group">
                        To :
                        <DatePicker className="form-control"
                            selected={to}
                            onChange={this.onToChange}
                        />
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Start date</th>
                                <th scope="col">End date</th>
                                <th scope="col">Checkins count</th>
                                <th scope="col">Total amount</th>
                                <th scope="col">Amount paid</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                fcBills.items && fcBills.items.map((c, i) =>
                                    <tr key={c.id}>
                                        <th scope="row">{c.start_date}</th>
                                        <td>{c.end_date}</td>
                                        <td>{c.checkins_count}</td>
                                        <td>{c.amount_total}</td>
                                        <td>{c.amount_paid}</td>
                                        <td>
                                            <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/payment/create', { bill_id: c.id })}>Pay</button>
                                            <button type='button' className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/bills/update', { bills: c })}><i className="fas fa-edit">Edit</i></button>
                                            <button type='button' className="btn btn-link" onClick={(e) => this.onDelete(c.id)} ><i className="fas fa-trash-alt" >Delete</i></button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </Loader>
            </div>

        );
    }
}

export default withRouter(FCBills);

