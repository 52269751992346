import * as React from 'react';
import DatePicker from "react-datepicker";
import { BarChart, BarData, LineChart, LineData } from 'react-easy-chart';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';



interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    data: models.GetFCDashboardData
    users_count: number
    sales_amount: number
    sessions_count: number
    average_rating: number
    sales_amounts: LineData[][]
    users_counts: BarData[]
    sessionuser_count: BarData[]
    error: string | null
    from: Date
    to: Date
    loading: boolean

}
class FCDashboard extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const to = new Date()
        const from = new Date()
        from.setMonth(to.getMonth() - 1)

        this.state = {
            data: {} as models.GetFCDashboardData,
            users_count: 5,
            sales_amount: 5,
            sessions_count: 0,
            average_rating: 0,
            sales_amounts: [],
            users_counts: [],
            sessionuser_count: [],
            error: null,
            to,
            from,
            loading: true
        };


        this.onFromChange = this.onFromChange.bind(this)
        this.onToChange = this.onToChange.bind(this)
    }
    data = () => {
        this.setState({
            loading: true,
            from: new Date(),
            to: new Date(),
        })
    }
    onFromChange = (date: Date) => {
        date.setHours(0)
        date.setMinutes(0)
        console.log("from time", date)
        this.setState({
            from: date
        }, () => {

            this.getDashboardDetails()
        })
    }

    onToChange = (date: Date) => {
        date.setHours(23)
        date.setMinutes(59)
        console.log("to time", date)
        this.setState({
            to: date
        }, () => {

            this.getDashboardDetails()
        })
    }
    componentDidMount = () => {
        const { fc_id } = this.props
        const { from, to } = this.state;
        console.log("from", from)
        console.log('to', to)
        this.getDashboardDetails()
    }
    getDashboardDetails = () => {
        const { fc_id } = this.props
        const { from, to } = this.state;
        Server.get().getFCDashboardData({ fc_id, from: from.getTime(), to: to.getTime() })
            .then((data) => {
                const sales_amounts = [
                    data.sales_amounts.map(
                        (i) => { return { 'x': i.day, 'y': i.total_incomes } })
                ]
                const users_counts = data.users_counts.map(
                    (i) => { return { 'x': i.month, 'y': i.total_users } })
                const sessionuser_count = data.sessionuser_count.map(
                    (i) => { return { 'x': i.range, 'y': i.count } }
                )
                this.setState({
                    data: data,
                    sales_amounts,
                    users_counts,
                    sessionuser_count,
                    loading: false
                })
            }).catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })

    }

    public render() {
        const { from, to, data, error } = this.state
        const { fc_id } = this.props;
        return (
            <div className="form">

                <div className="text-right">

                    From :
                        <DatePicker className="form-control"
                        selected={from}
                        onChange={this.onFromChange}
                    />
                    To :
                        <DatePicker className="form-control"
                        selected={to}
                        onChange={this.onToChange}
                    />

                </div>
                <Loader loading={this.state.loading}>
                    <div className='container'>

                        <div className="row">
                            <div className='col'>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <div className="card-text">
                                            <i className="fa fa-users">
                                                Users</i>
                                            {this.state.data.users_count}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <div className="card-text">
                                            <i className="fa fa-file-invoice-dollar">
                                                Sales</i>
                                            {this.state.data.sales_amount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <div className="card-text">
                                            <i className="fa fa-bolt">
                                                Sessions</i>
                                            {this.state.data.sessions_count}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <div className="card-text">
                                            <i className='fas fa-thumbs-up'>
                                                Average Rating</i>
                                            {this.state.data.average_rating}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='container'>
                        <div className='row' >
                            <div className='col'>
                                <h3> Users Count for year</h3>
                                <BarChart
                                    axisLabels={{ x: 'My x Axis', y: 'My y Axis' }}
                                    axes
                                    height={350}
                                    width={500}
                                    colorBars
                                    barWidth={5}
                                    xType={'text'}
                                    data={
                                        this.state.users_counts
                                    }
                                />
                            </div>
                            <div className='col'>
                                <h3> Average Session count</h3>
                                <BarChart
                                    axisLabels={{ x: 'My x Axis', y: 'My y Axis' }}
                                    axes={true}
                                    xType={'text'}
                                    width={350}
                                    height={350}
                                    barWidth={5}
                                    // xDomainRange={[0, 30]}
                                    colorBars
                                    data={
                                        this.state.sessionuser_count
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <h3>Sales Trend </h3>
                        <LineChart

                            dataPoints
                            // xType={'linear'}
                            axisLabels={{ x: 'Month', y: 'counts' }}
                            xType={'time'}
                            // tickTimeDisplayFormat={'%a'}
                            xDomainRange={['1-jan-19', '31-jan-19']}
                            axes
                            width={700}
                            height={350}
                            data={
                                this.state.sales_amounts
                            }
                        />
                    </div>
                </Loader >
            </div >
        );

    }

}

export default withRouter(FCDashboard);