import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import * as Yup from 'yup';
import { models } from '../../models';
import { FormikActions, Formik, Form, Field } from 'formik';
import Error from '../common/Error';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    data: models.FCUpdateBilling
    isLoading: boolean
    error: string | null
}
class FCUpdateBilling extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const billing = this.props.history.location.state.billing as models.FCBilling
        this.state = {
            data: {
                gst: billing.gst || '',
                pan: billing.pan || '',
                fc_id: this.props.fc_id
            },
            isLoading: true,
            error: null,
        };
    }
    schema = () => {
        return Yup.object().shape({
            gst: Yup.string().label('GSTN').when('pan', {
                is: pan => pan,
                then: Yup.string(),
                otherwise: Yup.string()
            }),
            pan: Yup.string().label('PAN').when('gst', {
                is: gst => gst,
                then: Yup.string(),
                otherwise: Yup.string()
            })
        }, [['pan', 'gst']])
    }
    handleSubmit = (values: models.FCUpdateBilling, fa: FormikActions<any>) => {
        Server.get().updateFCBilling(values)
            .then(() => {
                fa.setSubmitting(false)
                this.props.history.goBack()
            })
            .catch((e: any) => {
                fa.setErrors(e),
                    fa.setSubmitting(false)
            })
    }
    render() {
        const { fc_id } = this.props;
        const { data, error } = this.state;
        return (
            <div className='details' >
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update billing details</h2>
                        </div>
                    </ol>
                </nav>
                {
                    error
                        ? <span style={{ color: 'red' }}>{error}</span>
                        : null
                }
                <Formik initialValues={data} onSubmit={this.handleSubmit} validationSchema={this.schema} validateOnBlur={true}
                    render={({ isSubmitting, submitForm }) =>
                        <Form>
                            <div className="form-group">
                                <label>GSTN</label>
                                <Field type='text' name="gst" placeholder='Enter Gst number' className="form-control" />
                                <Error name='gst' />
                            </div>
                            <div className='form-group'>
                                <label>PAN</label>
                                <Field type='text' name="pan" placeholder='Enter Pan number' className="form-control" />
                                <Error name='pan' />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                            </div>
                        </Form>
                    }
                />
            </div>
        );
    }
}

export default withRouter(FCUpdateBilling);
