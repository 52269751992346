import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getApiError, getImageUrl } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import ImageUploader from 'react-images-upload';
import Loader from 'react-loader-spinner'
import Avatar from 'react-avatar';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    isDisableSubmit: boolean
    logo: string | any
    error: string | null
    isUploading: boolean
    loading: boolean
    attempt: number
}
class FCUploadLogo extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            isDisableSubmit: false,
            logo: '',
            error: null,
            isUploading: false,
            attempt: 0,
        };

    }

    componentDidMount = () => {
        const { fc_id } = this.props;
        Server.get().getFCDetails({ fc_id })
            .then((fcDetails) => {
                let logo = fcDetails.logo
                const _logo = getImageUrl(fc_id, logo, '128x128')
                this.setState({ logo: _logo })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }

    handleSubmit = (logo: any) => {
        const { fc_id } = this.props;
        const { attempt } = this.state;


        this.setState({ isUploading: true, attempt: attempt + 1 })

        Server.get().updateFCUploadLogo({ fc_id, logo: logo[0] })
            .then((_logo) => {
                const url = getImageUrl(fc_id, _logo.logo, '128x128')
                this.setState({ logo: url, isUploading: false })
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e),
                    isUploading: false
                })
            })
    }

    render() {
        const { logo, error, isUploading, attempt } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Fitness Center logo</h2>
                        </div>
                    </ol>
                </nav>
                {
                    isUploading ?
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                        : null
                }
                {
                    error
                        ? <span style={{ color: 'red' }}>{error}</span>
                        : null
                }
                <div className="container">
                    <div className='align-items-start'>
                    </div>
                    <div>
                        <Avatar
                            src={logo}
                            round={false}
                        />
                    </div>
                    <div>
                        <div className='row'>
                            <ImageUploader
                                key={attempt}
                                buttonText='Select logo'
                                onChange={this.handleSubmit}
                                imgExtension={['.jpg', '.gif', '.png', ".jpeg"]}
                                maxFileSize={5242880}
                                singleImage={true}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default FCUploadLogo;
