import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isLoading: boolean
    error: string | null
    area: models.Area[],
    filteredArea: models.Area[],

}
class GSFArea extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            area: [] as models.Area[],
            filteredArea: [] as models.Area[],

        };
    }
    onDelete = (name: string) => {

        const { } = this.props
        if (confirm('Are you sure you want to delete this facility?')) {
            Server.get().deleteGSFArea({ name })
                .then(() => {
                    let area = this.state.area
                    area = area.filter((area) => area.name !== name)
                    this.setState({
                        area,
                        isLoading: false
                    })

                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        isLoading: false
                    })
                })
        }
    }
    componentDidMount = () => {
        Server.get().getAllArea({ city: 1 })
            // 1 - hyderabad
            .then((area) => {
                this.setState({
                    area: area,
                    filteredArea: area,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })

    }

    onSearch = (str: string) => {
        const { area } = this.state;
        const _area = area.filter((area) => {
            const lowerStr = str.toLowerCase()
            return area.name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filteredArea: _area,
            isLoading: false
        })
    }


    public render() {
        const { filteredArea, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Area</h2>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/area/create')}><i className="fas fa-create-alt"></i>Add area</button>
                <div className='form-group'>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <Loader loading={isLoading}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                        <tbody>

                            {filteredArea.map((c) =>
                                <tr key={c.city_id}>
                                    <td scope="row">
                                        {c.name}
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-link"><i className="fas fa-trash-alt" onClick={() => this.onDelete(c.name)}>Delete</i></button>
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(GSFArea);