import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    isdisableSubmit: boolean
    name: string
    description: string
    price: string
    error: string | null
    color: "error" | "success" | null
    period: number
    active: boolean
    multi: { price: any, period: number }[]
    isLoading: boolean
    commission: string
    isGsfUser: boolean
    commissionInPercentage: boolean
}
class FCCreatePackage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            isdisableSubmit: false,
            name: '',
            description: '',
            price: '',
            error: null,
            color: null,
            period: 1,
            active: false,
            isGsfUser: false,
            commission: '',
            commissionInPercentage: false,
            multi: [{ period: 1, price: '' }, { period: 3, price: '' }, { period: 6, price: '' }, { period: 12, price: '' }]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.OnChange = this.OnChange.bind(this);
    }

    getValidationErr = () => {
        let { name, description, multi } = this.state
        let err = ""
        if (!name) {
            return 'Enter name'
        }
        if (!description) {
            return "Enter package description"
        }
        // for (let x = 0; x < multi.length; x++) {
        //     if (!multi[x].price) {
        //         return `Enter the ${multi[x].period} month amount of your package`
        //     }
        // }
        return err
    }
    componentDidMount = () => {
        Server.get().userType()
            .then((user_type) => {
                this.setState({
                    isGsfUser: user_type === 'gsf_user',
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }
    handleChange = () => {
        const { active } = this.state
        this.setState({ active: !active });
    }
    OnChange = () => {
        const { commissionInPercentage } = this.state
        this.setState({ commissionInPercentage: !commissionInPercentage })
    }
    handleOnChange = (event: any) => {
        this.setState({ period: parseInt(event.target.value) })
    }

    setPrice = (i: number, val: string) => {
        const { multi } = this.state
        multi[i].price = val
        this.setState({ multi })
    }


    onActive = (event: any) => {
        event.preventDefault()

        let { name, description, price, period, active, multi, commission, commissionInPercentage } = this.state
        price = price.replace(',', '').trim()
        const err = this.getValidationErr()
        const { fc_id } = this.props

        if (err) {
            this.setState({ error: err, color: "error" })
            return
        }
        this.setState({ isdisableSubmit: true, error: err })

        // multi = multi.map(v => { v.price = parseInt(v.price.replace(',', '').trim()); return v })

        Server.get().createFCPackage({ fc_id, active, description, name, period, price: parseInt(price), multi, commission: parseInt(commission), commission_in_percentage: commissionInPercentage })
            .then((r) => {
                this.props.history.goBack()
                this.setState({
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error",
                    isLoading: false,
                    isdisableSubmit: false
                })
            })
    }
    render() {
        const { isdisableSubmit, name, description, commission, error, color, active, period, multi, isGsfUser, commissionInPercentage } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Create package</h1>
                        </div>
                    </ol>
                </nav>
                <div className='container' >
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="base-form">
                                <form>

                                    {
                                        error
                                            ? <span style={{ color: 'red' }}>{error}</span>
                                            : null
                                    }
                                    <div className="form-group">
                                        <input type='name' className="form-control" name="name" placeholder='Name' autoCapitalize="off" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder='Description' rows={3} autoCapitalize="off" value={description} onChange={(e) => this.setState({ description: e.target.value })} ></textarea>
                                    </div>
                                    {[1, 3, 6, 12].map((v, i) =>
                                        <div className="form-group" key={i}>
                                            <label>{v + ' Month Price'}</label>
                                            <input type='price' className="form-control" name="price" placeholder={v + ' Month Price'} autoCapitalize="off" value={multi[i].price} onChange={(e) => this.setPrice(i, e.target.value)} />
                                        </div>
                                    )
                                    }
                                    <div className="form-group">
                                        <input type="checkbox" name="active" onChange={this.handleChange} checked={active} />
                                        <span>Active</span>
                                    </div>
                                    {
                                        isGsfUser ?
                                            <div className="form-group">
                                                <input type='name' className="form-control" name="commission" placeholder='Commission' autoCapitalize="off" value={commission} onChange={(e) => this.setState({ commission: e.target.value })} />
                                                <input type="checkbox" name="active" onChange={this.OnChange} checked={commissionInPercentage} />
                                                <span>Commission in percentage</span>
                                            </div>
                                            : null
                                    }
                                    <div>
                                        <button type="button" className="btn btn-primary" onClick={this.onActive} disabled={isdisableSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FCCreatePackage;
