import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';


interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isDisableSubmit: boolean
    name: string
    error: string | null
    color: "success" | "error" | null
    latitude: string
    longitude: string
    city_id: number
}
class GSFMasterArea extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isDisableSubmit: false,
            name: '',
            error: null,
            color: null,
            latitude: '',
            longitude: '',
            city_id: 1
        };
    }

    getValidationErr = (name: string, latitude: string, longitude: string) => {
        let err = ''
        if (!name) {
            err = 'Enter name'
            return
        }
        if (!latitude) {
            err = "Enter latitude"
            return
        }
        if (!longitude) {
            err = "Enter Longitude"
            return
        }
        return err
    }

    onSubmit = (event: any) => {
        event.preventDefault()

        const { name, latitude, longitude, city_id } = this.state;
        const err = this.getValidationErr(name, latitude, longitude)
        if (err) {
            this.setState({ error: err })
            return
        }
        Server.get().createGSFArea({ name, latitude: parseFloat(latitude), city_id, longitude: parseFloat(longitude) })
            .then((r) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error",
                    isDisableSubmit: false
                })
            })
    }
    render() {
        const { name, isDisableSubmit, error, latitude, longitude } = this.state;
        return (
            <div className='form'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Add Area</h2>
                        </div>
                    </ol>
                </nav>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="base-form">
                                <form>
                                    {
                                        error
                                            ? <span style={{ color: 'red' }}>{error}</span>
                                            : null
                                    }
                                    <div className='form-group'>
                                        <input type='text' className="form-control" name="details" placeholder='Enter Area' value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                    </div>

                                    <div className='form-group'>
                                        <input type='text' className="form-control" name="details" placeholder='Latitude' value={latitude} onChange={(e) => this.setState({ latitude: e.target.value })} />
                                    </div>

                                    <div className='form-group'>
                                        <input type='text' className="form-control" name="details" placeholder='Longitude' value={longitude} onChange={(e) => this.setState({ longitude: e.target.value })} />
                                    </div>
                                    <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GSFMasterArea);
