import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import moment from 'moment';




interface IProps extends RouteComponentProps<any> {
    org_id: number
}
interface IState {
    employees: models.OrgEmployee[]
    loading: boolean
    error: string | null
}
class OrgEmployees extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            employees: [] as models.OrgEmployee[],
            loading: true,
            error: null
        }
    }
    orgEmployees = () => {
        this.setState({
            loading: true
        })
    }

    componentDidMount = () => {
        const { org_id } = this.props
        Server.get().getOrgEmployees({ org_id })
            .then((employees) => {
                this.setState({
                    employees: employees,
                    loading: false
                })
            }).catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })
    }

    onDelete = (email: string) => {
        const { org_id } = this.props;
        if (confirm('Are you sure you want to delete this package ?')) {

            Server.get().deleteOrgEmployee({ org_id, email })
                .then(() => {
                    let stateEmployee = this.state.employees
                    const employees = stateEmployee.filter((employee) => employee.email !== email)
                    this.setState({
                        employees: employees,
                        loading: false
                    })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        loading: false
                    })
                })
        }
    }

    onSearch = (str: string) => {
        const { employees } = this.state;
        const _employees = employees.filter((employee) => {
            const lowerStr = str.toLowerCase()
            return employee.email.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            employees: _employees,
            loading: false
        })
    }
    render() {
        const { org_id } = this.props;
        const { error } = this.state;
        return (
            <div className='list'>
                <h2>Employees</h2>
                <button type="Button" className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/employee/create')}><i className="fas fa-create-alt"></i>New Employee</button>
                <Loader loading={this.state.loading}>
                    <div className='form-group'>
                        <SearchField
                            placeholder="Search..."
                            onChange={this.onSearch}
                            classNames="test-class"
                        />
                    </div>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                                <th scope="col">Sessions limit</th>
                                <th scope="col">Limit type</th>
                                <th scope="col">Minimum unit</th>
                                <th scope="col">Maximum unit</th>
                                <th scope="col">Last used</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.employees.map((c, i) =>
                                <tr key={c.email}>
                                    <th scope="row">{c.email}</th>
                                    <td>{c.sessions_limit}</td>
                                    <td>{c.limit_type}</td>
                                    <td>{c.min_unit}</td>
                                    <td>{c.max_unit}</td>
                                    <td>{c.start_at ? moment(c.start_at).format('DD/MM/YYYY HH:mm') : '-NA-'}</td>
                                    <td><button type='button' className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/employee/update', { employee: c })}><i className="fas fa-edit">Edit</i></button>
                                        <button type="button" className='btn btn-link' onClick={() => this.onDelete(c.email)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>

            </div>
        );
    }
}

export default withRouter(OrgEmployees);
