import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getApiError, isValidEmail, isValidPhone } from '../common/HelperMethods';
import { FC_ENTER_OTP, GSF_ENTER_OTP, ORG_ENTER_OTP } from '../common/Routes';
import { Server } from '../common/Server';
import { models } from '../models';

interface IProps extends RouteComponentProps<any> {
    user_type: 'end_user' | 'fc_user' | 'gsf_user' | 'org_user'
}

interface IState {
    emailorphone: string
    error: string | null
    isNextBtnDisable: boolean
    color: 'error' | 'success' | null
    otpMd5: string
}

class ResetPassword extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            emailorphone: '',
            error: '',
            isNextBtnDisable: false,
            color: null,
            otpMd5: ''
        };
    }

    getValidationErr = (emailorphone: string): string => {
        let err = ''
        if (!emailorphone) {
            err = 'Enter email or phone number'
        } else {
            let valid = false

            if (isValidEmail(emailorphone) || isValidPhone(emailorphone)) {
                valid = true
            }
            if (!valid) {
                err = 'Invalid email or phone number'
            }
        }
        console.log(err)
        return err
    }

    handleLogin = (event: any) => {
        event.preventDefault()
        this.props.history.goBack()
    }
    handleNext = (event: any) => {
        event.preventDefault()
        const { emailorphone, otpMd5, isNextBtnDisable } = this.state
        const err = this.getValidationErr(emailorphone)
        const user_type = this.props.user_type;
        const req = {} as models.ForgotPasswordReq

        if (err) {
            this.setState({ error: err, color: 'error' });
            return
        }
        this.setState({ isNextBtnDisable: true, error: err })


        console.log(this.props.user_type)

        if (emailorphone.indexOf('@') > 0) {
            req.email = emailorphone
        } else {
            req.phone = emailorphone
        }
        req.user_type = this.props.user_type;

        Server.get().forgotPassword(req)
            .then(({ otpMd5 }) => {
                this.setState({
                    otpMd5: otpMd5,
                    isNextBtnDisable: true
                })
                if (user_type === 'fc_user') {
                    this.props.history.push(FC_ENTER_OTP, { otpMd5: otpMd5, emailorphone: emailorphone, user_type: user_type })
                    return
                }
                if (user_type === 'gsf_user') {
                    this.props.history.push(GSF_ENTER_OTP, { otpMd5: otpMd5, emailorphone: emailorphone, user_type: user_type })
                    return
                }
                if (user_type === 'org_user') {
                    this.props.history.push(ORG_ENTER_OTP, { otpMd5: otpMd5, emailorphone: emailorphone, user_type: user_type })
                    return
                }
            })
            .catch((e: any) => {
                this.setState({
                    isNextBtnDisable: false,
                    error: getApiError(e),
                    color: "error"
                })
            })

    }


    render() {
        const { emailorphone, error, isNextBtnDisable, color } = this.state;
        return (
            <div className="container" >
                <div className='row justify-content-center'>
                    <div className="title">
                        <img src="/logo/apple-icon-60x60.png" width='60' height='60' alt='' />
                        <h1 text-align="center">GetSet.Fit</h1>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className="col-sm-4">
                        <div className="card">
                            <article className="card-body">
                                <h4 className="card-title text-center mb-4 mt-1">Reset Password</h4>
                                <hr />
                                {
                                    error
                                        ? <p className="text-danger text-center">{error}</p>
                                        : <p className="text-center">Please enter your Email or Phone</p>
                                }
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"> <i className="fa fa-user"></i> </span>
                                        </div>
                                        <input name="emailorphone" className="form-control" placeholder="Email or Phone" value={emailorphone} autoCapitalize="off" onChange={(e) => this.setState({ emailorphone: e.target.value })} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleNext} disabled={isNextBtnDisable}>Next</button>
                                </div>
                                <p className="text-center">
                                    <button type="button" className="btn btn-link" onClick={this.handleLogin}>Back to login</button>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ResetPassword);