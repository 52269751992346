import * as React from 'react';
import { Server } from '../../common/Server';
import { getApiError } from '../../common/HelperMethods';
import Loader from '../common/Loader';
import { RouteComponentProps, withRouter } from 'react-router';
import { models } from '../../models';
import Moment from 'react-moment';

interface IProps extends RouteComponentProps<any> {

    org_id: number
}

interface IState {
    packages: models.OrgPackage[]
    loading: boolean
    error: string | null
}
class OrgPackages extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            packages: [] as models.OrgPackage[],
            loading: true,
            error: null
        };
    }

    orgPackages = () => {
        this.setState({
            loading: false
        })
    }
    componentDidMount = () => {
        const { org_id } = this.props
        Server.get().getOrgPackages({ org_id })
            .then((packages) => {
                this.setState({
                    packages,
                    loading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })
    }

    onDelete = (package_id: number) => {
        const { org_id } = this.props;
        let { packages } = this.state;
        if (confirm('Are you sure you want to delete this package ?')) {

            Server.get().deleteOrgPackage({ org_id, package_id })
                .then(() => {

                    const index = packages.findIndex(x => x.id !== package_id)
                    packages = packages.splice(index, 1)
                    this.setState({ packages })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        loading: false
                    })
                })
        }
    }
    public render() {
        const { org_id } = this.props;
        const { error } = this.state;
        return (
            <div className='list'>
                <h2>Packages</h2>
                <button type="Button" className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/package/create')}><i className="fas fa-create-alt"></i>New Package</button>
                <Loader loading={this.state.loading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">From date</th>
                                <th scope="col">To date</th>
                                <th scope="col">Units</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Remaining units</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.packages.map((c, i) =>
                                <tr key={c.id}>
                                    <th scope="row">
                                        <Moment format="DD/MM/YYYY">
                                            {c.from_date}
                                        </Moment></th>
                                    <td>
                                        <Moment format="DD/MM/YYYY">
                                            {c.to_date}
                                        </Moment>
                                    </td>
                                    <td>{c.units}</td>
                                    <td>{c.amount}</td>
                                    <td>{c.units_remaining}</td>
                                    <td><button type='button' className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/package/update', { package: c })}><i className="fas fa-edit">Edit</i></button>
                                        <button type="button" className='btn btn-link' onClick={() => this.onDelete(c.id)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(OrgPackages);