import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getApiError } from '../common/HelperMethods';
import { FC_LOGIN, GSF_LOGIN, ORG_LOGIN } from '../common/Routes';
import { Server } from '../common/Server';
import { models } from '../models';


interface IProps extends RouteComponentProps<any> {
    user_type: 'end_user' | 'fc_user' | 'gsf_user' | 'org_user'
}


interface IState {
    password: string,
    error: string | null
    reEntrpassword: string
    issaveBtnDisable: boolean
    color: "sucess" | "error" | null
}

class NewPassword extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            password: '',
            reEntrpassword: '',
            error: null,
            color: null,
            issaveBtnDisable: false,
        };
    }

    handleSave = (event: any) => {

        event.preventDefault()

        const { password, reEntrpassword } = this.state;
        const { user_type } = this.props;
        const { otp, emailorphone } = this.props.history.location.state;
        const req = {} as models.ChangePasswordReq

        if (!password) {
            this.setState({ error: 'Enter Valid Password', color: 'error' })
            return
        } else if (password.length < 6) {
            this.setState({ error: 'The password must be minimum of 6 charactres long', color: 'error' })
        }
        else if (!reEntrpassword) {
            this.setState({ error: 'Re-enter the password', color: 'error' })
            return
        } else if (password != reEntrpassword) {
            this.setState({ error: 'Password Mis-MATCH', color: 'error' })
            return
        }
        this.setState({ issaveBtnDisable: true })
        if (emailorphone.indexOf('@') > 0) {
            req.email = emailorphone
        } else {
            req.phone = emailorphone
        }
        req.password = password
        req.code = otp
        Server.get().changePassword(req)
            .then((r) => {

                if (user_type === "fc_user") {
                    this.props.history.push(FC_LOGIN, { user_type: user_type })
                    return
                }
                if (user_type === "gsf_user") {
                    this.props.history.push(GSF_LOGIN, { user_type: user_type })
                    return
                }
                if (user_type === "org_user") {
                    this.props.history.push(ORG_LOGIN, { user_type: user_type })
                    return
                }
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    issaveBtnDisable: false,
                    color: "error"
                })
            })
    }

    render() {
        const { error, color, password, reEntrpassword, issaveBtnDisable } = this.state
        return (
            <div className="container" >
                <div className='row justify-content-center'>
                    <div className="title">
                        <img src="/logo/apple-icon-60x60.png" width='60' height='60' alt='' />
                        <h1 text-align="center">GetSet.Fit</h1>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className="col-sm-4">
                        <div className="card">
                            <article className="card-body">
                                <h4 className="card-title text-center mb-4 mt-1">Enter your new password</h4>
                                <hr />
                                {
                                    error
                                        ? <p className="text-danger text-center">{error}</p>
                                        : null
                                }
                                <div className="form-group">
                                    <label>Password</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"> <i className="fa fa-lock"></i> </span>
                                        </div>
                                        <input name="Password" type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => this.setState({
                                            password: e.target.value
                                        })} autoCapitalize="none" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Re-enter Password</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"> <i className="fa fa-lock"></i> </span>
                                        </div>
                                        <input name="Re-enter Password" type="password" className="form-control" placeholder="Re-enter Password" value={reEntrpassword} onChange={(e) => this.setState({ reEntrpassword: e.target.value })} autoCapitalize="none" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleSave} disabled={issaveBtnDisable}>Save</button>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NewPassword)