import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Server } from '../../common/Server';
import { getApiError } from '../../common/HelperMethods';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    org_id: number
}
interface IState {
    orgDetails: models.OrgDetails
    error: string | null
    loading: boolean
}
class OrgDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            orgDetails: {} as models.OrgDetails,
            error: null,
            loading: true,
        }
    }

    orgDetails = () => {
        this.setState({
            loading: true
        })
    }


    componentDidMount = () => {
        const { org_id } = this.props;

        Server.get().getOrgDetails({ org_id })
            .then((orgDetails) => {
                this.setState({
                    orgDetails: orgDetails,
                    loading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })

            })
    }
    public render() {
        const { org_id } = this.props;
        const { orgDetails, error } = this.state;
        return (
            <div className='details'>
                <Loader loading={this.state.loading}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <div className="align-content:center">
                                <h1> Organization Details</h1>
                            </div>
                        </ol>
                    </nav>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <div className='details-head'>
                        <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/org/' + org_id + '/details/update', { orgDetails: orgDetails })}><i className="fas fa-edit"></i>Edit</button>
                    </div>
                    <div className='details-main'>
                        <div>Name : {orgDetails.name}</div>
                        <div>Address: {orgDetails.address}</div>
                        <div>Manager name : {orgDetails.manager_name}</div>
                        <div>Manager phone: {orgDetails.manager_phone}</div>
                        <div>Manager Email : {orgDetails.manager_email}</div>
                        <div>Hr name: {orgDetails.hr_name}</div>
                        <div>Hr phone: {orgDetails.hr_phone}</div>
                        <div>Hr Email : {orgDetails.hr_email}</div>
                        <div>Minimum unit: {orgDetails.min_unit}</div>
                        <div>Maximum unit : {orgDetails.max_unit}</div>
                        <div>Session limit : {orgDetails.sessions_limit}</div>
                        <div>Limit type:{orgDetails.limit_type}</div>
                        <div>Status:{orgDetails.status == 0 ? 'In-progress' : (orgDetails.status == 1 ? 'Active' : 'In-active')}</div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(OrgDetails);
