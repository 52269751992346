import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import GSFArea from '../components/gsf/GSFArea';
import GSFConsumers from '../components/gsf/GSFConsumers';
import GSFCreateArea from '../components/gsf/GSFCreateArea';
import GSFCreateEquipment from '../components/gsf/GSFCreateEquipments';
import GSFCreateFacilite from '../components/gsf/GSFCreateFacilities';
import GSFCreateFitnessCenter from '../components/gsf/GSFCreateFitnessCenter';
import GSFCreateOrganization from '../components/gsf/GSFCreateOrganization';
import GSFCreateUser from '../components/gsf/GSFCreateUser';
import GsfDashBoard from '../components/gsf/GSFDashboard';
import GSFEquipments from '../components/gsf/GSFEquipments';
import GSFFacilities from '../components/gsf/GSFFacilities';
import GSFFitnessCenters from '../components/gsf/GSFFitnessCenters';
import GSFOrganizations from '../components/gsf/GSFOrganizations';
import GSFSidebar from '../components/gsf/GSFSidebar';
import GSFUpdateUser from '../components/gsf/GSFUpdateUser';
import GSFUsers from '../components/gsf/GSFUsers';
import { Server } from '../common/Server';
import GSFMessageScreen from '../components/gsf/GSFMessageScreen';
import GSFAddTermsAndConditions from '../components/gsf/GSFAddTermsAndConditions';
import GSFCreatePlan from '../components/gsf/GSFCreatePlan';
import GSFUpdatePlan from '../components/gsf/GSFUpdatePlan';
import GSFPlans from '../components/gsf/GSFPlans';
import GSFActivatePlan from '../components/gsf/GSFActivatePlan';
import GSFPaymentReport from '../components/gsf/GSFPaymentReport';

interface IRouteProps {
    user_id: string
}
interface IProps extends RouteComponentProps<IRouteProps> {

}

interface IState {

}

class GsfHome extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

    }
    componentDidMount = () => {
        Server.get().userToken().then(token => {
            console.log('fc home token', token)
            if (!token) {
                this.props.history.replace('')
            }
        })
    }

    render() {
        const user_id: number = parseInt(this.props.match.params.user_id);
        console.log('user id', user_id);
        return (
            <div className="wrapper">
                <GSFSidebar user_id={user_id}></GSFSidebar>
                <div className="content">
                    <Switch>
                        <Route exact={true} path={'/gsf/dashboard'} render={(props) => <GsfDashBoard {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/fitnesscenter'} render={(props) => <GSFFitnessCenters {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/organization'} render={(props) => <GSFOrganizations {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/users'} render={(props) => <GSFUsers {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/equipment'} render={(props) => <GSFEquipments {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/facilitie'} render={(props) => <GSFFacilities {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/report/payments'} render={(props) => <GSFPaymentReport {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/area'} render={(props) => <GSFArea {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/consumer'} render={(props) => <GSFConsumers {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/add/terms&conditions'} render={(props) => <GSFAddTermsAndConditions {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/users/update'} render={(props) => <GSFUpdateUser {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/users/create'} render={(props) => <GSFCreateUser {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/fitnesscenter/create'} render={(props) => <GSFCreateFitnessCenter {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/organization/create'} render={(props) => <GSFCreateOrganization {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/equipment/create'} render={(props) => <GSFCreateEquipment {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/facilitie/create'} render={(props) => < GSFCreateFacilite {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/area/create'} render={(props) => < GSFCreateArea {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/create/message'} render={(props) => < GSFMessageScreen {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/create/plan'} render={(props) => < GSFCreatePlan {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/plan/update'} render={(props) => < GSFUpdatePlan  {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/plans'} render={(props) => < GSFPlans  {...props} user_id={user_id} />} />
                        <Route exact={true} path={'/gsf/activate/plan'} render={(props) => < GSFActivatePlan  {...props} user_id={user_id} />} />

                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(GsfHome);