import * as React from 'react';


interface IProps {
    value?: number
    onChange: (e: any) => void
    disabled?: boolean
    name?: string
}

interface IState {

}

class Time extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {

        };
    }

    onChange = () => {

    }

    render() {
        const times = ['0:00 AM', '0:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM',
            '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM',
            '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
            '0:00 PM', '0:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
            '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM',
            '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM',]
        return (
            <div>
                <select onChange={this.props.onChange ? (e) => this.props.onChange(e) : undefined}
                    disabled={this.props.disabled}
                    value={this.props.value}
                    name={this.props.name}
                    className="form-control"
                >
                    {times.map((m, i) =>
                        <option value={i} key={i}>{m}</option>
                    )}
                </select>
            </div >
        );
    }
}

export default Time;