import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import Loader from '../common/Loader';



interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    loading: boolean
    isDisableSubmit: boolean
    name: string
    error: string | null
    color: "success" | "error" | null
}
class GSFMasterFacility extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            isDisableSubmit: false,
            name: '',
            error: null,
            color: null
        };
    }

    getValidationErr = (name: string) => {
        let err = ''
        if (!name) {
            err = ''
        }
        return err
    }

    onSubmit = (event: any) => {
        event.preventDefault()

        const { name } = this.state;
        const err = this.getValidationErr(name)


        if (err) {
            this.setState({ error: err })
            return
        }


        Server.get().createGSFFacility({ name })
            .then((r) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error",
                    isDisableSubmit: false
                })
            })
    }
    render() {
        const { name, isDisableSubmit, error } = this.state;
        return (
            <div className='form'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Add Facility</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }
                                        <div className='form-group'>

                                            <input type='text' className="form-control" name="details" placeholder='Enter Facility' value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </div>
                                        <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(GSFMasterFacility);
