import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    isDisableSubmit: boolean
    isUpiId: boolean
    account_name: string
    account_no: string
    ifsc: string
    upi: string
    error: string | null
    color: "error" | "success" | null
    type: "bank" | 'upi'
    bank: string
    active: boolean
    isLoading: boolean
}
class FCCreateBillingMethod extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: true,
            isDisableSubmit: false,
            isUpiId: false,
            account_name: '',
            account_no: '',
            ifsc: '',
            upi: '',
            error: null,
            color: null,
            type: "bank",
            bank: '',
            active: false
        };
        this.handleChange = this.handleChange.bind(this)
    }


    handleChange = () => {
        const { active } = this.state
        this.setState({
            active: !active
        })
    }

    getValidationErr = (account_name: string, account_no: string, ifsc: string, upi: string) => {
        let err = ''
        if (!account_name) {
            err = 'Enter  Name'
        } if (!account_no) {
            err = 'Enter account number'
        } if (!ifsc) {
            err = 'Enter IFSC code'
        }

        return err
    }

    onSubmit = (event: any) => {
        event.preventDefault()

        const { account_name, account_no, isDisableSubmit, ifsc, upi, error, type, active } = this.state;
        const err = this.getValidationErr(account_name, account_no, ifsc, upi)
        const { fc_id } = this.props

        if (err) {
            this.setState({ error: err, color: 'error' })
            return
        }

        this.setState({ isDisableSubmit: true })

        const req: models.FCCreateBillingMethodReq = type == "bank" ? {
            account_name,
            account_no,
            ifsc,
            fc_id,
            type, active
        } : {
                upi,
                fc_id,
                type,
                active
            }
        Server.get().createFCBillingMethod(req)
            .then((r) => {

                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isDisableSubmit: false,
                    color: "error"
                })
            })
    }

    render() {
        const { isDisableSubmit, isUpiId, bank, upi, error, color, account_name, account_no, ifsc, type, isLoading } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Add Billing Details</h1>
                        </div>
                    </ol>
                </nav>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-sm-5'>
                            <div className='base-form'>
                                <form>
                                    {
                                        error
                                            ? <span style={{ color: 'red' }}>{error}</span>
                                            : null
                                    }
                                    <label className='radio-inline'>
                                        <input type="radio" name="optradio" value={bank} checked onChange={(e) => this.setState({ bank: e.target.value })} onClick={() => this.setState({ isUpiId: false })} />
                                        <span>Bank account</span>
                                    </label>

                                    <label className='radio-inline'>
                                        <input type="radio" name="optradio" value={upi} onChange={(e) => this.setState({ upi: e.target.value })} onClick={() => this.setState({ isUpiId: true })} />
                                        <span>UPI</span>
                                    </label>
                                    {
                                        isUpiId
                                            ?

                                            <React.Fragment>
                                                <div className="form-group">
                                                    <label>UPI</label>
                                                    <input type='upi' className="form-control " name="Upi id" placeholder='Upi id' value={upi} onChange={(e) => this.setState({ upi: e.target.value })} />
                                                </div>
                                            </React.Fragment>

                                            : <React.Fragment>< div className='form-group'>


                                                <label>Account holder name</label>
                                                <input name="account holder name" className="form-control " placeholder='Account holder name' value={account_name} onChange={(e) => this.setState({ account_name: e.target.value })} />

                                            </ div>
                                                <div className='form-group'>

                                                    <label>Account number</label>
                                                    <input type='name' className="form-control " name="account no." placeholder='Account no.' value={account_no} onChange={(e) => this.setState({ account_no: e.target.value })} />

                                                </div>
                                                <div className='form-group'>

                                                    <label>IFSC Code</label>
                                                    <input type='name' className="form-control " name="ifsc code" placeholder='Ifsc code' value={ifsc} onChange={(e) => this.setState({ ifsc: e.target.value })} />

                                                </div>
                                            </React.Fragment>
                                    }

                                    <div>
                                        <input type="checkbox" name="active" onChange={this.handleChange} />Active
                    </div>
                                    <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        );
    }
}

export default FCCreateBillingMethod;
