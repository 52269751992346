import { Field, Form, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Server } from '../../common/Server';
import { models } from '../../models';
import * as Yup from 'yup';
import Error from '../common/Error';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    data: models.FCUpdateContact
    loading: boolean
    error: string | null
    isDisableSubmit: boolean
}
class FCUpdateContacts extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const contact = this.props.history.location.state.contact as models.FCUpdateContact

        this.state = {

            data: {
                name: contact.name || '',
                email: contact.email || '',
                fc_id: this.props.fc_id,
                id: contact.id,
                main: contact.main,
                phone: contact.phone || '',
                role: contact.role || 'Admin'
            },
            loading: false,
            error: null,
            isDisableSubmit: false
        };
    }
    schema = () => {
        return Yup.object().shape({
            name: Yup.string().label('Name').required(),
            email: Yup.string().label('Email').email().when('phone', {
                is: phone => phone == phone,
                then: Yup.string(),
                otherwise: Yup.string().required()
            }),
            phone: Yup.string().label('phone').matches(/^\d{10}$/, 'Not a valid phone number').when('email', {
                is: email => email == email,
                then: Yup.string(),
                otherwise: Yup.string().required()
            })

        })
    }
    onSubmit = (values: models.FCUpdateContact, fa: FormikActions<any>) => {
        Server.get().updateFCContact(values)
            .then(() => {
                fa.setSubmitting(false)
                this.props.history.goBack()
            })
            .catch((e) => {
                fa.setErrors(e),
                    fa.setSubmitting(false)
            })
    }
    render() {
        const { data, error } = this.state;
        return (
            <Loader loading={this.state.loading}>
                <div className="form">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <div className="align-content:center">
                                <h1> Update Contacts</h1>
                            </div>
                        </ol>
                    </nav>
                    <div className='container' >
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    {
                                        error
                                            ? <span style={{ color: "red" }}>{error}</span>
                                            : null
                                    }
                                    <Formik initialValues={data} onSubmit={this.onSubmit} validationSchema={this.schema} validateOnBlur={true}
                                        render={({ isSubmitting, submitForm }) =>
                                            <Form>
                                                <div className='form-group'>
                                                    <label>Name</label>
                                                    <Field type='text' name="name" placeholder='Enter Name' className="form-control" />
                                                    <Error name="name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <Field type='email' className="form-control" name="email" placeholder='Enter Email' />
                                                    <Error name="email" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone</label>
                                                    <Field type='text' className="form-control" name="phone" placeholder='Enter Phone' />
                                                    <Error name="phone" />
                                                </div>
                                                <div>
                                                    <label>Role:</label>
                                                    <Field component="select" className="form-control" name="role">
                                                        <option value='Owner'>Owner</option>
                                                        <option value='Admin'>Admin</option>
                                                        <option value='Instructor'>Instructor</option>
                                                    </Field>
                                                    <Error name="role" />
                                                </div>
                                                <div>
                                                    <Field component="input" type='checkbox' name="main" />
                                                    <Error name="main" />
                                                    <label>Main</label>
                                                </div>
                                                <div className="form-group" >
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                                </div>
                                            </Form>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default withRouter(FCUpdateContacts);
