import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { fitnessCenterImgUrl, getsetfitImgUrl } from '../common/HelperMethods';
import { FC_LOGIN, GSF_LOGIN, ORG_LOGIN } from '../common/Routes';


interface IProps extends RouteComponentProps<any> {

}

interface IState {
}

class StartPage extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }
    handleFCLogin = () => {
        try {
            if (sessionStorage) {
            }
        } catch (e) {
            alert('Your browser has cookies disabled . Make sure that your cookies are enabled and try again')
            return
        }
        this.props.history.push(FC_LOGIN)
    }


    handleORGLogin = () => {
        try {
            if (sessionStorage) {
            }
        } catch (e) {
            alert('Your browser has cookies disabled . Make sure that your cookies are enabled and try again')
            return
        }
        this.props.history.push(ORG_LOGIN)
    }


    handleGSFLogin = () => {
        try {
            if (sessionStorage) {
            }
        } catch (e) {
            alert('Your browser has cookies disabled . Make sure that your cookies are enabled and try again')
            return
        }
        this.props.history.push(GSF_LOGIN)
    }
    render() {
        return (
            <div className="container">
                <div className='row justify-content-center'>
                    <div className="title">
                        <img src="/logo/apple-icon-60x60.png" width='60' height='60' alt='' />
                        <h1 text-align="center">GetSet.Fit</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="card" >
                            <img src={fitnessCenterImgUrl} className="card-img-top" />
                            <div className="card-body">
                                <h4 className="card-title">Fitness Center</h4>
                                <p className="card-text"></p>
                                <button className="btn btn-primary btn-block" onClick={this.handleFCLogin}>Login</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm">
                        <div className="card" >
                            <img src={getsetfitImgUrl} className="card-img-top" />
                            <div className="card-body">
                                <h4 className="card-title">Organization</h4>
                                <p className="card-text"></p>
                                <button className="btn btn-primary btn-block" onClick={this.handleORGLogin}>Login</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm">
                        <div className="card" >
                            <img src={getsetfitImgUrl} className="card-img-top" />
                            <div className="card-body">
                                <h4 className="card-title">Getset.fit</h4>
                                <p className="card-text"></p>
                                <button className="btn btn-primary btn-block" onClick={this.handleGSFLogin}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(StartPage)