import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Server } from '../common/Server';
import FCBilling from '../components/fc/FCBilling';
import FCBills from '../components/fc/FCBills';
import FCCheckins from '../components/fc/FCCheckins';
import FCContacts from '../components/fc/FCContacts';
import FCCreateBillingMethod from '../components/fc/FCCreateBillingMethod';
import FCCreateBills from '../components/fc/FCCreateBills';
import FCCreateContact from '../components/fc/FCCreateContact';
import FCCreatePackage from '../components/fc/FCCreatePackage';
import FCCreatePayments from '../components/fc/FCCreatePayments';
import FCCreatePriceSetting from '../components/fc/FCCreatePriceSetting';
import FCCreateUser from '../components/fc/FCCreateUser';
import FcDashBoard from '../components/fc/FCDashboard';
import FCDetails from '../components/fc/FCDetails';
import FCEquipment from '../components/fc/FCEquipment';
import FCFacilities from '../components/fc/FCFacilities';
import FCHolidays from '../components/fc/FCHolidays';
import FCImages from '../components/fc/FCImages';
import FCPackages from '../components/fc/FCPackages';
import FCPackageSales from '../components/fc/FCPackageSales';
import FCPaymentDetails from '../components/fc/FCPaymentDetails';
import FCPayments from '../components/fc/FCPayments';
import FCPriceSettings from '../components/fc/FCPriceSettings';
import FCSidebar from '../components/fc/FCSidebar';
import FCSlots from '../components/fc/FCSlots';
import FCUpdateBilling from '../components/fc/FCUpdateBilling';
import FCUpdateBillingMethod from '../components/fc/FCUpdateBillingMethod';
import FCUpdateBills from '../components/fc/FCUpdateBills';
import FCUpdateContacts from '../components/fc/FCUpdateContacts';
import FCUpdateDetails from '../components/fc/FCUpdateDetails';
import FCUpdatePackage from '../components/fc/FCUpdatePackage';
import FCUpdatePayment from '../components/fc/FCUpdatePayment';
import FCUpdatePriceSetting from '../components/fc/FCUpdatePriceSetting';
import FCUpdateUser from '../components/fc/FCUpdateUser';
import FCUpdateUsp from '../components/fc/FCUpdateUsp';
import FCUploadLogo from '../components/fc/FCUploadLogo';
import FCUsers from '../components/fc/FCUsers';
import FCUsp from '../components/fc/FCUsp';



interface IRouteProps {
    fc_id: string
}

interface IProps extends RouteComponentProps<IRouteProps> {
}

interface IState {
}

class FcHome extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }

    componentDidMount = () => {
        Server.get().userToken().then(token => {
            console.log('fc home token', token)
            if (!token) {
                this.props.history.replace('')
            }
        })
    }

    render() {
        const fc_id: number = parseInt(this.props.match.params.fc_id);
        return (
            <div className="wrapper">
                <FCSidebar fc_id={fc_id}></FCSidebar>
                <div className="content">
                    <Switch>
                        <Route exact={true} path={'/fc/' + fc_id + '/billing'} render={(props) => <FCBilling {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={"/fc/" + fc_id + "/checkins"} render={(props) => <FCCheckins {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/contacts'} render={(props) => <FCContacts {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/dashboard'} render={(props) => <FcDashBoard {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/details'} render={(props) => <FCDetails {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/equipment'} render={(props) => <FCEquipment {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/facilities'} render={(props) => <FCFacilities {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/images'} render={(props) => <FCImages {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/packages'} render={(props) => <FCPackages {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/packagesales'} render={(props) => <FCPackageSales {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/paymentdetails'} render={(props) => <FCPaymentDetails {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/payments'} render={(props) => <FCPayments {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/slots'} render={(props) => <FCSlots {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/user'} render={(props) => <FCUsers {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/usp'} render={(props) => <FCUsp {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/sidebar'} render={(props) => <FCSidebar {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/bills'} render={(props) => <FCBills {...props} fc_id={fc_id} />} />

                        <Route exact={true} path={'/fc/' + fc_id + '/billing/update'} render={(props) => <FCUpdateBilling {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/billing/method/create'} render={(props) => <FCCreateBillingMethod {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/packages/create'} render={(props) => <FCCreatePackage {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/user/create'} render={(props) => <FCCreateUser {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/billing/method/update'} render={(props) => <FCUpdateBillingMethod {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/contacts/update'} render={(props) => <FCUpdateContacts {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/details/update'} render={(props) => <FCUpdateDetails {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/packages/update'} render={(props) => <FCUpdatePackage {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/user/update'} render={(props) => <FCUpdateUser {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/details/usp/update'} render={(props) => <FCUpdateUsp {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/payment/update'} render={(props) => <FCUpdatePayment {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/payment/create'} render={(props) => <FCCreatePayments {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/logo/upload'} render={(props) => <FCUploadLogo {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/bills/update'} render={(props) => <FCUpdateBills {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/bills/create'} render={(props) => <FCCreateBills {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/pricesettings'} render={(props) => <FCPriceSettings {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/pricesettings/create'} render={(props) => <FCCreatePriceSetting {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/contacts/create'} render={(props) => <FCCreateContact {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/pricesettings/update'} render={(props) => <FCUpdatePriceSetting {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/contacts/update'} render={(props) => <FCUpdateContacts {...props} fc_id={fc_id} />} />
                        <Route exact={true} path={'/fc/' + fc_id + '/holidays'} render={(props) => <FCHolidays {...props} fc_id={fc_id} />} />
                        +
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(FcHome)