import * as React from 'react';
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    pricesettings: models.FCPriceSetting[]
    isLoading: boolean
    error: string | null
    start_date: Date
}
class FCPriceSettings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            pricesettings: [] as models.FCPriceSetting[],
            isLoading: true,
            error: null,
            start_date: new Date()
        };
    }

    FCPriceSettings = () => {
        this.setState({
            isLoading: true
        })
    }

    onStart_dateChange = (date: Date) => {
        date.setHours(0)
        date.setMinutes(0)
        console.log("start_date", date)
        this.setState({
            start_date: date
        }, () => {

            this.componentDidMount()
        })
    }
    componentDidMount = () => {
        const { fc_id } = this.props
        const { start_date } = this.state
        Server.get().getFCPriceSettings({ fc_id, start_date: start_date.getTime() })
            .then((pricesettings) => {
                this.setState({
                    pricesettings,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onDelete = (id: number) => {

        const { fc_id } = this.props
        let { pricesettings } = this.state
        const pricesetting_id = id

        if (confirm('Are you sure you want to delete this package?')) {

            Server.get().deleteFCPriceSetting({ fc_id, pricesetting_id })
                .then(() => {
                    let statePrice = this.state.pricesettings
                    const statePriceSetttings = statePrice.filter((price) => price.id !== pricesetting_id)
                    this.setState({ pricesettings: statePriceSetttings })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                    })
                })
        }
    }

    render() {
        const { fc_id } = this.props;
        const { error, isLoading } = this.state
        const times = ['0:00 AM', '0:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM',
            '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM',
            '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
            '0:00 PM', '0:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
            '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM',
            '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM',]
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Price settings for Hourly packages </h2>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/pricesettings/create')}><i className="fas fa-create-alt"  ></i>New Price setting</button>
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Start Date</th>
                                <th scope="col">Normal Price</th>
                                <th scope="col">Off Peak Pricing</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pricesettings.map((c, i) =>
                                <tr key={c.id}>
                                    <td>
                                        <Moment format="DD/MM/YYYY">
                                            {c.start_date}
                                        </Moment>
                                    </td>
                                    <td scope="row">{c.price}</td>
                                    <td scope="row">{c.off_peak_pricing ? `${c.off_peak_price} / ${times[c.off_peak_start]} to ${times[c.off_peak_end]}` : '-NA-'}</td>
                                    <td><button type='button' className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/pricesettings/update', { package: c })}><i className="fas fa-edit">Edit</i></button>
                                        <button type='button' className="btn btn-link" onClick={() => this.onDelete(c.id)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCPriceSettings);