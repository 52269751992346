import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getApiError, isValidEmail } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    loading: boolean
    isDisableSubmit: boolean
    name: string
    email: string
    phone: string
    password: string
    id: number
    role: "Admin" | "Sales" | "Support"
    error: string | null
    color: "error" | "success" | null
}
class GSFUpdateUser extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const gsfUser = this.props.history.location.state.user as models.GSFUser
        this.state = {
            loading: false,
            isDisableSubmit: false,
            name: gsfUser.name,
            email: gsfUser.email,
            phone: gsfUser.phone,
            password: '',
            role: gsfUser.role,
            id: gsfUser.id,
            error: null,
            color: null
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event: any) => {
        this.setState({
            role: event.target.value
        })
    }
    getValidationErr = (email: string, phone: string, password: string, name: string) => {
        let err = ''

        if (!email) {
            err = 'Enter email'
        }
        if (!name) {
            err = 'Enter Name'
        }
        else if (!password) {
            err = 'Enter password'
        } else if (password.length < 6) {
            err = 'Invalid user name or password '
        }
        else if (isValidEmail(email)) {
            err = 'Enter valid email id'
        }
        else if (!phone) {
            err = 'Enter phone'
        }
        else if (isNaN(Number.parseInt(phone))) {
            err = 'Invalid email or phone number'
        } else if (isNaN(Number.parseInt(phone))) {
            if (phone.length < 10) {
                err = 'Mobile number must be 10 digits long'
            }
        }
        return err
    }
    onSubmit = (event: any) => {

        event.preventDefault()
        const { email, phone, password, name, role, error, isDisableSubmit, id } = this.state
        const { user_id } = this.props
        const err = this.getValidationErr(email, phone, password, name)

        if (err) {
            this.setState({ error: err, color: "error" })
            return
        }

        this.setState({ isDisableSubmit: true })

        Server.get().updateGSFUser({ user_id, email, role, name, phone, id })
            .then((r) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error",
                    isDisableSubmit: false
                })
            })
    }
    render() {
        const { email, phone, password, name, error, isDisableSubmit, color, role } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update User</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }

                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type='name' className="form-control" name="name" placeholder='Name' autoCapitalize="off" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type='email' className="form-control" name="email" placeholder='Email' autoCapitalize="off" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type='text' className="form-control" name="phone" placeholder='Phone' value={phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type='password' className="form-control" name="password" placeholder='Password' autoCapitalize="off" value={password} onChange={(e) => this.setState({ password: e.target.value })} />
                                        </div>
                                        <div>
                                            <select onChange={this.handleChange} value={role} className="form-control">
                                                <label>Role:</label>
                                                <option value='Sales'>Sales</option>
                                                <option value='Admin'>Admin</option>
                                                <option value='Support'>Support</option>
                                            </select>
                                        </div>
                                        <div>
                                            <button color='primary' className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default GSFUpdateUser;
