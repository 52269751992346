import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Time from '../common/Time';
import Loader from '../common/Loader';


const DAYS = [{ day: 0, name: 'Monday' }, { day: 1, name: 'Tuesday' }, { day: 2, name: 'Wednesday' },
{ day: 3, name: 'Thursday' }, { day: 4, name: 'Friday' }, { day: 5, name: 'Saturday' }, { day: 6, name: 'Sunday' }]

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}

interface IState {
    slots: models.SlotSettings
    error: string | null
    isLoading: boolean
    color: "error" | null
    visibleSlots: number[]
    editMode: boolean
    day: number
    start_time: number
    close_time: number
    break_start: number
    break_end: number
}

class FCSlots extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            slots: [] as models.SlotSettings,
            error: null,
            isLoading: true,
            color: null,
            visibleSlots: [] as number[],
            editMode: false,
            day: 0,
            start_time: 0,
            close_time: 0,
            break_start: 0,
            break_end: 0,
        };
    }
    onChangeDay = (day: number) => {
        this.setState({
            day
        }, () => {
            this.updateVisibleSlots()
        })
    }
    onChangeSameAsDay = (value: number) => {
        const { slots, day } = this.state;
        slots[day].sameAsValue = value
        this.updateDataToSame(slots, day, value);
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }
    updateDataToSame(slots: models.DaySlotSetting[], day: number, copyFrom: number) {

        if (copyFrom == -1) {
            return
        }
        slots[day].start_time = slots[copyFrom].start_time
        slots[day].close_time = slots[copyFrom].close_time
        slots[day].break_start = slots[copyFrom].break_start
        slots[day].break_end = slots[copyFrom].break_end
        for (let x = 0; x < 48; x++) {
            slots[day].slots[x].open = slots[copyFrom].slots[x].open
            slots[day].slots[x].sessions = slots[copyFrom].slots[x].sessions
        }

    }
    onChangeExclusive = (exclusive: models.DaySlotSetting['exclusive']) => {
        const { slots, day } = this.state;
        slots[day].exclusive = exclusive
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.exclusive = slots[day].exclusive
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }

    handleOnChangeExclusiveStart = (exclusive_start: number) => {
        const { slots, day } = this.state;
        slots[day].exclusive_start = exclusive_start
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.exclusive_start = slots[day].exclusive_start
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }
    handleOnChangeExclusiveEnd = (exclusive_end: number) => {
        const { slots, day } = this.state;
        slots[day].exclusive_end = exclusive_end
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.exclusive_end = slots[day].exclusive_end
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }
    onChangeSameAs = (same: boolean) => {
        const { slots, day } = this.state;
        slots[day].sameAs = same
        slots[day].sameAsValue = same ? 0 : -1
        this.updateDataToSame(slots, day, same ? 0 : -1);
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }

    fcOnChangeSlots = (slot: number, sessions: number) => {
        const { slots, day } = this.state;
        slots[day].slots[slot].sessions = sessions
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.slots[slot].sessions = sessions
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }

    fcOnChangeOpen = (slot: number, checked: boolean) => {
        const { slots, day } = this.state;
        slots[day].slots[slot].open = checked
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.slots[slot].open = checked
        })
        this.setState({
            slots
        })
    }



    handleOnChange_from_time = (event: any) => {
        const { slots, day } = this.state;
        slots[day].start_time = parseInt(event.target.value)
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.start_time = slots[day].start_time
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }

    handleOnChange_to_time = (event: any) => {
        const { slots, day } = this.state;
        slots[day].close_time = parseInt(event.target.value)
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.close_time = slots[day].close_time
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }

    handleOnChangeBreak1 = (event: any) => {
        const { slots, day } = this.state;
        slots[day].break_start = parseInt(event.target.value)
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.break_start = slots[day].break_start
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }

    handleOnChangeBreak2 = (event: any) => {
        const { slots, day } = this.state;
        slots[day].break_end = parseInt(event.target.value)
        const daysSimilar = slots.filter((v, i) => v.sameAs && v.sameAsValue == day)
        daysSimilar.forEach((v) => {
            v.break_end = slots[day].break_end
        })
        this.setState({
            slots
        }, () => {
            this.updateVisibleSlots()
        })
    }


    updateVisibleSlots = () => {
        const { slots, day } = this.state;
        const visibleSlots = [] as number[]
        for (let n = slots[day].start_time; n < slots[day].close_time; n++) {
            if (n < slots[day].break_start || n >= slots[day].break_end) {
                visibleSlots.push(n)
            }
        }

        this.setState({
            visibleSlots,
            start_time: slots[day].start_time,
            close_time: slots[day].close_time,
            break_start: slots[day].break_start,
            break_end: slots[day].break_end
        })
    }

    componentDidMount = () => {
        const { fc_id } = this.props;
        Server.get().getFCSlots({ fc_id })
            .then((slots) => {
                this.setState({
                    slots: slots,
                    isLoading: false
                }, () => {
                    this.updateVisibleSlots()
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error"
                })
            })
    }

    onSubmit = () => {
        const { fc_id } = this.props;
        const { slots } = this.state;
        for (let n = 0; n < 48; n++) {
            for (let x = 0; x < 7; x++) {
                if (n < slots[x].start_time || n >= slots[x].close_time || (n >= slots[x].break_start && n < slots[x].break_end)) {
                    slots[x].slots[n].open = false
                }
            }
        }

        Server.get().updateFCSlots({ fc_id, slots })
            .then(() => {
                alert('Slot details saved successfully')
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }

    public render() {
        const { visibleSlots, slots, day, start_time, break_end, close_time, break_start, error, isLoading } = this.state;
        const times = ['0:00 AM', '0:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM',
            '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM',
            '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
            '0:00 PM', '0:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
            '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM',
            '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM',]

        const activeDay = (n: number) => 'nav-link' + (n == day ? ' active' : '')
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Slots</h2>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-primary " onClick={this.onSubmit}>Submit</button>
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <p>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a href="#monday" className={activeDay(0)} onClick={() => this.onChangeDay(0)} >Monday</a>
                            </li>
                            <li className="nav-item">
                                <a href="#tuesday" className={activeDay(1)} onClick={() => this.onChangeDay(1)}>Tuesday</a>
                            </li>
                            <li className="nav-item">
                                <a href="#wednesday" className={activeDay(2)} onClick={() => this.onChangeDay(2)}>Wednesday</a>
                            </li>
                            <li className="nav-item">
                                <a href="#thursday" className={activeDay(3)} onClick={() => this.onChangeDay(3)}>Thursday</a>
                            </li>
                            <li className="nav-item">
                                <a href="#friday" className={activeDay(4)} onClick={() => this.onChangeDay(4)}>Friday</a>
                            </li>
                            <li className="nav-item">
                                <a href="#saturday" className={activeDay(5)} onClick={() => this.onChangeDay(5)}>Saturday</a>
                            </li>
                            <li className="nav-item">
                                <a href="#sunday" className={activeDay(6)} onClick={() => this.onChangeDay(6)}>Sunday</a>
                            </li>
                        </ul>
                    </p>
                    <p>
                        <div className="form-inline">
                            <div className="form-group">
                                <label>From:</label>
                                <Time

                                    onChange={this.handleOnChange_from_time}
                                    value={start_time}
                                    disabled={slots[day] && slots[day].sameAs}

                                />


                                <label>To:</label>
                                <Time
                                    onChange={this.handleOnChange_to_time}
                                    value={close_time}
                                    disabled={slots[day] && slots[day].sameAs}
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    <input type="checkbox" name="active"
                                        checked={slots[day] && slots[day].sameAs}
                                        onChange={(e) => this.onChangeSameAs(e.target.checked)} />Same as</label>
                                <select
                                    onChange={(e) => this.onChangeSameAsDay(parseInt(e.target.value))}
                                    value={slots[day] && slots[day].sameAsValue}
                                    disabled={slots[day] && !slots[day].sameAs} className="form-control"
                                >
                                    {DAYS.filter(v => v.day !== day).map(v =>
                                        <option value={v.day} key={v.day}>{v.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Break:</label>
                                <Time
                                    onChange={this.handleOnChangeBreak1}
                                    value={break_start}
                                    disabled={slots[day] && slots[day].sameAs}
                                />
                                <label>-</label>
                                <Time
                                    onChange={this.handleOnChangeBreak2}
                                    value={break_end}
                                    disabled={slots[day] && slots[day].sameAs}
                                />
                            </div>
                            <div className="form-group">
                                <label>Exclusive:</label>
                                <select
                                    onChange={(e) => this.onChangeExclusive(e.target.value as any)}
                                    value={slots[day] && slots[day].exclusive}
                                    disabled={slots[day] && slots[day].sameAs} className="form-control"
                                >
                                    <option value='-NA-'>-NA-</option>
                                    <option value='Men'>Men</option>
                                    <option value='Women'>Women</option>
                                </select>
                            </div>
                            {slots[day] && ((slots[day].exclusive == 'Men') || (slots[day].exclusive == 'Women')) ?
                                <div className="form-group">
                                    <label>From:</label>
                                    <Time
                                        onChange={(e) => this.handleOnChangeExclusiveStart(e.target.value)}
                                        value={slots[day] ? slots[day].exclusive_start : 0}
                                        disabled={slots[day] && slots[day].sameAs}
                                    />
                                    <label>To</label>
                                    <Time
                                        onChange={(e) => this.handleOnChangeExclusiveEnd(e.target.value)}
                                        value={slots[day] ? slots[day].exclusive_end : 0}
                                        disabled={slots[day] && slots[day].sameAs}
                                    />
                                </div> : null}
                        </div>
                    </p>

                    <table className="slots">
                        <thead className="bg-light">
                            <tr>
                                <th scope='col'>Time</th>
                                <th scope='col'>Open</th>
                                <th scope='col'>Slot</th>

                            </tr>
                        </thead>
                        <tbody>
                            {visibleSlots && visibleSlots.map((m, i) =>
                                <tr key={m}>
                                    <td scope="row">{times[m]}</td>
                                    <td>
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                name="active"
                                                onChange={(e) => this.fcOnChangeOpen(m, e.target.checked)}
                                                checked={slots[day].slots[m].open}
                                                disabled={slots[day].sameAs}

                                            /></div>

                                    </td>
                                    <td>
                                        <input
                                            name="sessions"
                                            onChange={(e) => this.fcOnChangeSlots(m, parseInt(e.target.value))}
                                            value={slots[day].slots[m].sessions}
                                            disabled={slots[day].sameAs || !slots[day].slots[m].open}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div >
        );
    }
}

export default withRouter(FCSlots);
