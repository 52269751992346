import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import RichTextEditor, { EditorValue } from 'react-rte';
import { Server } from '../../common/Server';
import { getApiError } from '../../common/HelperMethods';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    error: string | null
    tc: EditorValue
}

class GSFAddTermsConditions extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            error: null,
            tc: RichTextEditor.createEmptyValue()
        }
    }

    componentDidMount = () => {
        Server.get().getAgreement({})
            .then((c) => {
                this.setState({
                    tc: RichTextEditor.createValueFromString(c.agreements, 'markdown')
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }

    onSubmit = (event: any) => {
        event.preventDefault();
        const { tc } = this.state
        const agreements = tc.toString('markdown')
        Server.get().updateAgreement({ agreements })
            .then((c) => {
                alert('Terms & Condition updated successfully');
                this.props.history.goBack();
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }

    onChange = (value: EditorValue) => {
        console.log('value on change', value.toString('markdown'))
        this.setState({
            tc: value
        })
    }

    render() {
        const { error, tc } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update Terms and Conditions</h2>
                        </div>
                    </ol>
                </nav>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            {
                                error
                                    ? <span style={{ color: 'red' }}>{error}</span>
                                    : null
                            }
                            <form>
                                <div className='rich-text'>
                                    <RichTextEditor
                                        value={tc}
                                        onChange={this.onChange}
                                        rootStyle={{ innerHeight: 100, innerWidth: 100 }}
                                        editorStyle={{ innerHeight: 100, innerWidth: 100 }}
                                        placeholder='Enter Terms and Conditions'
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary btn-block" onClick={this.onSubmit}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(GSFAddTermsConditions);