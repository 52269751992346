import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    contacts: models.FCContact[]
    isLoading: boolean
    error: string | null
}
class FCContacts extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            contacts: [] as models.FCContact[],
            isLoading: true,
            error: null
        }

    }
    componentDidMount() {
        Server.get().getFCContacts({ fc_id: this.props.fc_id }).then((contacts) => {
            this.setState({
                contacts,
                isLoading: false
            })
        }).catch((e) => {
            this.setState({
                error: getApiError(e),
                isLoading: false
            })
        })
    }
    onDelete = (id: number) => {

        const { fc_id } = this.props
        const contact_id = id
        if (confirm('Are you sure you want to delete this contact?')) {

            Server.get().deleteFCUpdateContact({ fc_id, contact_id })
                .then(() => {
                    let stateContact = this.state.contacts
                    stateContact = stateContact.filter((cntct) => cntct.id !== id)
                    this.setState({ contacts: stateContact })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e)

                    })
                })
        }
    }
    onSearch = (str: string) => {
        const { contacts } = this.state;
        const filteredContacts = contacts.filter((contact) => {
            const lowerStr = str.toLowerCase()
            return contact.name.toLowerCase().indexOf(lowerStr) >= 0 ||
                contact.email.toLowerCase().indexOf(lowerStr) >= 0 ||
                contact.phone.toLowerCase().indexOf(lowerStr) >= 0 ||
                contact.role.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            contacts: filteredContacts
        })
    }
    public render() {
        const { fc_id } = this.props;
        const { error, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Contacts</h1>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/contacts/create')}><i className="fas fa-create-alt"  ></i>New contact</button>
                <div className='form-group'>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                {
                    <Loader loading={this.state.isLoading}>
                        {
                            error
                                ? <span style={{ color: 'red' }}>{error}</span>
                                : null
                        }
                        <table className="table">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.contacts.map((c, i) =>
                                    <tr key={c.id}>
                                        <th scope="row">{c.name}</th>
                                        <td>{c.email}</td>
                                        <td>{c.phone}</td>
                                        <td>{c.role}</td>
                                        <td><button type='button' className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/contacts/update', { contact: c })}><i className="fas fa-edit" >Edit</i></button>
                                            <button type='button' className="btn btn-link" onClick={(e) => this.onDelete(c.id)}><i className="fas fa-trash-alt" >Delete</i></button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Loader>
                }
            </div>
        );
    }
}

export default withRouter(FCContacts);
