import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    org_id: number
}

interface IState {
    loading: boolean
    isDisableSubmit: boolean
    email: string,
    sessions_limit: number
    limit_type: string
    min_unit: number
    max_unit: number
    error: string | null
    color: 'error' | 'success' | null
}
class OrgUpdateEmployee extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const employee = this.props.history.location.state.employee as models.OrgEmployee
        this.state = ({
            loading: false,
            isDisableSubmit: false,
            email: employee.email,
            sessions_limit: employee.sessions_limit,
            limit_type: employee.limit_type || 'week',
            min_unit: employee.min_unit,
            max_unit: employee.max_unit,
            error: null,
            color: null
        });
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange = (e: any) => {
        this.setState({
            limit_type: e.target.value
        })
    }
    getValidationErr = (email: string, sessions_limit: number, min_unit: number, max_unit: number) => {
        let err = ""

        if (!email) {
            err = 'Enter employee name'
        }
        return err
    }
    onSubmit = (event: any) => {
        event.preventDefault()

        const { email, sessions_limit, min_unit, max_unit, error, isDisableSubmit, limit_type } = this.state
        const err = this.getValidationErr(email, sessions_limit, min_unit, max_unit)
        const { org_id } = this.props

        if (err) {
            this.setState({ error: err, color: "error" })
            return
        }

        this.setState({ isDisableSubmit: true })

        Server.get().updateOrgEmployee({ email, max_unit, min_unit, sessions_limit, limit_type, org_id })
            .then((r: any) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error",
                    isDisableSubmit: false
                })
            })
    }
    render() {
        const { email, sessions_limit: sessions_limit, min_unit, max_unit, error, limit_type } = this.state
        const { isDisableSubmit } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update employee</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }
                                        <div className='form-group'>
                                            <label>Email</label>
                                            <input type='email' className="form-control" name="email" placeholder='Employee email' value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Session Limit</label>
                                            <input type='number' className="form-control" name="limit" placeholder='Session limit' value={sessions_limit} onChange={(e) => this.setState({ sessions_limit: e.target.valueAsNumber })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Minimum unit</label>
                                            <input type='number' className="form-control" name="limit" placeholder='min. unit' value={min_unit} onChange={(e) => this.setState({ min_unit: parseFloat(e.target.value) })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Maximum unit</label>
                                            <input type='number' className="form-control" name="limit" placeholder='max. unit' value={max_unit} onChange={(e) => this.setState({ max_unit: parseFloat(e.target.value) })} />
                                        </div>
                                        <div className="form-group">
                                            <label>Limit Type:</label>
                                            <select onChange={this.handleOnChange} value={limit_type} className="form-control">
                                                <option value='week'>Week</option>
                                                <option value='month'>Month</option>
                                            </select>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(OrgUpdateEmployee);
