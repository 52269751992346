import { Field, Form, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Error from '../common/Error';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    data: models.FCCreateUser
    color: 'success' | 'error' | null
    isdisableSubmit: boolean
    error: string | null
    isLoading: boolean
}
class FCCreateUser extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            data: {
                email: '',
                fc_id: this.props.fc_id,
                name: '',
                password: '',
                phone: '',
                role: 'Owner',
                saveAsContact: false
            },
            color: null,
            isLoading: true,
            isdisableSubmit: false,
            error: null
        };
    }
    schema = () => {
        return Yup.object().shape({
            name: Yup.string().label('Name').required(),
            password: Yup.string().label('Password').required(),
            saveAsContact: Yup.boolean().label('Save As contact').required(),
            email: Yup.string().label('Email').email().when('phone', {
                is: phone => phone,
                then: Yup.string(),
                otherwise: Yup.string().required()
            }),
            phone: Yup.string().label('phone').matches(/^\d{10}$/, 'Not a valid phone number').when('email', {
                is: email => email,
                then: Yup.string(),
                otherwise: Yup.string().required()
            })
        }, [['email', 'phone']])

    }
    onSubmit = (values: models.FCCreateUser, fa: FormikActions<any>) => {
        Server.get().createFCUser(values)
            .then((r) => {
                fa.setSubmitting(false)
                this.props.history.goBack()
            })
            .catch((e: any) => {
                fa.setErrors(e)
                fa.setSubmitting(false)
            })
    }
    render() {
        const { data, error } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1>Create User</h1>
                        </div>
                    </ol>
                </nav>
                <div className='container' >
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="base-form">
                                {
                                    error
                                        ? <span style={{ color: 'red' }}>{error}</span>
                                        : null
                                }
                                <Formik initialValues={data} onSubmit={this.onSubmit} validationSchema={this.schema} validateOnBlur={true}
                                    render={({ isSubmitting, submitForm }) =>
                                        <Form>
                                            <div className='form-group'>
                                                <label>Name</label>
                                                <Field type='text' name="name" placeholder='Enter Name' className="form-control" />
                                                <Error name="name" />
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <Field type='email' className="form-control" name="email" placeholder='Enter Email' />
                                                <Error name="email" />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <Field type='text' className="form-control" name="phone" placeholder='Enter Phone' />
                                                <Error name="phone" />
                                            </div>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <Field type='text' className="form-control" name="password" placeholder='Enter Password' />
                                                <Error name="password" />
                                            </div>
                                            <div>
                                                <label>Role:</label>
                                                <Field component="select" className="form-control" name="role">
                                                    <option value='Owner'>Owner</option>
                                                    <option value='Admin'>Admin</option>
                                                    <option value='Instructor'>Instructor</option>
                                                </Field>
                                                <Error name="role" />
                                            </div>
                                            <div>
                                                <Field component="input" type='checkbox' name="saveAsContact" />
                                                <Error name="saveAsContact" />
                                                <label>Save as contact</label>
                                            </div>
                                            <div className="form-group" >
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                            </div>
                                        </Form>
                                    }
                                >
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FCCreateUser;
