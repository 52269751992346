import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import SearchField from "react-search-field";
import { Server } from "../../common/Server";
import Loader from '../common/Loader';
import { models } from '../../models';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}
interface IState {
    users: models.GSFUser[]
    filteredUsers: models.GSFUser[]
    loading: boolean
    error: string | null

}
class GSFUsers extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            users: [] as models.GSFUser[],
            filteredUsers: [] as models.GSFUser[],
            loading: true,
            error: null
        }

    }
    gsfUsers = () => {
        this.setState({
            loading: true
        })
    }

    componentDidMount = () => {

        Server.get().getGSFUsers({})
            .then((user) => {
                this.setState({
                    users: user,
                    filteredUsers: user,
                    loading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    loading: false
                })
            })
    }
    onDelete = (id: number) => {

        let { users } = this.state
        const user_id = id
        if (confirm('Are you sure you want to delete this user?')) {

            Server.get().deleteGSFUser({ user_id })
                .then(() => {
                    const index = users.findIndex(x => x.id === id)
                    users = users.splice(index, 1)
                    this.setState({ users })
                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        loading: false
                    })
                })
        }
    }

    onSearch = (input: string) => {
        const { filteredUsers } = this.state;

        const _filteredUsers = filteredUsers.filter((user) => {
            const lowerStr = input.toLowerCase()
            return user.name.toLowerCase().indexOf(lowerStr) >= 0 ||
                user.email.toLowerCase().indexOf(lowerStr) >= 0 ||
                user.phone.toLowerCase().indexOf(lowerStr) >= 0 ||
                user.role.toLowerCase().indexOf(lowerStr) >= 0
        })

        this.setState({
            filteredUsers: _filteredUsers,
            loading: false
        })
    }
    public render() {
        const { error } = this.state;
        return (
            <div className='list'>
                <h2>GetSetFit</h2>
                <div>
                    <SearchField
                        placeholder="Search..."
                        onChange={this.onSearch}
                        classNames="test-class"
                    />
                </div>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/users/create')} ><i className="fas fa-create-alt"></i>New User</button>
                <Loader loading={this.state.loading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Role</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredUsers.map((c, i) =>
                                <tr key={c.id}>
                                    <th scope="row">{c.name}</th>
                                    <td>{c.email}</td>
                                    <td>{c.phone}</td>
                                    <td>{c.role}</td>
                                    <td><button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/users/update', { user: c })}><i className="fas fa-edit" >Edit</i></button>
                                        <button type="button" className="btn btn-link" onClick={(e) => this.onDelete(c.id)}><i className="fas fa-trash-alt" >Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default GSFUsers;
