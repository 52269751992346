import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isLoading: boolean
    error: string | null
    month: number,
    year: number,
    details: models.GSFPaymentDetail[],
}
class GSFPaymentReport extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const now=new Date();
        const month=now.getMonth()+1;
        const year=now.getFullYear();

        this.state = {
            isLoading: true,
            error: null,
            details: [] as models.GSFPaymentDetail[],
            year,
            month,
        };
    }

    componentDidMount = () => {
        this.onDateChangeSearch();
    }
    onMonthChange = (event: any) => {
        event.preventDefault()
        this.setState({month:parseInt(event.target.value)},()=>this.onDateChangeSearch());
    }
    onYearChange = (event: any) => {
        event.preventDefault()
        this.setState({year:parseInt(event.target.value)},()=>this.onDateChangeSearch());
    }
    onDateChangeSearch = () => {
        const { year, month } = this.state;
        Server.get().getPaymentReport({ year, month })
            .then((details) => {
                this.setState({
                    details,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    public render() {
        const { details,month,year,isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Payments Report</h2>
                        </div>
                    </ol>
                </nav>
                <div className='form-group'>
                    <label>Payment for </label>
                    <select onChange={this.onMonthChange} value={month} className="form-control">
                        <option value='1'>January</option>
                        <option value='2'>February</option>
                        <option value='3'>March</option>
                        <option value='4'>April</option>
                        <option value='5'>May</option>
                        <option value='6'>June</option>
                        <option value='7'>July</option>
                        <option value='8'>August</option>
                        <option value='9'>September</option>
                        <option value='10'>Ocrober</option>
                        <option value='11'>November</option>
                        <option value='12'>December</option>
                    </select>
                    <select onChange={this.onYearChange} value={year} className="form-control">
                        <option value='2019'>2019</option>
                        <option value='2020'>2020</option>
                        <option value='2021'>2021</option>
                    </select>                    
                </div>
                <Loader loading={this.state.isLoading}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Total Checkins</th>
                                <th scope="col">Normal Checkins</th>
                                <th scope="col">Off Peak Checkins</th>
                                <th scope="col">Total Units</th>
                                <th scope="col">Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>

                            {details.map((c) =>
                                <tr key={c.id}>
                                    <td scope="row">
                                        {c.name}
                                    </td>
                                    <td>
                                        {c.total_checkins}
                                    </td>
                                    <td>
                                        {c.normal_checkins}
                                    </td>
                                    <td>
                                        {c.off_peak_checkins}
                                    </td>
                                    <td>
                                        {c.units}
                                    </td>
                                    <td>
                                        {c.amount}
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </Loader>
            </div>
        );
    }
}

export default withRouter(GSFPaymentReport);