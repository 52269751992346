import { Field, Form, Formik, FormikActions } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Server } from '../../common/Server';
import { models } from '../../models';
import * as Yup from 'yup';
import Error from '../common/Error';
import Time from '../common/Time';




interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    data: models.FCUpdatePriceSetting
}
class FCUpdatePriceSetting extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const fcpricesetting = this.props.history.location.state.package as models.FCPriceSetting
        this.state = {
            data: {
                fc_id: this.props.fc_id,
                price: fcpricesetting.price || 0,
                start_date: moment(fcpricesetting.start_date).format("YYYY-MM-DD"),
                id: fcpricesetting.id,
                off_peak_start: fcpricesetting.off_peak_start || 0,
                off_peak_end: fcpricesetting.off_peak_end || 0,
                off_peak_price: fcpricesetting.off_peak_price || 0,
                off_peak_pricing: fcpricesetting.off_peak_pricing || false
            }

        };
    }

    schema = () => {
        return Yup.object().shape({
            off_peak_pricing: Yup.boolean().label('Off peak pricing').required(),
            off_peak_start: Yup.number().label('Off peak start').when('off_peak_pricing', {
                is: off_peak_pricing => off_peak_pricing == off_peak_pricing,
                then: Yup.number().required(),
                otherwise: Yup.number()
            }),
            off_peak_end: Yup.number().label('Off peak end').when('off_peak_pricing', {
                is: off_peak_pricing => off_peak_pricing == off_peak_pricing,
                then: Yup.number().required(),
                otherwise: Yup.number()
            }),
            off_peak_price: Yup.number().label('Off peak price').when('off_peak_pricing', {
                is: off_peak_pricing => off_peak_pricing == off_peak_pricing,
                then: Yup.number().required(),
                otherwise: Yup.number()
            }),
            price: Yup.number().label('Price').required(),
            start_date: Yup.date().label('Start date').required()
        })
    }
    onSubmit = (values: models.FCUpdatePriceSetting, fa: FormikActions<any>) => {
        values.start_date = moment(values.start_date).hours(0).minutes(0).seconds(0).toDate().getTime()
        Server.get().updateFCPriceSetting(values)
            .then(() => {
                fa.setSubmitting(false)
                this.props.history.goBack()
            })
            .catch((e: any) => {
                fa.setErrors(e)
                fa.setSubmitting(false)
            })
    }
    render() {
        const { data } = this.state
        return (
            <div className='form'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update PriceSetting</h2>
                        </div>
                    </ol>
                </nav>
                <div className='container' >
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="base-form">
                                <Formik initialValues={data} onSubmit={this.onSubmit} validationSchema={this.schema} validateOnBlur={true}
                                    render={({ isSubmitting, submitForm, values, error, errors, handleChange }) =>
                                        <Form>
                                            {
                                                error
                                                    ? <span style={{ color: 'red' }}>{error}</span>
                                                    : null
                                            }
                                            <div className='form-group'>
                                                <label>Price</label>
                                                <Field type='text' name="price" placeholder='Enter Price' className="form-control" />
                                                <Error name="price" />
                                            </div>
                                            <div className='form-group'>
                                                <label>Start date :</label>
                                                <Field type='date' name="start_date" placeholder='choose Date' className="form-control" value={values.start_date} />
                                                <Error name="start_date" />
                                            </div>
                                            <div>
                                                <label>
                                                    <Field component="input" type='checkbox' name="off_peak_pricing" checked={values.off_peak_pricing} />
                                                    Off Peak Pricing
                                                    </label>

                                                <Error name="off_peak_pricing" />
                                            </div>
                                            {values.off_peak_pricing ?
                                                <div>
                                                    <div className='form-group'>
                                                        <label>Off Peak Start Time :</label>
                                                        <Time
                                                            onChange={handleChange}
                                                            value={values.off_peak_start}
                                                            name="off_peak_start"
                                                        />
                                                        <Error name="off_peak_start" />
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Off Peak End Time:</label>
                                                        <Time
                                                            onChange={handleChange}
                                                            value={values.off_peak_end}
                                                            name="off_peak_end"
                                                        />
                                                        <Error name="off_peak_end" />
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Off Peak Price</label>
                                                        <Field type='text' name="off_peak_price" placeholder='Enter Off Peak Price' className="form-control" />
                                                        <Error name="off_peak_price" />
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            <div className="form-group" >
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                            </div>
                                        </Form>
                                    }

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FCUpdatePriceSetting;