import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';

interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    fcDetails: models.FCDetails
    error: string | null
    isLoading: boolean
    isGsfUser: boolean
}
class FCDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            fcDetails: {} as models.FCDetails,
            error: null,
            isLoading: true,
            isGsfUser: false
        }
    }

    componentDidMount = () => {
        const { fc_id } = this.props;
        Server.get().userType().then(user_type => {
            this.setState({
                isGsfUser: user_type === "gsf_user"
            })
        })

        Server.get().getFCDetails({ fc_id })
            .then((fcDetails) => {
                this.setState({
                    fcDetails: fcDetails,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })

            })
    }
    public render() {
        const { fc_id } = this.props;
        const { fcDetails, error, isGsfUser, isLoading } = this.state;
        return (
            <div className='details'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1> Fitness Center Details</h1>
                        </div>
                    </ol>
                </nav>
                <Loader loading={isLoading}>
                    {
                        error
                            ? <span style={{ color: 'red' }}>{error}</span>
                            : null
                    }
                    <div className='details-head'>
                        <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/details/update', { fcDetails: fcDetails })}><i className="fas fa-edit"></i>Edit</button>
                    </div>
                    <div className='details-main'>
                        <div>Name : {fcDetails.name}</div>
                        <div>Branch : {fcDetails.branch}</div>
                        <div>Phone: {fcDetails.phone}</div>
                        <div>Email : {fcDetails.email}</div>
                        <div>Address line 1 : {fcDetails.address_line1}</div>
                        <div>Address line 2 : {fcDetails.address_line2}</div>
                        <div>Landmark : {fcDetails.land_mark}</div>
                        <div>Zipcode : {fcDetails.zip_code}</div>
                        <div>City : {fcDetails.city}</div>
                        <div>Latitude: {fcDetails.latitude}</div>
                        <div>Longitude : {fcDetails.longitude}</div>
                        <div>Website : {fcDetails.website}</div>
                        <div>For Gender : {fcDetails.gender}</div>
                        <div>Capacity : {fcDetails.capacity}</div>
                        <div>Floor Trainers : {fcDetails.floor_trainers}</div>
                        <div>Price:{fcDetails.price_level}</div>
                        <div>Personal Trainers : {fcDetails.personal_trainers}</div>
                        <div>Area : {fcDetails.area}</div>
                        <div>Workouts : {fcDetails.workouts}</div>
                        <div>Business name : {fcDetails.business_name}</div>
                        {
                            isGsfUser ?
                                <div>Commitments: {fcDetails.commitment}</div>
                                : null
                        }
                        <div>Area Size : {fcDetails.area_size}</div>
                        <div>Status:{fcDetails.status == 0 ? 'In-progress' : (fcDetails.status == 1 ? 'Active' : 'In-active')}</div>
                        <div>
                            <div>USP:{fcDetails.usp}</div>
                            <button type='button' className="btn btn-link" onClick={() => this.props.history.push('/fc/' + fc_id + '/details/usp/update')}>
                                <i className="fas fa-edit">Edit Usp</i>
                            </button>
                        </div>
                        <div>Payment Cycle:{fcDetails.payment_cycle}</div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCDetails);
