import * as React from 'react';
import Gallery from 'react-grid-gallery';
import ImageUploader from 'react-images-upload';
import Loader from 'react-loader-spinner';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError, getImageUrl } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import CheckButton from '../common/CheckButton';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    images: any[]
    localPath: string
    error: string | null
    isUploading: boolean
    selectAllChecked: boolean
    attempt: number
    progress: boolean
}
class FCImages extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            images: [],
            localPath: '',
            error: null,
            isUploading: false,
            selectAllChecked: false,
            attempt: 0,
            progress: false
        };
        this.onSelectImage = this.onSelectImage.bind(this);
        this.getSelectedImages = this.getSelectedImages.bind(this);
        this.onClickSelectAll = this.onClickSelectAll.bind(this);
    }

    setImages = (images: string[]) => {
        const { fc_id } = this.props;
        const imgObjs = images.map((i) => {
            return {
                original: i,
                src: getImageUrl(fc_id, i, '1024x768'),
                thumbnail: getImageUrl(fc_id, i, '128x128'),
                thumbnailWidth: 128,
                thumbnailHeight: 128,
                isSelected: false,
            }
        })
        this.setState({
            images: imgObjs,
            isUploading: false
        })
    }

    componentDidMount = () => {
        const { fc_id } = this.props;

        Server.get().getFCImages({ fc_id })
            .then((images) => {
                this.setImages(images)
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isUploading: false
                })
            })
    }

    handleOnChange = (pictures: any) => {
        const { fc_id } = this.props;
        const { attempt } = this.state;

        this.setState({ isUploading: true, attempt: attempt + 1 })
        console.log(pictures)
        Server.get().updateFCUploadImage({ fc_id, img: pictures })
            .then((images) => {
                // this.setState({ images: images.images })
                this.setImages(images.images)
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isUploading: false
                })
            })
    }

    allImagesSelected = (image: string) => {

        const { images } = this.state;
        const img = images.filter(
            function (_img) {
                return _img.isSelected === true;
            }
        )
        return img.length == images.length

    }

    onSelectImage = (index: number, image: string) => {

        const images = this.state.images.slice();
        const img = images[index];

        if (img.hasOwnProperty('isSelected')) {
            img.isSelected = !img.isSelected;
        } else {
            img.isSelected = true;
        }

        this.setState({
            images: images
        })
        if (this.allImagesSelected(image)) {
            this.setState({
                selectAllChecked: true
            });
        }
        else {
            this.setState({
                selectAllChecked: false
            });
        }
    }


    getSelectedImages = () => {
        const selected = [];
        for (var i = 0; i < this.state.images.length; i++) {
            if (this.state.images[i].isSelected == true) {
                selected.push(this.state.images[i].original);
            }
        }
        return selected;
    }

    onClickSelectAll = () => {
        var selectAllChecked = !this.state.selectAllChecked;
        this.setState({
            selectAllChecked: selectAllChecked
        });

        const images = this.state.images.slice();
        if (selectAllChecked) {
            for (var i = 0; i < this.state.images.length; i++)
                images[i].isSelected = true;
        }
        else {
            for (var i = 0; i < this.state.images.length; i++)
                images[i].isSelected = false;

        }
        this.setState({
            images: images
        });
    }

    onDelete = () => {
        const { fc_id } = this.props;
        const images = this.getSelectedImages()

        if (confirm('Are you sure you want to delete this image?')) {

            Server.get().deleteFCImage({ fc_id, images })
                .then(() => {
                    let stateImages = this.state.images
                    stateImages = stateImages.filter((img) => images.indexOf(img.original) < 0)
                    this.setState({ images: stateImages })
                })
                .catch((e) => {
                    this.setState({
                        error: getApiError(e),
                    })
                })
        }
    }

    public render() {
        const { images, error, attempt, isUploading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Fitnesscenter images</h2>
                        </div>
                    </ol>
                </nav>
                <h3>Gallery</h3>
                {
                    isUploading ?
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                        : null
                }
                <div>
                    <CheckButton
                        index={0}
                        isSelected={this.state.selectAllChecked}
                        onClick={this.onClickSelectAll}
                        parentHover={true}
                        color={"rgba(0,0,0,0.54)"}
                        selectedColor={"#4285f4"}
                        hoverColor={"rgba(0,0,0,0.54)"}
                    />
                    <div style={{
                        height: "36px",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        Select all
                    </div>
                    {
                        error ?
                            <text style={{ color: 'red' }}>{error}</text>
                            : null
                    }
                    <div style={{
                        padding: "2px",
                        color: "#666"
                    }}>
                        <button type="button" className="btn btn-primary" onClick={() => this.onDelete()}><i className="fas fa-trash-alt">Delete</i></button>
                        {/* selected images:{this.getSelectedImages().toString()} */}
                    </div>
                </div>
                <div data-reactroot="" className="gallery">
                    <Gallery images={images}
                        onSelectImage={this.onSelectImage}
                        showLightboxThumbnails={true}
                    />
                </div>
                <div className="container">
                    <div className="align-items-start">
                    </div>
                    <div className='row'>
                        <ImageUploader
                            key={attempt}
                            withIcon={true}
                            buttonText='Select image'
                            onChange={this.handleOnChange}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                        />
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(FCImages);
