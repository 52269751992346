import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Server } from '../../common/Server';
import { models } from '../../models';
import { getApiError } from '../../common/HelperMethods';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    loading: boolean
    usp: string
    error: string | null
    isSubmitBtnDisable: boolean
}
class FCUpdateUsp extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const fcUsp = this.props.history.location.state.usp as models.FCUsp
        this.state = {
            loading: false,
            usp: fcUsp.usp,
            error: null,
            isSubmitBtnDisable: false
        };
    }

    getValidationErr = (usp: string) => {
        let err = ""

        if (!usp) {
            err = "Fill this usp field"
        }
        return err
    }
    onSubmit = (event: any) => {

        event.preventDefault();
        const { fc_id } = this.props;
        const { usp } = this.state;
        let err = this.getValidationErr(usp);

        if (err) {
            this.setState({ error: err })
        }
        this.setState({ isSubmitBtnDisable: true })

        Server.get().updateFCUsp({ fc_id, usp })
            .then(() => {
                this.props.history.goBack()
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e)
                })
            })
    }
    public render() {
        const { usp, isSubmitBtnDisable, error } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>USP</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }
                                        <div className="form-group">
                                            <label htmlFor="FormControlTextarea">Usp</label>
                                            <textarea className="form-control" placeholder='Usp' rows={3} autoCapitalize="off" value={usp} onChange={(e) => this.setState({ usp: e.target.value })} ></textarea>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isSubmitBtnDisable}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(FCUpdateUsp);
