import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { FC_ENTER_OTP, FC_FORGOT_PASSWORD, FC_LOGIN, FC_NEW_PASSWORD, GSF_ENTER_OTP, GSF_FORGOT_PASSWORD, GSF_LOGIN, GSF_NEW_PASSWORD, INDEX, ORG_ENTER_OTP, ORG_FORGOT_PASSWORD, ORG_LOGIN, ORG_NEW_PASSWORD } from './common/Routes';
import EnterOtp from './pages/EnterOtp';
import FCHome from './pages/FCHome';
import GSFHome from './pages/GSFHome';
import Login from './pages/Login';
import NewPassword from './pages/NewPassword';
import OrgHome from './pages/OrgHome';
import ResetPassword from './pages/ResetPassword';
import StartPage from './pages/StartPage';





class App extends React.Component<any> {
    public render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route exact={true} path={INDEX} component={StartPage} />
                        {/* <Route exact={true} path={INDEX} component={image360} /> */}
                        <Route exact={true} path={FC_LOGIN} render={(props) => <Login {...props} user_type={'fc_user'} />} />
                        <Route exact={true} path={GSF_LOGIN} render={(props) => <Login {...props} user_type={'gsf_user'} />} />
                        <Route exact={true} path={ORG_LOGIN} render={(props) => <Login {...props} user_type={'org_user'} />} />

                        <Route exact={true} path={FC_FORGOT_PASSWORD} render={(props) => <ResetPassword {...props} user_type={'fc_user'} />} />
                        <Route exact={true} path={GSF_FORGOT_PASSWORD} render={(props) => <ResetPassword {...props} user_type={'gsf_user'} />} />
                        <Route exact={true} path={ORG_FORGOT_PASSWORD} render={(props) => <ResetPassword {...props} user_type={'org_user'} />} />

                        <Route exact={true} path={FC_NEW_PASSWORD} render={(props) => <NewPassword {...props} user_type={'fc_user'} />} />
                        <Route exact={true} path={GSF_NEW_PASSWORD} render={(props) => <NewPassword {...props} user_type={'gsf_user'} />} />
                        <Route exact={true} path={ORG_NEW_PASSWORD} render={(props) => <NewPassword {...props} user_type={'org_user'} />} />

                        <Route exact={true} path={ORG_ENTER_OTP} render={(props) => <EnterOtp {...props} user_type={'org_user'} />} />
                        <Route exact={true} path={FC_ENTER_OTP} render={(props) => <EnterOtp {...props} user_type={'fc_user'} />} />
                        <Route exact={true} path={GSF_ENTER_OTP} render={(props) => <EnterOtp {...props} user_type={'gsf_user'} />} />


                        <Route path={'/fc/:fc_id/'} component={FCHome} />

                        <Route path={'/gsf/'} component={GSFHome} />
                        <Route path={'/org/:org_id/'} component={OrgHome} />

                    </Switch>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

export default App;
