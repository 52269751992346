import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getApiError, isValidEmail } from '../common/HelperMethods';
import { FC_FORGOT_PASSWORD, GSF_FORGOT_PASSWORD, ORG_FORGOT_PASSWORD } from '../common/Routes';
import { Server } from '../common/Server';


interface IProps extends RouteComponentProps<any> {

    user_type: 'end_user' | 'fc_user' | 'org_user' | 'gsf_user'
}

interface IState {
    email_or_phone: string,
    password: string,
    error: null | string,
    isLoginBtnDisable: boolean
    type: 'Fitness Center' | 'Organization' | 'GSF'
}


class Login extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            email_or_phone: "",
            password: '',
            error: null,
            isLoginBtnDisable: false,
            type: 'Fitness Center',
        };
    }

    componentDidMount = () => {
        const { user_type } = this.props
        let type: IState['type'] = 'Fitness Center'
        if (user_type === 'fc_user') {
            type = 'Fitness Center'
        } if (user_type === 'gsf_user') {
            type = 'GSF'
        } if (user_type === 'org_user') {
            type = 'Organization'
        }
        this.setState({ type })

        this.checkSessionStorage();
    }

    checkSessionStorage = () => {
        try {
            if (sessionStorage) {
            }
        } catch (e) {
            alert('Your browser has cookies disabled . Make sure that your cookies are enabled and try again')
            return
        }
        this.handleLogin;
    }

    getValidationErr = (email_or_phone: string, password: string) => {
        let err = ''

        if (!email_or_phone) {
            err = 'Enter email or phone number'
        }
        else if (!password) {
            err = 'Enter password'
        } else if (password.length < 6) {
            err = 'Invalid user name or password '
        }
        else if (email_or_phone.indexOf('@') > 0) {

            if (!isValidEmail(email_or_phone)) {
                err = 'Enter valid email id / phone number'

            }
        }
        else if (isNaN(Number.parseInt(email_or_phone))) {
            err = 'Invalid email or phone number'
        } else if (isNaN(Number.parseInt(email_or_phone))) {
            if (email_or_phone.length < 10) {
                err = 'Mobile number must be 10 digits long'
            }
        }
        return err
    }
    handleLogin = (event: any) => {
        event.preventDefault()
        this.checkSessionStorage();
        const { email_or_phone, password } = this.state;
        const { user_type } = this.props
        const err = this.getValidationErr(email_or_phone, password)

        if (err) {
            this.setState({ error: err })
            return
        }
        this.setState({ error: err, isLoginBtnDisable: true })

        Server.get().login({ email_or_phone, password, user_type })
            .then((r) => {
                if (user_type === 'fc_user') {
                    this.props.history.push('/fc/' + r.fc_id + "/")
                    return
                } if (user_type === 'gsf_user') {
                    this.props.history.push('/gsf/home')
                    return
                } if (user_type === 'org_user') {
                    this.props.history.push('/org/' + r.org_id + '/')
                    return
                }
            })
            .catch((e) => {
                this.setState({
                    isLoginBtnDisable: false,
                    error: getApiError(e),
                })
            })
    }

    handleResetPassword = (event: any) => {
        event.preventDefault();
        const { user_type } = this.props;
        if (user_type === 'fc_user') {
            this.props.history.push(FC_FORGOT_PASSWORD, { user_type: user_type })
            return
        } if (user_type === 'gsf_user') {
            this.props.history.push(GSF_FORGOT_PASSWORD, { user_type: user_type })
            return
        } if (user_type === 'org_user') {
            this.props.history.push(ORG_FORGOT_PASSWORD, { user_type: user_type })
            return
        }
    }
    render() {
        const { email_or_phone: emailorphone, password, isLoginBtnDisable, error, type } = this.state;
        return (
            <div className="container" >
                <div className='row justify-content-center'>
                    <div className="title">
                        <img src="/logo/apple-icon-60x60.png" width='60' height='60' alt='' />
                        <h1 text-align="center">GetSet.Fit</h1>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className="col-sm-4">
                        <div className='base-form'>
                            <div className="card">
                                <article className="card-body">
                                    <h4 className="card-title text-center mb-4 mt-1">{type} Login</h4>
                                    <hr />
                                    {
                                        error
                                            ? <p className="text-danger text-center">{error}</p>
                                            : <p className="text-center">Please enter your email and password</p>
                                    }
                                    <form onSubmit={this.handleLogin}>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="fa fa-user"></i> </span>
                                                </div>
                                                <input type='email' className="form-control" autoCapitalize="off" value={emailorphone} placeholder="Email or Phone" onChange={(e) => this.setState({ email_or_phone: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="fa fa-lock"></i> </span>
                                                </div>
                                                <input type='password' className="form-control" autoCapitalize="off" value={password} placeholder="******" onChange={(e) => this.setState({ password: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={this.handleLogin} disabled={isLoginBtnDisable}>{isLoginBtnDisable ? "Loading..." : "Login"}</button>
                                        </div>
                                    </form>
                                    <p className="text-center">
                                        <button type="button" className="btn btn-link" onClick={this.handleResetPassword}>Forgot password?</button>
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Login);