import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import DatePicker from "react-datepicker";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    org_id: number
}

interface IState {
    loading:boolean
    isDisableSubmit: boolean
    invoice_id: string
    from_date: Date
    to_date: Date
    units: number
    amount: string
    error: string | null
    color: "error" | null
}
class OrgCreatePackage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading:false,
            isDisableSubmit: false,
            invoice_id: '',
            from_date: new Date(),
            to_date: new Date(),
            units: 0,
            amount: '',
            error: null,
            color: null
        };

        this.dateOnChange1 = this.dateOnChange1.bind(this);
        this.dateOnChange2 = this.dateOnChange2.bind(this);
    }

    dateOnChange1 = (date: Date) => {
        this.setState({
            from_date: date
        })
    }

    dateOnChange2 = (date: Date) => {
        this.setState({
            to_date: date
        })
    }

    getValidationErr = (from_date: Date, to_date: Date, units: number, amount: string) => {
        let err = ''
        if (!from_date) {
            err = "Enter From date"
        } if (!to_date) {
            err = "Enter to date"
        } if (!units) {
            err = 'Enter number of units'
        } if (!amount) {
            err = 'Enter valid amount'
        }
        return err
    }
    onSubmit = (event: any) => {
        event.preventDefault()
        let { from_date, to_date, units, amount, isDisableSubmit, color, invoice_id } = this.state
        amount = amount.replace(',', '').trim()
        const err = this.getValidationErr(from_date, to_date, units, amount)
        const { org_id } = this.props

        if (err) {
            this.setState({ error: err, color: "error" })
        }
        const fromDate = from_date.toString()
        const toDate = to_date.toString()
        this.setState({ isDisableSubmit: true })

        Server.get().createOrgPackage({ amount: parseInt(amount), from_date: from_date.getTime(), org_id, to_date: to_date.getTime(), units })
            .then((r: any) => {
                this.props.history.goBack()
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    color: "error",
                    isDisableSubmit: false
                })
            })
    }
    render() {
        const { from_date, to_date, units, amount, isDisableSubmit, color, error } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Create package</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }
                                        <div className="form-group">
                                            From :
                        <DatePicker className="form-control"
                                                selected={from_date}
                                                onChange={this.dateOnChange1}
                                            />
                                        </div>
                                        <div className="form-group">
                                            To :
                        <DatePicker className="form-control"
                                                selected={to_date}
                                                onChange={this.dateOnChange2}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label>Units</label>
                                            <input type='number' className="form-control" name="units" placeholder='units' value={units} onChange={(e) => this.setState({ units: e.target.valueAsNumber })} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Amount</label>
                                            <input type='amount' className="form-control" name="amount" placeholder='Amount' value={amount} onChange={(e) => this.setState({ amount: e.target.value })} />
                                        </div>
                                        <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(OrgCreatePackage);
