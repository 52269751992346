import * as React from 'react';
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchField from "react-search-field";
import { getApiError } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import { models } from '../../models';
import Pager from '../common/Pager';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    data: models.Paging<models.FCCheckin>
    filteredData: models.FCCheckin[]
    isLoading: boolean
    error: string | null
    from: Date
    to: Date
    limit: number
    offset: number
    fc_id: number
    today: Date

}
class FCCheckins extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            data: { count: 0, items: [] as models.FCCheckin[] },
            filteredData: [] as models.FCCheckin[],
            isLoading: true,
            error: null,
            limit: 25,
            offset: 0,
            fc_id: 0,
            from: new Date(),
            to: new Date(),
            today: new Date()
        };
        this.onFromChange = this.onFromChange.bind(this);
        this.onToChange = this.onToChange.bind(this);
    }

    onFromChange = (date: Date) => {
        date.setHours(0)
        date.setMinutes(0)
        console.log("from time", date)
        this.setState({
            from: date
        }, () => {
            this.doRequest()
        })
    }

    onToChange = (date: Date) => {
        date.setHours(23)
        date.setMinutes(59)
        console.log("to time", date)
        this.setState({
            to: date
        }, () => {

            this.doRequest()
        })
    }
    fcCheckins() {
        this.setState({
            limit: 25,
            offset: 0,
            isLoading: true,
            from: new Date(),
            to: new Date()
        })
    }
    doRequest() {
        const { from, to, limit, offset } = this.state;
        console.log("from", from)
        console.log('to', to)
        Server.get().getFCCheckins({
            fc_id: this.props.fc_id,
            from: from.getTime(),
            to: to.getTime(),
            limit,
            offset
        }).then((data) => {
            this.setState({
                data,
                filteredData: data.items,
                isLoading: false
            })
        }).catch((e: any) => {
            this.setState({
                error: getApiError(e),
                isLoading: false
            })
        })
    }
    onPage(limit: number, offset: number) {
        this.setState({
            limit,
            offset
        }, () => this.doRequest())
    }
    componentDidMount() {
        this.doRequest()
    }

    onSearch = (input: string) => {
        const { data } = this.state
        const filteredData = data.items.filter(data => data.user_name.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        this.setState({
            filteredData
        })
    }
    render() {
        const { from, to, today, error, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h1> Checkins</h1>
                        </div>
                    </ol>
                </nav>
                <Pager count={this.state.data.count} limit={25} page={0} onChange={(limit, offset) => this.onPage(limit, offset)} />
                {
                    isLoading ?
                        <Loader
                            type="puff"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                        : null
                }
                {
                    error
                        ? <span style={{ color: 'red' }}>{error}</span>
                        : null
                }
                <form className="form-inline">
                    <div className="form-group">
                        <SearchField
                            placeholder="Search..."
                            onChange={this.onSearch}
                            classNames="test-class"
                        />
                    </div>
                    <div className="form-group">
                        From :
                    <DatePicker
                            selected={from}
                            onChange={this.onFromChange}
                            className="form-control"

                        />
                    </div>

                    <div className="form-group">
                        To :
                        <DatePicker
                            selected={to}
                            onChange={this.onToChange}
                            className="form-control"
                        />
                    </div>
                    <div>Total checkins : {this.state.data.count}</div>
                </form>
                <table className="table">
                    <thead className="bg-light">
                        <tr>
                            <th scope="col">User</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">End Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.filteredData && this.state.filteredData.map((m, i) =>
                            <tr key={m.id}>
                                <th scope="row">{m.user_name}</th>
                                <td>
                                    <Moment format="DD/MM/YYYY HH:mm">
                                        {m.start_at}
                                    </Moment>
                                </td>
                                <td>
                                    <Moment format="DD/MM/YYYY HH:mm">
                                        {m.end_at}
                                    </Moment>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        );
    }
}

export default withRouter(FCCheckins);
