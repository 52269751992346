import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Server } from '../../common/Server';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    fc_id: number
}
interface IState {
    loading: boolean
    usp: string
    error: string | null
}
class FCUsp extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            usp: '',
            error: null
        };
    }
    componentDidMount() {
        Server.get().getFCUsp({ fc_id: this.props.fc_id }).then((usp) => {
            this.setState({
                usp: usp.usp,
                loading: false
            })
        })
    }
    public render() {
        const { error } = this.state;
        return (
            <Loader loading={this.state.loading}>
                <div className='details'>
                    <div className='details-head'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <div className="align-content:center">
                                    <h1> USP</h1>
                                </div>
                            </ol>
                        </nav>
                        {
                            error
                                ? <span style={{ color: 'red' }}>{error}</span>
                                : null
                        }
                    </div>
                    <div className='details-main'>
                        <div><p>{this.state.usp}</p></div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default FCUsp;
