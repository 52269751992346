import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError, getIconUrl } from '../../common/HelperMethods';
import { Server } from '../../common/Server';
import Loader from '../common/Loader';



interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    loading: boolean
    isDisableSubmit: boolean
    name: string
    icon: string | any
    description: string
    error: string | null
    isUploading: boolean
    color: "success" | "error" | null
}
class GSFMasterEquipment extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            isDisableSubmit: false,
            name: '',
            icon: '',
            description: '',
            isUploading: false,
            error: null,
            color: null
        };
    }

    getValidationErr = (name: string) => {
        let err = ''
        if (!name) {
            err = ''
        }
        return err
    }

    onSubmit = (event: any) => {
        event.preventDefault()

        const { name } = this.state;
        const { description } = this.state;
        const { icon } = this.state;
        const err = this.getValidationErr(name)


        if (err) {
            this.setState({ error: err })
            return
        }
        Server.get().createGSFEquipment({ name, description })
            .then(() => {
                this.props.history.goBack()
            })
            .catch((e) => {
                this.setState({
                    error: getApiError(e),
                })
            })
    }
    render() {
        const { name, isDisableSubmit, error, description } = this.state;
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Add Equipment</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <form>
                                        {
                                            error
                                                ? <span style={{ color: 'red' }}>{error}</span>
                                                : null
                                        }
                                        <div className='form-group'>
                                            <label>Name</label>
                                            <input type='text' className="form-control" name="details" placeholder='name' value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="FormControlTextarea">Description</label>
                                            <textarea className="form-control" placeholder='description' rows={3} autoCapitalize="off" value={description} onChange={(e) => this.setState({ description: e.target.value })} ></textarea>
                                        </div>

                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isDisableSubmit}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(GSFMasterEquipment);
