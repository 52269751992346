import { Field, Form, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Server } from '../../common/Server';
import { models } from '../../models';
import * as Yup from 'yup';
import Error from '../common/Error';
import Loader from '../common/Loader';


interface IProps extends RouteComponentProps<any> {
    org_id: number
}


interface IState {
    loading: boolean
    data: models.OrgUpdate
}

class OrgUpdateDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const orgDetails = this.props.history.location.state.orgDetails as models.OrgDetails
        this.state = {
            loading: false,
            data: {
                name: orgDetails.name,
                org_id: orgDetails.id,
                address: orgDetails.address || '',
                manager_name: orgDetails.manager_name || '',
                manager_email: orgDetails.manager_email || '',
                manager_phone: orgDetails.manager_phone || '',
                hr_name: orgDetails.hr_name || '',
                hr_email: orgDetails.hr_email || '',
                hr_phone: orgDetails.hr_phone || '',
                min_unit: orgDetails.min_unit || 0,
                max_unit: orgDetails.max_unit || 10,
                limit_type: orgDetails.limit_type || 'month',
                sessions_limit: orgDetails.sessions_limit || 30,
                status: orgDetails.status,
            },
        };
    }

    onSubmit = (values: models.OrgUpdate, fa: FormikActions<any>) => {
        Server.get().updateOrgDetails(values)
            .then((r: any) => {
                fa.setSubmitting(false)
                this.props.history.goBack();
            })
            .catch((e: any) => {
                fa.setErrors(e)
                fa.setSubmitting(false)
            })
    }

    schema = () => {
        return Yup.object().shape({
            name: Yup.string().label('Name').required(),
            org_id: Yup.number(),
            address: Yup.string().label('Address').when('status', {
                is: status => status == 1,
                then: Yup.string().required().min(5).max(250),
                otherwise: Yup.string().min(5).max(250)
            }),
            manager_name: Yup.string().label('Manager Name').required(),
            manager_email: Yup.string().label('Manager Email').email().required(),
            manager_phone: Yup.string().label('Manager phone').matches(/^\d{10}$/, 'Not a valid phone number').required(),
            hr_name: Yup.string().label('Hr Name').required(),
            hr_email: Yup.string().label('Hr email').email().required(),
            hr_phone: Yup.string().label('Hr phone').matches(/^\d{10}$/, 'Not a valid phone number').required(),
            min_unit: Yup.number().required().min(0).max(10),
            max_unit: Yup.number().required().min(0).max(10),
            limit_type: Yup.string().oneOf(['week', 'day', 'month']),
            sessions_limit: Yup.number(),
            status: Yup.number().oneOf([0, 1, 2]),
        })
    }


    render() {
        const { data } = this.state
        return (
            <div className="form">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Update organization</h2>
                        </div>
                    </ol>
                </nav>
                <Loader loading={this.state.loading}>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-sm-5">
                                <div className="base-form">
                                    <Formik initialValues={data} onSubmit={this.onSubmit} validationSchema={this.schema} validateOnBlur={true}
                                        render={({ isSubmitting, submitForm }) =>
                                            <Form>
                                                <div className='form-group'>
                                                    <label>Name</label>
                                                    <Field type='text' name="name" placeholder='Organization Name' className="form-control" />
                                                    <Error name="name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <Field type='text' className="form-control" name="address" placeholder='Address' />
                                                    <Error name="address" />
                                                </div>
                                                <div className='form-group'>
                                                    <label>Manager name</label>
                                                    <Field type='text' className="form-control" name="manager_name" placeholder='Managaer name' />
                                                    <Error name="manager_name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Manager email</label>
                                                    <Field type='email' className="form-control" name="manager_email" placeholder='Manager email' />
                                                    <Error name="manager_email" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Manager phone</label>
                                                    <Field type='text' className="form-control" name="manager_phone" placeholder='Manager Phone' />
                                                    <Error name="manager_phone" />
                                                </div>
                                                <div className="form-group">
                                                    <label>HR name</label>
                                                    <Field type='text' className="form-control" name="hr_name" placeholder='HR name' />
                                                    <Error name="hr_name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>HR email</label>
                                                    <Field type='email' className="form-control" name="hr_email" placeholder='HR email' />
                                                    <Error name="hr_email" />
                                                </div>
                                                <div className="form-group">
                                                    <label>HR Phone</label>
                                                    <Field type='text' className="form-control" name="hr_phone" placeholder='HR phone' />
                                                    <Error name="hr_phone" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Minimum unit</label>
                                                    <Field type='number' className="form-control" name="min_unit" placeholder='Minimum unit' />
                                                    <Error name="min_unit" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Maximum unit</label>
                                                    <Field type='number' className="form-control" name="max_unit" placeholder='Maximum unit' />
                                                    <Error name="max_unit" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Session limit</label>
                                                    <Field type='number' className="form-control" name="sessions_limit" placeholder='Session limit' />
                                                    <Error name="sessions_limit" />
                                                </div>
                                                <div>
                                                    <label>Limit type:</label>
                                                    <Field component="select" className="form-control" name="limit_type">
                                                        <option value='month'>Month</option>
                                                        <option value='week'>Week</option>
                                                    </Field>
                                                    <Error name="limit_type" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Status:</label>
                                                    <Field component="select" className="form-control" name="status">
                                                        <option value={0}>In-progress</option>
                                                        <option value={1}>Active</option>
                                                        <option value={2}>In-active</option>
                                                    </Field>
                                                    <Error name="status" />
                                                </div>
                                                <div className="form-group" >
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                                </div>
                                            </Form>
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        );
    }
}

export default OrgUpdateDetails;
