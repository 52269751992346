import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getApiError, getIconUrl } from '../../common/HelperMethods';
import SearchField from "react-search-field";
import { Server } from '../../common/Server';
import { models } from '../../models';
import Loader from '../common/Loader';
import Avatar from 'react-avatar';

interface IProps extends RouteComponentProps<any> {
    user_id: number
}

interface IState {
    isLoading: boolean
    error: string | null
    equipments: models.GSFEquipment[]
    filteredEquipments: models.GSFEquipment[]

}
class GSFEquipments extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            equipments: [] as models.GSFEquipment[],
            filteredEquipments: [] as models.GSFEquipment[],

        };
    }
    onDelete = (id: number) => {

        const { } = this.props
        if (confirm('Are you sure you want to delete this equipment?')) {

            Server.get().deleteGSFEquipment({ id })
                .then(() => {
                    let equipments = this.state.equipments
                    equipments = equipments.filter((e) => e.id !== id)
                    this.setState({
                        equipments,
                        isLoading: false
                    })

                })
                .catch((e: any) => {
                    this.setState({
                        error: getApiError(e),
                        isLoading: false
                    })
                })
        }
    }
    componentDidMount = () => {
        Server.get().getAllEquipments({})
            .then((equipments) => {
                this.setState({
                    equipments,
                    filteredEquipments: equipments,
                    isLoading: false
                })
            })
            .catch((e: any) => {
                this.setState({
                    error: getApiError(e),
                    isLoading: false
                })
            })
    }

    onSearch = (str: string) => {
        const { equipments } = this.state;
        const filteredEquipments = equipments.filter((equipment) => {
            const lowerStr = str.toLowerCase()
            return equipment.name.toLowerCase().indexOf(lowerStr) >= 0
        })
        this.setState({
            filteredEquipments: filteredEquipments,
            isLoading: false
        })
    }

    public render() {
        const { filteredEquipments, isLoading } = this.state;
        return (
            <div className='list'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <div className="align-content:center">
                            <h2>Equipments</h2>
                        </div>
                    </ol>
                </nav>
                <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/gsf/equipment/create')}><i className="fas fa-create-alt"></i>Add Equipment</button>

                <Loader loading={isLoading}>
                    <div className='form-group'>
                        <SearchField
                            placeholder="Search..."
                            onChange={this.onSearch}
                            classNames="test-class"
                        />
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Icon</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                        <tbody>

                            {this.state.filteredEquipments.map((c) =>
                                <tr key={c.id}>
                                    <td scope="row">

                                        {c.icon ? <Avatar
                                            src={getIconUrl(c.icon, '64x64')}
                                            size="64px"
                                        /> : null
                                        }
                                    </td>
                                    <td scope="row">
                                        {c.name}
                                    </td>
                                    <td scope="row">
                                        {c.description}
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-link" onClick={(e) => this.onDelete(c.id)}><i className="fas fa-trash-alt">Delete</i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </Loader>


            </div>
        );
    }
}

export default withRouter(GSFEquipments);
